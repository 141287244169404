import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleFetchUsers = async () => {
  const uri = `api/users`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateUser = async (data: any) => {
  const uri = `api/users/register`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUpdateUser = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleDeleteUser = async (data: any) => {
  const uri = `api/users`;
  const response = deleteDataAuto({ data, uri });
  return response;
};


const handleResetPassword = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}/reset/password`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleUpdatePassword = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}/update/password`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateDetails = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}/update/details`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleUpdateSeller = async ({ _id, data }: any) => {
  const uri = `api/users/${_id}/seller`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleCheckUser = async () => {
  const uri = `api/users/check`;
  const response = fetchDataAuto({ uri });
  return response;
};

export {
  handleFetchUsers,
  handleCreateUser,
  handleUpdateUser,
  handleDeleteUser,
  handleResetPassword,
  handleUpdatePassword,
  handleUpdateSeller,
  handleUpdateDetails,
  handleCheckUser
};
