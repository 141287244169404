import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { Button, Table } from "antd";
import { handleFetchNewLeads } from "../../../api/leads";
import Something from "../../../components/shared/Something";
// import LeadColumns from "./components/LeadColumns";
import Wrap from "../Wrap";
import Main from "./Main";
import { useContext, useEffect, useState } from "react";
import { AiOutlineCloseSquare, AiOutlinePlus } from "react-icons/ai";
import CreateLeadPanel from "./components/CreateLeadPanel";
import { RiSearch2Line } from "react-icons/ri";
import AdvancedSearch from "./components/AdvancedSearch";
import { AuthContext } from "../../../context/AuthContext";
import { BsCheck2Square } from "react-icons/bs";
import Moment from "react-moment";
import formatMoney from "../../../utils/formatMoney";

export default function OngoingLeads() {
  const { auth } = useContext(AuthContext);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [advSearch, setAdvSearch] = useState<boolean>(false);
  const createAccess = [2, 4, 9];

  const initials = {
    customer: "",
    consultant: "",
    model: "",
    source: "",
    status: "",
    startDate: "",
    endDate: "",
    minValue: "",
    maxValue: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 60,
  });

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  const params = `&customer=${filters?.customer}&consultant=${filters?.consultant}&source=${filters?.source}&status=${filters?.status}&startDate=${filters?.startDate}&endDate=${filters?.endDate}&model=${filters?.model}&minValue=${filters?.minValue}&maxValue=${filters?.maxValue}`;
  const {
    data: leads,
    refetch,
    isLoading,
    isFetching,
    isFetched,
    isError,
  } = useQuery({
    queryKey: ["newLeads", { pagination, params }],
    queryFn: handleFetchNewLeads,
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const navigate = useNavigate();

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: leads?.total_count,
    });
  };

  const LeadColumns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">   {pagination?.page * pagination?.per_page -
          pagination?.per_page +
          index +
          1}</span>
      ),
    },
    {
      title: "Title",
      width: 220,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.title ? record?.title : "-"}
          </span>
        );
      },
    },
    {
      title: "Contact",
      width: 180,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.contact?.name ? record?.contact?.name : "-"}
          </span>
        );
      },
    },
  
    {
      title: "Source",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.source?.name ? record?.source?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Value ( KES )",
      width: 100,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.value ? formatMoney(record?.value) : "-"}
          </span>
        );
      },
    },
    // {
    //   title: "Next Activity",
    //   width: 120,
    //   render: (record: any) => {
    //     return (
    //       <span className="text-gray-700">
    //         {record?.next_activity ? record?.next_activity : "-"}
    //       </span>
    //     );
    //   },
    // },
  
    {
      title: "Participant",
      width: 120,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.creator?.name}</span>;
      },
    },
    {
      title: "Created",
      width: 120,
      render: (record: any) => {
        return <Moment format={"Do MMM,YYYY"}>{record?.createdAt}</Moment>;
      },
    },
    {
      title: "Status",
      width: 80,
      render: (record: any) => {
        return (
          <span className="text-gray-700 capitalize">
            {record?.status ? record?.status?.state : "-"}
          </span>
        );
      },
    },
    {
      title: "Qualified",
      width: 70,
      render: (record: any) => {
        return (
          <div className="">
            {record?.flags?.isWon ? (
              <div className="flex items-center">
                <BsCheck2Square color="#16a34a" />
                <span className="ml-1 text-green-600">YES</span>
              </div>
            ) : (
              <div className="flex items-center">
                <AiOutlineCloseSquare color="#dc2626" />
                <span className="ml-1 text-red-600">NO</span>
              </div>
            )}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
     if (leads?.total_count) {
       setPagination({
         ...pagination,
         total_count: leads?.total_count,
       });
     }
  }, [isFetched]);

  return (
    <Wrap
      active="Leads"
      child={
        <CreateLeadPanel isVisible={createModal} setVisible={setCreateModal} />
      }
      actions={
        <div className="">
          {createAccess?.includes(auth?.accessLevel) && (
            <Button
              className="bg-white rounded-none ml-2 flex justify-center items-center"
              style={{ fontSize: 12 }}
              onClick={() => setCreateModal(true)}
            >
              <div className="">
                <AiOutlinePlus size={12} />
              </div>
              <div className="ml-1 pt-1">New</div>
            </Button>
          )}
        </div>
      }
    >
      <Main
        active="New Leads"
        actions={
          <Button
            className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
            onClick={() => {
              setAdvSearch(!advSearch);
            }}
          >
            <div className="flex justify-center items-center">
              <div className="mr-2">Advanced Search</div>
              <div className="border-l px-2">
                <RiSearch2Line color="gray" size={14} />
              </div>
            </div>
          </Button>
        }
      >
        {advSearch && (
          <AdvancedSearch
            setVisible={setAdvSearch}
            clearAllFilters={clearAllFilters}
            handleAdvancedSearch={handleAdvancedSearch}
          />
        )}
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="grid gap-x-0 grid-cols-12 border bg-white">
            <div className="col-span-12 sm:col-span-12 md:col-span-12">
              <Table
                size="small"
                dataSource={leads === undefined ? [] : leads?.results}
                columns={LeadColumns}
                scroll={{ y: 660, x: 0 }}
                rowKey="id"
                onRow={(record) => {
                  return {
                    onClick: () => {
                      navigate(`/crm/leads/${record?._id}/detailed`);
                    },
                  };
                }}
                pagination={{
                  current: pagination?.page,
                  pageSize: pagination?.per_page,
                  total: pagination?.total_count,
                }}
                onChange={onChange}
                loading={isLoading}
                rowClassName={"hover:cursor-pointer hover:bg-red-50"}
              />
            </div>
          </div>
        )}
      </Main>
    </Wrap>
  );
}
