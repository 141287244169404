import { AiOutlineRight } from "react-icons/ai";
import formatShort from "../../../../utils/formatShort";

export default function StatusWidget({ item, handleSelect }: any) {
  const getTotal = () => {
    let total = 0;
    item?.list?.forEach((item: any) => {
      total = total + Number(item?.value);
    });
    return total;
  };

  return (
    <div
      className="col-span-12 sm:col-span-2 border bg-white cursor-pointer"
      onClick={() => handleSelect({ item })}
    >
      <div className="flex flex-col">
        <div className="p-2">
          <div className="flex justify-between">
            <div className="font-bold capitalize">{item?._id || "-"}</div>
            <div className="">{item?.total || "-"} Leads</div>
          </div>
          <div className="text-xl">{formatShort(getTotal())} <span className="text-xs">KES</span></div>
        </div>
        <button
          onClick={() => handleSelect({ item })}
          className="border-t items-center p-1 mt-4 hover:bg-gray-50 cursor-pointer"
        >
          <div className="flex justify-between items-center">
            <div className="p-1">See Details</div>
            <div className="">
              <AiOutlineRight />
            </div>
          </div>
        </button>
      </div>
    </div>
  );
}
