import { DatePicker, Checkbox, Button, Form, Popover } from "antd";
import moment from "moment";
import { useState } from "react";
import { BsCalendarCheck, BsChevronDown } from "react-icons/bs";
import { RiFilterOffLine } from "react-icons/ri";

const { RangePicker } = DatePicker;

export default function DateTool({ stateHandler, handleYearChange }: any) {
  const initialYear = new Date().getFullYear();
  const [showRange, setShowRange] = useState(false);
  const start = moment(new Date()).format("YYYYMMDD");
  const end = moment(new Date()).format("YYYYMMDD");
  const [startDate, setStateDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [selected, setSelected] = useState<any>({ id: 3, name: initialYear });
  const [form] = Form.useForm();
  const [year, setYear] = useState<any>(new Date().getFullYear());
  const [opened, setOpened] = useState<boolean>(false);

  const onRangeChange = (date: any) => {
    if(date === null) return
    setStateDate(moment(new Date(date[0])).format("YYYYMMDD"));
    setEndDate(moment(new Date(date[1])).format("YYYYMMDD"));
    stateHandler({
      startDate: moment(new Date(date[0])).format("YYYYMMDD"),
      endDate: moment(new Date(date[1])).format("YYYYMMDD"),
      isRange: true,
    });
  };

  const periods = [
    { id: 1, name: "This Week" },
    { id: 2, name: "This Month" },
  ];

  const years = [
    { id: 2, name: initialYear + 2 },
    { id: 3, name: initialYear + 1 },
    { id: 4, name: initialYear },
    { id: 5, name: initialYear - 1 },
    { id: 6, name: initialYear - 2 },
  ];

  const handleSelectYear = async (year: any) => {
    setYear(year);
    setSelected({ id: 3, name: "Year" });

    const start = moment().startOf("year").format("YYYYMMDD");
    const end = moment().endOf("year").format("YYYYMMDD");
    setStateDate(start);
    setEndDate(end);
    stateHandler({
      startDate: moment().startOf("year").format("YYYYMMDD"),
      endDate: moment().endOf("year").format("YYYYMMDD"),
      isRange: true,
    });
    handleYearChange({ year });
  };

  const onSelectPeriod = (period: any) => {
    if (period?.name === "This Week") {
      const start = moment().startOf("isoWeek").format("YYYYMMDD");
      const end = moment().endOf("isoWeek").format("YYYYMMDD");
      setStateDate(start);
      setEndDate(end);
      stateHandler({
        startDate: moment().startOf("isoWeek").format("YYYYMMDD"),
        endDate: moment().endOf("isoWeek").format("YYYYMMDD"),
        isRange: true,
      });
    }
    if (period?.name === "This Month") {
      const start = moment().startOf("month").format("YYYYMMDD");
      const end = moment().endOf("month").format("YYYYMMDD");
      setStateDate(start);
      setEndDate(end);
      stateHandler({
        startDate: moment().startOf("month").format("YYYYMMDD"),
        endDate: moment().endOf("month").format("YYYYMMDD"),
        isRange: true,
      });
    }
    if (period?.name === "This Year") {
      const start = moment().startOf("year").format("YYYYMMDD");
      const end = moment().endOf("year").format("YYYYMMDD");
      setStateDate(start);
      setEndDate(end);
      stateHandler({
        startDate: moment().startOf("year").format("YYYYMMDD"),
        endDate: moment().endOf("year").format("YYYYMMDD"),
        isRange: true,
      });
    }
  };

  const onDateChange = (date: any) => {
    if (date === null) return;
    setStateDate(moment(new Date(date)).format("YYYYMMDD"));
    setEndDate(moment(new Date(date)).format("YYYYMMDD"));
    stateHandler({
      startDate: moment(new Date(date)).format("YYYYMMDD"),
      endDate: moment(new Date(date)).format("YYYYMMDD"),
      isRange: false,
    });
  };

  const clearDate = () => {
    const period = { id: 1, name: "This Week" };
    form.resetFields();
    const start = moment().startOf("isoWeek").format("YYYYMMDD");
    const end = moment().endOf("isoWeek").format("YYYYMMDD");
    setStateDate(start);
    setEndDate(end);
    setSelected(period);
    stateHandler({
      startDate: moment(new Date()).format("YYYYMMDD"),
      endDate: moment(new Date()).format("YYYYMMDD"),
      isRange: false,
    });
    setSelected(null);
  };

  return (
    <div className="flex justify-between items-center border-b  border-gray-300 py-1 px-2 bg-white">
      <div className="flex">
        <Form layout="inline" form={form}>
          {showRange ? (
            <div className="flex justify-center items-center">
              <Form.Item name="ranger">
                <RangePicker
                  allowClear={true}
                  onChange={onRangeChange}
                  format={"DD-MM-YYYY"}
                />
              </Form.Item>

              <Checkbox onChange={() => setShowRange(!showRange)}>
                <span className="text-gray-500">Date as Range</span>
              </Checkbox>
            </div>
          ) : (
            <div className="flex justify-center items-center">
              <Form.Item name="picker">
                <DatePicker
                  onChange={onDateChange}
                  format={"DD-MM-YYYY"}
                  placeholder="Specific date"
                  style={{ width: "100%" }}
                />
              </Form.Item>
              <Checkbox onChange={() => setShowRange(!showRange)}>
                <span className="text-gray-500">Date as Range</span>
              </Checkbox>
            </div>
          )}
          <Button className="" onClick={() => clearDate()}>
            <RiFilterOffLine color="#6b7280" />
          </Button>
        </Form>
      </div>
      <div className="">
        <div className="flex flex-row">
          {periods?.map((period: any, index: number) => (
            <button
              className={`flex justify-center items-center border border-gray-300 py-2 px-4 ml-2 ${
                selected?.id === period.id ? `bg-red-100` : ``
              }`}
              key={index}
              onClick={() => {
                setSelected(period);
                onSelectPeriod(period);
              }}
            >
              <span>
                <BsCalendarCheck />
              </span>
              <span className="ml-2 text-sm">{period.name}</span>
            </button>
          ))}

          <Popover
            className="cursor:pointer focus:outline-none hover:text-toyota"
            trigger="click"
            placement="bottom"
            content={
              <div className="flex flex-col w-40">
                {years?.map((item: any) => (
                  <button
                    className="flex cursor-pointer hover:text-toyota text-left focus:outline-none py-1"
                    onClick={() => {
                      handleSelectYear(item?.name);
                      setOpened((o) => !o);
                    }}
                    key={item.id}
                  >
                    <div className={`${year === item?.name && "text-red-600"}`}>
                      {item?.name}
                    </div>
                  </button>
                ))}
              </div>
            }
          >
            <div
              onClick={() => setOpened((o) => !o)}
              className="cursor-pointer"
            >
              <div
                className={`flex justify-center items-center border border-gray-300 py-2 px-4 ml-2 ${
                  selected?.id === 3 ? `bg-red-100` : ``
                }`}
              >
                <span>
                  <BsCalendarCheck />
                </span>
                <div className="ml-2 text-sm">{year}</div>
                <div className="ml-2">
                  <BsChevronDown color={"#4145A8"} />
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    </div>
  );
}
