import Wrap from "./Wrap";
import Something from "../../components/shared/Something";
import ServiceItem from "./components/ServiceItem";
import ServiceSearch from "./components/ServiceSearch";
import CreateService from "./components/CreateService";
import { useContext, useState } from "react";
import { Button } from "antd";
import { AiOutlinePlus } from "react-icons/ai";
import { useQuery } from "@tanstack/react-query";
import { handleFetchAllServices } from "../../api/services";
import NoRecords from "../../components/shared/NoRecords";
import { AuthContext } from "../../context/AuthContext";

export default function AllServices() {
  const { auth } = useContext(AuthContext);
  const [createModal, setCreateModal] = useState<boolean>(false);

  const createAccess = [3, 4, 9];

  const initials = {
    registration: "",
    chassis: "",
    model: "",
    status: "",
    approval: "",
    location: "",
    pds: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });
  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 12,
    total_pages: 1,
    total_count: 50,
  });

  const params = `&registration=${filters?.registration}&chassis=${filters?.chassis}&model=${filters?.model}&status=${filters?.status}&approval=${filters?.approval}&location=${filters?.location}&pds=${filters?.pds}`;

  const {
    data: services,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["allServices", { pagination, params }],
    queryFn: handleFetchAllServices,
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  return (
    <Wrap
      active="All"
      actions={
        <>
          {createAccess?.includes(auth?.accessLevel) && (
            <Button
              className="bg-white rounded-none ml-2 flex justify-center items-center"
              style={{ fontSize: 12 }}
              onClick={() => setCreateModal(true)}
            >
              <div className="">
                <AiOutlinePlus size={12} />
              </div>
              <div className="ml-1 pt-1">New</div>
            </Button>
          )}
          <CreateService isVisible={createModal} setVisible={setCreateModal} />
        </>
      }
    >
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <div className="">
          <ServiceSearch
            services={services || []}
            handleAdvancedSearch={handleAdvancedSearch}
            clearAllFilters={clearAllFilters}
          />
          {services?.length === 0 ? (
            <NoRecords />
          ) : (
            <div className="grid grid-cols-12 gap-2 p-2">
              {services?.map((item: any, index: number) => (
                <ServiceItem key={index} service={item || {}} />
              ))}
            </div>
          )}
        </div>
      )}
    </Wrap>
  );
}
