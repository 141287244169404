import {
  Button,
  Modal,
  Form,
  DatePicker,
  Row,
  Col,
  Select,
  Input,
  message,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleConvertLead } from "../../../../api/leads";
import { handleFetchDealStages } from "../../../../api/dealStages";
import { AuthContext } from "../../../../context/AuthContext";
import { AiOutlineCheck } from "react-icons/ai";
import formatMoney from "../../../../utils/formatMoney";
import roundRawKES from "../../../../utils/roundRawKES";
const { Option } = Select;

export default function ConvertLead({ lead, lead_id, prepick, isVisible, setVisible }: any) {
  const [isAllSelected, setAllSelected] = useState(false);
  const [selected, setSelected] = useState<any>([prepick] || []);
  const [value, setValue] = useState<number>(prepick?.prices?.inclusive || 0);
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();


  const { mutate, isLoading } = useMutation({
    mutationFn: handleConvertLead,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: stages } = useQuery({
    queryKey: ["dealStages"],
    queryFn: handleFetchDealStages,
  });

  const [categories] = useState<any>([]);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const onSubmit = async () => {
    const values = await form.validateFields();

    const extracted = selected?.map((item: any) => item.chassis);

    if (extracted.length === 0) {
      message.error("Selected atleast one Vehicle");
    }

    const data = {
      user: auth?._id,
      vehicles: extracted,
      ...values,
    };

    await mutate({ lead_id, data });
    setVisible(false);
    form.resetFields();
    await queryClient.invalidateQueries({ queryKey: ["newLeads"] });
    await queryClient.invalidateQueries({ queryKey: ["newDeals"] });
    navigate("/crm/deals/new");
  };

  const stageOptions = stages?.map((stage: any, index: number) => {
    return (
      <Option value={stage._id} key={index}>
        {stage.name}
      </Option>
    );
  });

  const categoryOptions = categories?.map((item: any, index: number) => {
    return (
      <Option value={item?.cat_name} key={index}>
        {item?.cat_name}
      </Option>
    );
  });

  const handleChangeValue = (e: any) => {
    setValue(e);
  };

  const handleAddItem = (item: any) => {
    setSelected([...selected, item]);
    getTotal();
  };

  const getTotal = () => {
    let total = 0;
    selected?.forEach((item: any) => {
      total = total + item?.car?.prices?.inclusive;
    });

    form.setFieldsValue({ value: total });
    setValue(roundRawKES(total));
    return;
  };

  const handleRemoveItem = (item: any) => {
    var filtered = selected?.filter(function (value: any, index: any, arr: any) {
      return value?._id !== item?._id;
    });
    setAllSelected(false);
    setSelected(filtered);
    getTotal();
  };

  const handleAddAll = () => {
    var filtered = lead?.vehicles?.filter(function (value: any) {
      return value;
    });
    setSelected(filtered);
    setAllSelected(true);
    getTotal();
  };

  const handleRemoveAll = () => {
    setSelected([]);
    setAllSelected(false);
    getTotal();
  };

  useEffect(() => {
    getTotal();
  }, [selected]);

  useEffect(() => {
    setSelected([prepick] || []);
  }, [prepick]);

  return (
    <Modal
      open={isVisible}
      title="Convert to Deal"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="AddForm"
          danger
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Convert
        </Button>,
      ]}
    >
      <Form
        id="AddForm"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label="Title"
              initialValue={lead?.title}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Title" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="value"
              label="Value ( KES )"
              rules={[
                {
                  required: true,
                  message: "Please enter deal value",
                },
              ]}
            >
              <Input
                placeholder="Value"
                value={value}
                defaultValue={value}
                style={{ width: "100%" }}
                onChange={(e) => handleChangeValue(e.target.value)}
              />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="until"
              label="Expected close date"
              initialValue={lead?.dates?.expected_date}
              rules={[
                {
                  required: true,
                  message: "Please enter expected close date",
                },
              ]}
            >
              <DatePicker
                placeholder="Expected close date"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="stage"
              rules={[
                {
                  required: true,
                  message: "Please select stage",
                },
              ]}
            >
              <Select placeholder="Stage">{stageOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        {categories?.length === 0 ? null : (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="source_cat"
                label="Lead Source Category"
                rules={[
                  {
                    required: false,
                    message: "Please select source category",
                  },
                ]}
              >
                <Select placeholder="Source Category">{categoryOptions}</Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="source_subcat"
                label="Lead Source Details"
                rules={[
                  {
                    required: false,
                    message: "Please enter source detail",
                  },
                ]}
              >
                <Input placeholder="Source details" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        )}

        <div className="">
          <div className="flex items-center justify-between mb-4">
            <div className="flex items-center">
              <div className="">
                {isAllSelected ? (
                  <button
                    type="button"
                    className="flex justify-center items-center h-8 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                    onClick={() => handleRemoveAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                ) : (
                  <button
                    type="button"
                    className="flex justify-center items-center  h-8 w-8 border border-gray-600 hover:bg-gray-500"
                    onClick={() => handleAddAll()}
                  >
                    <AiOutlineCheck color="#FFFFFF" />
                  </button>
                )}
              </div>
              <div className="ml-2">Select All Vehicles</div>
            </div>
            <div className="">{selected?.length || 0}</div>
          </div>
          {lead?.vehicles?.map((vehicle: any, index: number) => (
            <div
              className={`flex justify-between border-t pt-4 ${
                selected?.includes(vehicle) ? "bg-green-50" : ""
              }`}
              onClick={() => {
                selected?.includes(vehicle)
                  ? handleRemoveItem(vehicle)
                  : handleAddItem(vehicle);
              }}
              key={index}
            >
              <div className="flex">
                <div className="">
                  {selected?.includes(vehicle) ? (
                    <button
                      type="button"
                      className="flex justify-center items-center h-8 w-8 border border-gray-600 hover:bg-green-600 bg-green-600"
                      onClick={() => handleRemoveItem(vehicle)}
                    >
                      <AiOutlineCheck color="#FFFFFF" />
                    </button>
                  ) : (
                    <button
                      type="button"
                      className="flex justify-center items-center h-8 w-8 border border-gray-600 hover:bg-gray-500"
                      onClick={() => handleAddItem(vehicle)}
                    >
                      <AiOutlineCheck color="#FFFFFF" />
                    </button>
                  )}
                </div>
                <div className="flex justify-start">
                  <div className="w-16 mx-2">
                    <img
                      src={vehicle?.car?.thumbnail}
                      className="w-full aspect-[4/3] object-contain"
                      style={{ width: "100%" }}
                      alt=""
                    />
                  </div>

                  <div className="">
                    <div className="">
                      {vehicle?.car?.year} {vehicle?.model?.name}
                    </div>

                    <div className="">Chassis No: {vehicle?.chassis}</div>
                <div className="">{vehicle?.deal?.isConverted ? <span className="text-green-600">Converted</span>: <span className="text-gray-500">Not Converted</span>}</div>
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end">
                <div className="">
                  {formatMoney(vehicle?.prices?.inclusive)}
                </div>
                <div className="">KES</div>
              </div>
            </div>
          ))}
        </div>
      </Form>
    </Modal>
  );
}
