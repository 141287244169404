import { useState } from "react";
import { RiFileExcel2Line } from "react-icons/ri";
import { PieChart, Pie, Sector, ResponsiveContainer } from "recharts";
import Moment from "react-moment";
import { Tooltip as Tip } from "antd";
import { useQuery } from "@tanstack/react-query";
import { handleFetchAfterApprovalReport } from "../../../../api/reports";
import { exportStockByFuel } from "../../../../utils/stockDataExport";
import formatShort from "../../../../utils/formatShort";

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    payload,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = cx + (outerRadius + 10) * cos;
  const sy = cy + (outerRadius + 10) * sin;
  const mx = cx + (outerRadius + 30) * cos;
  const my = cy + (outerRadius + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? "start" : "end";

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill="#333"
      >{`${formatShort(value)}`}</text>
      <text
        x={ex + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={18}
        textAnchor={textAnchor}
        fill="#999"
      >
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  );
};

export default function ApprovedChart({ dates }: any) {
  const [state, setState] = useState<any>({ activeIndex: 0 });

  const onPieEnter = (_: any, index: any) => {
    setState({
      activeIndex: index,
    });
  };

  const { data: stock } = useQuery({
    queryKey: ["afterApprovalReport"],
    queryFn: handleFetchAfterApprovalReport,
  });

  const getFuelData = ({ stock }: any) => {
    let d: any = [];
    stock?.forEach((item: any) => {
      d.push({
        name: item?._id ? "Approved" : "Not Approved",
        value: item?.budget,
      });
    });

    return d;
  };

  const data: any = getFuelData({ stock: stock || [] });
  
  return (
    <div className="h-96">
      <div className="flex flex-row justify-between items-center  border-b border-gray-200 pl-6 mb-4">
        <div className="py-2">
          <h4 className="text-gray-800">
            <span className="text-gray-900 font-bold text-lg">
              Service By Approval
            </span>
          </h4>
          <p className="text-gray-600 text-sm">Vehicles Grouped By Approval</p>
        </div>

        <div className="flex items-center justify-center">
          <div className="flex items-center text-gray-500 text-xs">
            {dates?.isRange ? (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment> -{" "}
                <Moment format={"Do MMM, YYYY"}>{dates?.endDate}</Moment>
              </div>
            ) : (
              <div className="font-bold">
                <Moment format={"Do MMM, YYYY"}>{dates?.startDate}</Moment>
              </div>
            )}
          </div>
          <div className="mx-2">
            <Tip title="Export To Excel">
              <button
                color="#E50000"
                className="flex justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={() => exportStockByFuel({ data: stock })}
              >
                <RiFileExcel2Line size={18} />
              </button>
            </Tip>
          </div>
        </div>
      </div>

      <div
        className="flex justify-center items-center"
        style={{ width: "100%", height: "100%" }}
      >
        <ResponsiveContainer width="100%" height="100%">
          <PieChart width={500} height={500}>
            <Pie
              activeIndex={state.activeIndex}
              activeShape={renderActiveShape}
              data={data}
              cx="40%"
              cy="40%"
              innerRadius={55}
              outerRadius={70}
              fill="#8884d8"
              dataKey="value"
              onMouseEnter={onPieEnter}
            />
          </PieChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
