import { Button, message, Drawer, Space } from "antd";
import { useState } from "react";
import { BsUpload } from "react-icons/bs";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleUploadVehicleImages } from "../../../../api/vehicles";
import { AiOutlineClose } from "react-icons/ai";
import RUG, { DropArea, DragArea, Card } from "react-upload-gallery";
import "react-upload-gallery/dist/style.css";
import convertImagesToBase64 from "../../../../utils/multiBase64";
import spin from "../../../../assets/images/spin.svg";

export default function MultiUpload({ isVisible, setVisible, chassis }: any) {
  const queryClient = useQueryClient();
  const [images, setImages] = useState<any>([]);
  const [, forceUpdate] = useState<any>();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUploadVehicleImages,
    onSuccess: () => {
      message.success("Uploaded Successfully");
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
      setVisible(false);
      clearForm();
    },
    onError: () => {
      message.error("Something went wrong");
      clearForm();
    },
  });

  const onSubmit = async () => {
    const data = await convertImagesToBase64(images);
    await mutate({ data: { images: data }, chassis });
  };

  const clearForm = async () => {
    forceUpdate({});
  };

  return (
    <Drawer
      title={`UPLOAD IMAGES`}
      placement={"right"}
      width={1000}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <RUG
        source={(response: any) => response.source}
        onChange={(images: any) => {
          setImages(images);
        }}
        onDelete={(images: any) => {
          setImages(images);
        }}
        header={({ openDialogue }: any) => (
          <DropArea>
            {(isDrag: any) => (
              <button
                className={`py-8 border  flex flex-col justify-center items-center w-full ${
                  isDrag ? "bg-green-200" : "bg-gray-50"
                }`}
                onClick={openDialogue}
              >
                <div className="mb-4">
                  <BsUpload size={30} />
                </div>
                <div className="">Drag and Drop Images Here to Upload</div>
                <div className="py-4">or</div>
                <button className="bg-green-100 py-2 px-4">
                  Select Images to Upload
                </button>
              </button>
            )}
          </DropArea>
        )}
        footer={({ images }: any) => (
          <div className="mb-4">
            <span style={{ marginRight: 20 }}>Count: {images.length}</span>
          </div>
        )}
      >
        <DragArea className="rug-items __card">
          {(image: any) => {
            return (
              <div
                style={{
                  border: `1px solid ${
                    image.selected ? "green" : "transparent"
                  }`,
                }}
              >
                <Card image={image} />
              </div>
            );
          }}
        </DragArea>
      </RUG>
      <div className="mx-1 py-4 bg-gray-200 mb-1">
        <span className="text-red-500 mx-2">NOTE : </span>IMAGES MAP ( KEY )
      </div>
      <div className="grid grid-cols-12">
        <div className="col-span-2 mx-1 p-4 bg-gray-100">FRONT 1</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">FRONT 2</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">BACK 1</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">BACK 2</div>
      </div>
      <div className="grid grid-cols-12 mt-1">
        <div className="col-span-2 mx-1 p-4 bg-gray-100">SIDE 1</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">SIDE 2</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">INTERIOR 1</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">INTERIOR 2</div>
      </div>
      <div className="grid grid-cols-12 mt-1">
        <div className="col-span-2 mx-1 p-4 bg-gray-100">INTERIOR 3</div>
        <div className="col-span-2 mx-1 p-4 bg-gray-100">INTERIOR 4</div>
        <div className="col-span-4 mx-1 p-4 bg-gray-50 text-gray-400">
          Extras ( if any )
        </div>
      </div>
      <div className="flex">
        <button
          onClick={() => onSubmit()}
          className="bg-red-600 py-3 px-8 text-white w-64 flex justify-center items-center mt-4"
        >
          {isLoading ? (
            <div className="w-5 h-5">
              <img src={spin} alt="" />
            </div>
          ) : (
            "Upload Images"
          )}
        </button>

        <button
          onClick={() => clearForm()}
          className=" border border-red-600 py-3 px-8 text-red-600 mx-2 w-64 flex justify-center items-center mt-4"
        >
          Clear
        </button>
      </div>
    </Drawer>
  );
}
