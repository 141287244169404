import { Button, Checkbox, Col, Form, Input, Row, Select, message } from "antd";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../context/AuthContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchUnfilteredVehicles } from "../../../api/vehicles";
import { handleFetchAllowances } from "../../../api/allowances";
import { handleFetchContacts } from "../../../api/contacts";
import { handleUpdateProforma } from "../../../api/proforma";
import { FiEdit, FiUserPlus } from "react-icons/fi";
import { GrRefresh } from "react-icons/gr";
import { MdOutlineAddLocationAlt } from "react-icons/md";
import { GoCheck } from "react-icons/go";
import { AiOutlinePlus } from "react-icons/ai";
import { RiDeleteBin4Line } from "react-icons/ri";
import CreateContact from "../../stock/components/CreateContact";
import CreateLocation from "../../stock/components/CreateLocation";
import roundKES from "../../../utils/roundKES";
import getProformaTotals from "../../../utils/proformaTotals";

const { Option } = Select;

export default function ProformaEdit({ proforma }: any) {
  const { auth } = useContext(AuthContext);
  const [isPercentage, setPercentage] = useState<boolean>(
    proforma?.discount?.isPercentage
  );
  const [contactModal, setContactModal] = useState<boolean>(false);
  const [locationModal, setLocationModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const [isPartial, setPartial] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any>(proforma?.vehicles);
  const [selectedVat, setSelectedVat] = useState<number>(16);
  const [selectedDis, setSelectedDis] = useState<number>(
    proforma?.discount?.amount
  );
  const [editMode, setEditMode] = useState<boolean>(false);
  const [picked, setPicked] = useState<any>(null);
  const [form] = Form.useForm();

  const { data: contacts } = useQuery({
    queryKey: ["contacts"],
    queryFn: handleFetchContacts,
  });

  const { data: allowances } = useQuery({
    queryKey: ["allowances"],
    queryFn: handleFetchAllowances,
  });

  const { data: stock } = useQuery({
    queryKey: ["sourceVehicles", { source_id: proforma?.source?.entity }],
    queryFn: handleFetchUnfilteredVehicles,
  });

  const { mutate } = useMutation({
    mutationFn: handleUpdateProforma,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      clearForm();
      queryClient.invalidateQueries({ queryKey: ["proformas"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const handleAddVehicle = async () => {
    if (picked === null) {
      return;
    }
    const v = stock?.vehicles?.filter((item: any) => item?.chassis === picked);
    await addVehicle(v[0]);
  };

  const handleRemVehicle = async (item: any) => {
    remVehicle(item);
  };

  const onSubmit = async () => {
    let discount: any = {};
    const { contact, transfer } = await form.getFieldsValue();

    discount = {
      amount: Number(selectedDis),
      isPercentage,
    };

    const extracted = vehicles?.map((item: any) => item.chassis);

    const data = {
      user: auth?._id,
      contact,
      transfer,
      vat: { category: "S", rate: 16 },
      discount,
      vehicles: extracted,
    };
    await mutate({ data, _id: proforma?._id });
  };

  const vats = [{ _id: 1, code: "S", name: "Standard - ( 16 % )" }];

  const onChangeVat = (e: any) => {
    switch (e) {
      case 1:
        setPartial(false);
        setSelectedVat(16);
        form.setFieldsValue({ partial_vat: "" });
        break;
      case 2:
        setPartial(false);
        setSelectedVat(0);
        form.setFieldsValue({ partial_vat: "" });
        break;
      case 3:
        setPartial(true);
        setSelectedVat(0);
        break;
      default:
    }
  };

  const contactOptions = contacts?.map((contact: any, index: number) => (
    <Option value={contact?._id} key={index}>
      {contact?.name}
    </Option>
  ));

  const allowanceOptions = allowances?.map((allowance: any, index: number) => (
    <Option value={allowance?._id} key={index}>
      {allowance?.location}
    </Option>
  ));

  const vatOptions = vats?.map((vat: any, index: number) => (
    <Option value={vat?._id} key={index}>
      {vat?.name} {vat?.value}
    </Option>
  ));

  const vehicleOptions = stock?.vehicles?.map((vehicle: any, index: number) => (
    <Option value={vehicle?.chassis} key={index}>
      {`${index + 1}. ${vehicle?.year} ${vehicle.model?.name} - Chassis No:  ${
        vehicle.chassis
      } ( ${vehicle?.source?.name} )`}
    </Option>
  ));

  const clearForm = async () => {
    setPartial(false);
    setEditMode(false);
    setSelectedDis(proforma?.discount?.amount);
    setPercentage(proforma?.discount?.isPercentage);
    form.setFieldsValue({ contact: proforma.contact?.entity });
    form.setFieldsValue({ transfer: proforma.transfer?.location });
  };

  const addVehicle = async (item: any) => {
    try {
      let a = vehicles;
      if (a.some((e: any) => e.chassis === item?.chassis)) {
        message.error("Vehicle already Added");
        return;
      } else {
        a.push(item);
        setVehicles(a);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const remVehicle = async (item: any) => {
    try {
      const filtered = vehicles.filter((i: any) => i.chassis !== item?.chassis);
      setVehicles(filtered);
    } catch (e) {
      console.error(e);
    }
  };

  const { discount, vat, unit, net } = getProformaTotals(proforma);

  useEffect(() => {
    const e = proforma.vehicles ? proforma?.vehicles : null;
    form.setFieldsValue({ contact: proforma.contact?.entity });
    form.setFieldsValue({ transfer: proforma.transfer?.location });
    setVehicles(e);
  }, [proforma, form]);

  return (
    <div className="">
      <Form
        id="createAllowance"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="grid grid-cols-12">
          <div className="col-span-6">
            <div className="">
              <span className="text-red-500">*</span> Contact
            </div>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                <Form.Item
                  name="contact"
                  initialValue={proforma?.contact?.entity}
                  rules={[
                    {
                      required: true,
                      message: "Please select contact",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and select contact"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {contactOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button onClick={() => setContactModal(true)}>
                  <FiUserPlus />
                </Button>
              </Col>
              <Col>
                <Button>
                  <GrRefresh />
                </Button>
              </Col>
            </Row>
          </div>
          <div className="col-span-6">
            <div className="">
              <span className="text-red-500">*</span> Transport
            </div>
            <Row>
              <Col xs={{ span: 24 }} lg={{ span: 18 }}>
                <Form.Item
                  name="transfer"
                  rules={[
                    {
                      required: true,
                      message: "Please select transport allowance",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Search and select allowance"
                    optionFilterProp="children"
                    filterOption={(input: any, option: any) =>
                      option?.children
                        ?.toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {allowanceOptions}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Button onClick={() => setLocationModal(true)}>
                  <MdOutlineAddLocationAlt />
                </Button>
              </Col>
              <Col>
                <Button>
                  <GrRefresh />
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="vat_category"
              label="VAT Category"
              rules={[
                {
                  required: false,
                  message: "Please select VAT category",
                },
              ]}
            >
              <Select
                placeholder="Select VAT category"
                defaultValue={1}
                optionFilterProp="children"
                filterOption={(input: any, option: any) =>
                  option?.children
                    ?.toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
                onChange={(e) => onChangeVat(e)}
              >
                {vatOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="discount_amount"
                  label={
                    <div className="flex">
                      <div className="">Discount</div>
                      <div className="ml-2">
                        <Checkbox
                          onChange={(e: any) => {
                            setPercentage(!isPercentage);
                          }}
                          defaultChecked={isPercentage}
                        >
                          Percentage
                        </Checkbox>
                      </div>
                    </div>
                  }
                  rules={[
                    {
                      required: false,
                      message: "Please enter discount",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    addonAfter={isPercentage ? "%" : "KES"}
                    defaultValue={selectedDis}
                    value={selectedDis}
                    onChange={(e: any) => setSelectedDis(e?.target?.value)}
                    placeholder={`Enter Discount  ${
                      isPercentage ? "( in % )" : "( in amount )"
                    }  `}
                  />
                </Form.Item>
              </Col>
          <Col xs={{ span: 24 }} lg={{ span: 4 }}></Col>
        </Row>
        {isPartial && (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="partial_vat"
                label={`Specify Partial VAT ( in % ) `}
                rules={[
                  {
                    required: true,
                    message: "Please enter discount",
                  },
                ]}
              >
                <Input
                  type="number"
                  onChange={(e: any) => setSelectedVat(e?.target?.value)}
                  placeholder={`Enter VAT ( in % )`}
                />
              </Form.Item>
            </Col>
          </Row>
        )}

        <div className="mb-8 border-b">
          <div className="flex justify-between w-full p-2 bg-gray-100 items-center border-b">
            <div className="">Vehicles</div>
            <Button
              onClick={() => setEditMode(!editMode)}
              className="border px-2 bg-white"
            >
              {editMode ? (
                <GoCheck color="#16a34a" />
              ) : (
                <FiEdit color="#ca8a04" />
              )}
            </Button>
          </div>

          {editMode && (
            <div className="mt-4">
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 20 }} lg={{ span: 22 }}>
                  <Form.Item
                    name="chassis"
                    rules={[
                      {
                        required: true,
                        message: "Please select vehicle",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search and select vehicle"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(e: any) => {
                        setPicked(e);
                      }}
                    >
                      {vehicleOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                  <Button
                    onClick={() => {
                      handleAddVehicle();
                      setPicked(null);
                    }}
                    type="primary"
                    htmlType="button"
                    className="bg-gray-400"
                  >
                    <AiOutlinePlus />
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <div className="">
            <div className="border-b flex justify-between bg-gray-50 p-2">
              <div className="flex">
                <div className="">S/n. </div>
                <div className="ml-2">Vehicle</div>
              </div>

              <div className="flex">
                <div className="w-40 text-right"></div>
                <div className="w-32 text-right">Amount ( KES )</div>
                {editMode && <div className="w-12 ml-2">Action</div>}
              </div>
            </div>
            {vehicles?.map((item: any, index: number) => (
              <div className="border-b flex justify-between p-2" key={index}>
                <div className="flex">
                  <div className="">{index + 1}. </div>
                  <div className="ml-2">
                    {item?.year} {item?.model?.name} - Chassis : {item?.chassis}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-40 text-left"></div>
                  <div className="w-32 text-right">
                    {roundKES(item?.prices?.exclusive)}
                  </div>

                  {editMode && (
                    <div className="w-12 ml-2">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => handleRemVehicle(item)}
                          className="border p-1 rounded-md ml-2 bg-red-500"
                          type="button"
                        >
                          <RiDeleteBin4Line color={"#FFF"} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="border-t flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">
                Discount ( {isPercentage ? `${selectedDis} %` : "Amount"} )
              </div>
              <div className="w-32 text-right">{roundKES(discount)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Sub Total</div>
              <div className="w-32 text-right">{roundKES(unit)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t border-gray-300 flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Vat ( {selectedVat} %)</div>
              <div className="w-32 text-right">{roundKES(vat)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t-2 border-b border-gray-600  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Grand Total</div>
              <div className="w-32 text-right">{roundKES(net)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>
          </div>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
      <CreateContact isVisible={contactModal} setVisible={setContactModal} />
      <CreateLocation isVisible={locationModal} setVisible={setLocationModal} />
    </div>
  );
}
