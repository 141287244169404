import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useState } from "react";
import { LuLock } from "react-icons/lu";

import { TbSignature } from "react-icons/tb";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import { IoTrashBinOutline } from "react-icons/io5";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import CreateUser from "./components/CreateUser";
import UploadSign from "./components/UploadSign";
import UpdateUser from "./components/UpdateUser";
import { postDataMail } from "../../../services/mail";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchUsers, handleUpdateSeller } from "../../../api/users";
import Something from "../../../components/shared/Something";
import { AuthContext } from "../../../context/AuthContext";
import ResetPassword from "./components/ResetPassword";

export default function AllUsers() {
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [resetModal, setResetModal] = useState<boolean>(false);
  const [signModal, setSignModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 20,
    total_pages: 1,
    total_count: 0,
  });

  const {
    data: users,
    refetch,
    error,
    isLoading,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["users"],
    queryFn: handleFetchUsers,
  });

  const { mutate: updateSeller } = useMutation({
    mutationFn: handleUpdateSeller,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  const handleSeller = async ({ _id, isSeller }: any) => {
    await updateSeller({ _id, data: { isSeller: !isSeller } });
  };

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Name",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.name ? record?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Email",
      width: 140,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.email ? record?.email : "-"}
        </span>
      ),
    },
    {
      title: "Mobile",
      width: 90,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.mobile ? record?.mobile : "-"}
        </span>
      ),
    },

    {
      title: "Role",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.role?.name ? record?.role?.name : "-"}
        </span>
      ),
    },

    {
      title: "Seller",
      width: 100,
      render: (record: any) => (
        <div className="text-gray-700">
          <button
            className=""
            onClick={() =>
              handleSeller({ _id: record?._id, isSeller: record?.isSeller })
            }
          >
            {record?.isSeller ? "YES" : "NO"}
          </button>
        </div>
      ),
    },

    {
      title: "Signature",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          {record?.signature?.url ? (
            <img src={record?.signature?.url} width={80} alt="" />
          ) : (
            "-"
          )}
        </span>
      ),
    },

    {
      title: "Access",
      width: 100,
      render: (record: any) => (
        <span className="text-gray-700">
          Level {record?.accessLevel || "-"}
        </span>
      ),
    },

    {
      title: "Action",
      width: 100,
      render: (record: any) => (
        <div className="flex justify-end">
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setSignModal(true);
            }}
            className="flex justify-center items-center text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <TbSignature />
          </button>
          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setUpdateModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <FiEdit />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setResetModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-gray-100 hover:bg-gray-200 cursor-pointer"
          >
            <LuLock />
          </button>

          <button
            onClick={() => {
              setSelected({
                ...record,
              });
              setDeleteModal(true);
            }}
            className="flex justify-center items-center ml-1 text-gray-700 border rounded-md p-2 bg-red-100 hover:bg-red-200 cursor-pointer"
          >
            <IoTrashBinOutline />
          </button>
        </div>
      ),
    },
  ];

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = users?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Address: item?.address === null ? "-" : item?.address,
        Email: item?.email === null ? "-" : item?.email,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Active: item?.isActive ? "Yes" : "No",
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  return (
    <Manage
      title={"Users"}
      subtitle={"All"}
      description={"List of all users"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  users?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                      Address: item?.address === null ? "-" : item?.address,
                      Email: item?.email === null ? "-" : item?.email,
                      Mobile: item?.mobile === null ? "-" : item?.mobile,
                      Active: item?.isActive ? "Yes" : "No",
                    };
                  }),
                  "Users.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
          <CreateUser
            isVisible={createModal}
            setVisible={setCreateModal}
            refetch={handleRefetch}
          />

          <UpdateUser
            isVisible={updateModal}
            setVisible={setUpdateModal}
            refetch={refetch}
            selected={selected}
          />

          <ResetPassword
            isVisible={resetModal}
            setVisible={setResetModal}
            refetch={refetch}
            selected={selected}
          />

          <UploadSign
            isVisible={signModal}
            setVisible={setSignModal}
            selected={selected}
            refetch={refetch}
          />
        </div>
      }
    >
      <>
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                {isError ? (
                  <Something refetch={refetch} isFetching={isFetching} />
                ) : (
                  <Table
                    size="small"
                    dataSource={users === undefined ? [] : users}
                    columns={columns}
                    scroll={{ y: 660, x: 0 }}
                    rowKey="id"
                    pagination={{
                      current: pagination?.page,
                      pageSize: pagination?.per_page,
                      total: 20,
                    }}
                    loading={isLoading}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    </Manage>
  );
}
