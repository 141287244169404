export default function DocumentLogo() {
  return (
    <>
      <div className="border border-black py-4 px-2 flex flex-col justify-between items-center text-xs">
        <div className="flex flex-row justify-center items-center w-full py-4">
          <img
            src="https://automark.co.tz/public/img/logo.svg"
            className="w-96"
            alt=""
          />
        </div>
        <div className="flex flex-row justify-center items-center text-2xl font-bold">
          JOB ORDER
        </div>
      </div>
    </>
  );
}
