import { useState } from "react";
import AddImage from "./AddImage";
export default function VehicleImages({ vehicle, editable }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});
  const prepareImages = () => {
    let f1: any = {};
    let f2: any = {};
    let b1: any = {};
    let b2: any = {};
    let s1: any = {};
    let s2: any = {};
    let i1: any = {};
    let i2: any = {};
    let i3: any = {};
    let i4: any = {};
    vehicle?.images?.forEach((item: any) => {
      switch (item?.location) {
        case "F1":
          f1 = item;
          break;
        case "F2":
          f2 = item;
          break;
        case "B1":
          b1 = item;
          break;
        case "B2":
          b2 = item;
          break;
        case "S1":
          s1 = item;
          break;
        case "S2":
          s2 = item;
          break;
        case "I1":
          i1 = item;
          break;
        case "I2":
          i2 = item;
          break;
        case "I3":
          i3 = item;
          break;
        case "I4":
          i4 = item;
          break;
        default:
      }
    });

    return {
      f1,
      f2,
      b1,
      b2,
      s1,
      s2,
      i1,
      i2,
      i3,
      i4,
    };
  };

  const { f1, f2, b1, b2, s1, s2, i1, i2, i3, i4 } = prepareImages();

  return (
    <div className="">
      <div className="grid gap-2 grid-cols-12 mb-4">
        <div className="col-span-6">
          {/* <div className="bg-gray-100 text-center mb-1">Front</div> */}
          <div className="flex">
            <ImageWidget
              editable={editable}
              isMain={true}
              title="Front 1"
              image={f1.url}
              location="F1"
              setSelected={setSelected}
              setVisible={setVisible}
            />
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Front 2"
              image={f2.url}
              location="F2"
              setSelected={setSelected}
              setVisible={setVisible}
            />
          </div>
        </div>
        <div className="col-span-6">
          {/* <div className="bg-gray-100 text-center mb-1">Back</div> */}
          <div className="flex">
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Back 1"
              image={b1.url}
              location="B1"
              setSelected={setSelected}
              setVisible={setVisible}
            />

            <ImageWidget
              editable={editable}
              isMain={false}
              title="Back 2"
              image={b2.url}
              location="B2"
              setSelected={setSelected}
              setVisible={setVisible}
            />
          </div>
        </div>
      </div>
      <div className="grid gap-2 grid-cols-12">
        <div className="col-span-6">
          {/* <div className="bg-gray-100 text-center mb-1">Interiors</div> */}
          <div className="flex">
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Interior 1"
              image={i1.url}
              location="I1"
              setSelected={setSelected}
              setVisible={setVisible}
            />
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Interior 2"
              image={i2.url}
              location="I2"
              setSelected={setSelected}
              setVisible={setVisible}
            />
          </div>
          <div className="flex mt-1">
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Interior 3"
              image={i3.url}
              location="I3"
              setSelected={setSelected}
              setVisible={setVisible}
            />
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Interior 4"
              image={i4.url}
              location="I4"
              setSelected={setSelected}
              setVisible={setVisible}
            />
          </div>
        </div>
        <div className="col-span-6">
          <div className="flex">
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Side 1"
              image={s1.url}
              setSelected={setSelected}
              setVisible={setVisible}
            />
            <ImageWidget
              editable={editable}
              isMain={false}
              title="Side 2"
              image={s2.url}
              setSelected={setSelected}
              setVisible={setVisible}
            />
          </div>
        </div>
      </div>
      <AddImage
        isVisible={isVisible}
        setVisible={setVisible}
        selected={selected}
        chassis={vehicle?.chassis}
      />
    </div>
  );
}

export function ImageWidget({
  editable,
  location,
  title,
  isMain,
  image,
  setVisible,
  setSelected,
}: any) {
  if (!image) return null;
  return (
    <div
      onClick={() => {
        if (editable) {
          setVisible(true);
          setSelected({
            location,
            isMain,
            title,
          });
        }
      }}
      className="cursor-pointer ml-1"
    >
      <img
        src={image}
        className="w-full aspect-[4/4] border object-contain"
        style={{ width: 600, backgroundColor: "#ECECEC" }}
        alt=""
      />
    </div>
  );
}
