import { Button, Modal, Form, Row, Col, Select, Input, message } from "antd";
import { PasswordInput } from "antd-password-input-strength";
import zxcvbn from "zxcvbn";
import { useState } from "react";
import { BsCheck2Circle } from "react-icons/bs";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleFetchRoles } from "../../../../api/roles";
import { handleCreateUser } from "../../../../api/users";
import getBase64 from "../../../../utils/getBase64";

const { Option } = Select;

export default function CreateUser({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [isSelected, setIsSelected] = useState(false);
  const [selectedFile, setSelectedFile]: any = useState(null);
  const [form] = Form.useForm();

  const changeHandler = (event: any) => {
    setSelectedFile(event.target.files[0]);
    setIsSelected(true);
  };

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: handleFetchRoles,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateUser,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["users"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    let base64 = null

    const { name, mobile, mobile2, email, roleId, accessLevel, password } =
      await form.getFieldsValue();

    if (selectedFile) {
      base64 = await getBase64(selectedFile);
    }
    const score = password.length === 0 ? -1 : zxcvbn(password).score;
    if (score < 3) {
      message.error("Use stronger password");
    } else {
      const role = roles.filter((item: any) => item?._id === roleId);
      const data = {
        name,
        email,
        mobile,
        mobile2,
        password,
        designation: role[0]?.name,
        role: roleId,
        accessLevel,
        signature: base64,
      };
      await mutate(data);
    }
    setVisible(false);
  };

  const roleOptions = roles?.map((level: any, index: number) => (
    <Option value={level?._id} key={index}>
      {level?.name}
    </Option>
  ));

  const clearForm = async () => {
    form.resetFields();
    setSelectedFile(null);
    setIsSelected(false);
  };

  return (
    <Modal
      open={isVisible}
      title="New User"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createUser"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createUser"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input type="text" placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input type="text" placeholder="Email" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="password"
              label={"Password"}
              rules={[
                {
                  required: true,
                  message: `Please enter Password`,
                },
              ]}
            >
              <PasswordInput placeholder="Password" style={{ width: "95%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile 1"
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input type="text" placeholder="Mobile" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile2"
              label="Mobile 2 (Optional)"
              rules={[
                {
                  required: false,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input type="text" placeholder="Mobile" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="roleId"
              label={"Designation"}
              rules={[
                {
                  required: true,
                  message: "Please select designation",
                },
              ]}
            >
              <Select placeholder="Designation">{roleOptions}</Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="accessLevel"
              label={"Access Level"}
              rules={[
                {
                  required: true,
                  message: "Please select access level",
                },
              ]}
            >
              <Select placeholder="Access Level">
                <Option value={1}>Level 1</Option>
                <Option value={2}>Level 2</Option>
                <Option value={3}>Level 3</Option>
                <Option value={4}>Level 4</Option>
                <Option value={9}>Level 9</Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <div className="flex flex-col justify-center items-center bg-gray-100 h-32 rounded-md border">
          <div className="flex flex-col justify-center items-center">
            {isSelected ? (
              <div className="flex flex-col justify-center items-center">
                <BsCheck2Circle color={"#16a34a"} size={30} />
                <div className="text-gray-600 mt-2">Image Selected</div>
                <div className="text-gray-500">{selectedFile?.name}</div>
              </div>
            ) : (
              <Form.Item
                name="file"
                label="Upload Signature ( Optional )"
                rules={[
                  {
                    required: false,
                    message: "Please select file",
                  },
                ]}
              >
                <Input
                  type="file"
                  placeholder="Signature"
                  onChange={changeHandler}
                />
              </Form.Item>
            )}
          </div>
        </div>
      </Form>
    </Modal>
  );
}
