import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect } from "react";
import { handleUpdateMonthBudget } from "../../../../api/budget";

export default function UpdateMonthBudget({
  isVisible,
  setVisible,
  year,
  number,
  _id,
  selected,
}: any) {

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateMonthBudget,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["budgets", year] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values= await form.getFieldsValue();
    const data = {
      year,
      name: selected?.name,
      month: number?.toString(),
      ...values,
    };
    await mutate({ _id, data, year, month:number });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ sales_count: selected?.sales?.count });
    form.setFieldsValue({ sales_value: selected?.sales?.value });
    form.setFieldsValue({ leads_count: selected?.leads?.count });
    form.setFieldsValue({ leads_value: selected?.leads?.value });
    form.setFieldsValue({ deals_count: selected?.deals?.count });
    form.setFieldsValue({ deals_value: selected?.deals?.value });
    form.setFieldsValue({ contacts_count: selected?.contacts });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title=<div className="uppercase border-b-2 pb-2">{`${selected?.name}  : Update Budget`}</div>
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateMonthBudget"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateMonthBudget"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <div className="font-bold">1. SALES BUDGET</div>
        
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="sales_count"
              label="COUNT"
              rules={[
                {
                  required: true,
                  message: "Please enter sales count",
                },
              ]}
            >
              <Input type="" placeholder="COUNT" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="sales_value"
              label="VALUE  ( KES )"
              rules={[
                {
                  required: true,
                  message: "Please enter sales value",
                },
              ]}
            >
              <Input type="" placeholder="VALUE" />
            </Form.Item>
          </Col>
        </Row>

        <div className="font-bold">2. LEADS BUDGET</div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="leads_count"
              label="COUNT"
              rules={[
                {
                  required: true,
                  message: "Please enter leads count",
                },
              ]}
            >
              <Input type="" placeholder="COUNT" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="leads_value"
              label="VALUE  ( KES )"
              rules={[
                {
                  required: true,
                  message: "Please enter leads value",
                },
              ]}
            >
              <Input type="" placeholder="VALUE" />
            </Form.Item>
          </Col>
        </Row>


        <div className="font-bold">3. DEALS BUDGET</div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="deals_count"
              label="COUNT"
              rules={[
                {
                  required: true,
                  message: "Please enter deals count",
                },
              ]}
            >
              <Input type="" placeholder="COUNT" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="deals_value"
              label="VALUE  ( KES )"
              rules={[
                {
                  required: true,
                  message: "Please enter deals value",
                },
              ]}
            >
              <Input type="" placeholder="VALUE" />
            </Form.Item>
          </Col>
        </Row>



        <div className="font-bold">4. CONTACTS BUDGET</div>
        <Row gutter={[16, 0]}>
        <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="contacts_count"
              label="COUNT"
              rules={[
                {
                  required: true,
                  message: "Please enter contact count",
                },
              ]}
            >
              <Input type="" placeholder="COUNT" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
