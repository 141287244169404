export default  function salesStats({ budget, actual }: any) {


  const sales : any = calculateQuantityAndAmount(actual)

  const data = [
    {
      Month: "Jan",
      budgetValue: budget?.months?.[1]?.sales?.value || 0,
      budgetCount: budget?.months?.[1]?.sales?.count || 0,
      actualValue: sales[1]?.amount || 0,
      actualCount: sales[1]?.quantity || 0,
    },
    {
      Month: "Feb",
      budgetValue: budget?.months?.[2]?.sales?.value || 0,
      budgetCount: budget?.months?.[2]?.sales?.count || 0,
      actualValue: sales[2]?.amount || 0,
      actualCount: sales[2]?.quantity || 0,
    },
    {
      Month: "Mar",
      budgetValue: budget?.months?.[3]?.sales?.value || 0,
      budgetCount: budget?.months?.[3]?.sales?.count || 0,
      actualValue: sales[3]?.amount || 0,
      actualCount: sales[3]?.quantity || 0,
    },
    {
      Month: "Apr",
      budgetValue: budget?.months?.[4]?.sales?.value || 0,
      budgetCount: budget?.months?.[4]?.sales?.count || 0,
      actualValue: sales[4]?.amount || 0,
      actualCount: sales[4]?.quantity || 0,
    },
    {
      Month: "May",
      budgetValue: budget?.months?.[5]?.sales?.value || 0,
      budgetCount: budget?.months?.[5]?.sales?.count || 0,
      actualValue: sales[5]?.amount || 0,
      actualCount: sales[5]?.quantity || 0,
    },
    {
      Month: "Jun",
      budgetValue: budget?.months?.[6]?.sales?.value || 0,
      budgetCount: budget?.months?.[6]?.sales?.count || 0,
      actualValue: sales[6]?.amount || 0,
      actualCount: sales[6]?.quantity || 0,
    },
    {
      Month: "Jul",
      budgetValue: budget?.months?.[7]?.sales?.value || 0,
      budgetCount: budget?.months?.[7]?.sales?.count || 0,
      actualValue: sales[7]?.amount || 0,
      actualCount: sales[7]?.quantity || 0,
    },
    {
      Month: "Aug",
      budgetValue: budget?.months?.[8]?.sales?.value || 0,
      budgetCount: budget?.months?.[8]?.sales?.count || 0,
      actualValue: sales[8]?.amount || 0,
      actualCount: sales[8]?.quantity || 0,
    },
    {
      Month: "Sep",
      budgetValue: budget?.months?.[9]?.sales?.value || 0,
      budgetCount: budget?.months?.[9]?.sales?.count || 0,
      actualValue: sales[9]?.amount || 0,
      actualCount: sales[9]?.quantity || 0,
    },
    {
      Month: "Oct",
      budgetValue: budget?.months?.[10]?.sales?.value || 0,
      budgetCount: budget?.months?.[10]?.sales?.count || 0,
      actualValue: sales[10]?.amount || 0,
      actualCount: sales[10]?.quantity || 0,
    },
    {
      Month: "Nov",
      budgetValue: budget?.months?.[11]?.sales?.value || 0,
      budgetCount: budget?.months?.[11]?.sales?.count || 0,
      actualValue: sales[11]?.amount || 0,
      actualCount: sales[11]?.quantity || 0,
    },
    {
      Month: "Dec",
      budgetValue: budget?.months?.[12]?.sales?.value || 0,
      budgetCount: budget?.months?.[12]?.sales?.count || 0,
      actualValue: sales[12]?.amount || 0,
      actualCount: sales[12]?.quantity || 0,
    },
  ];
  return data;
}


export function calculateQuantityAndAmount(vehicles: any) {
  const result: any = {};

   vehicles?.forEach((vehicle: any) => {
    const { sold_month } = vehicle.sales;
    const pricePerUnit = vehicle.prices.exclusive;

    if (!result[sold_month]) {
      result[sold_month] = {
        quantity: 1,
        amount: pricePerUnit
      };
    } else {
      result[sold_month].quantity += 1;
      result[sold_month].amount += pricePerUnit;
    }
  });

  return result;
}
