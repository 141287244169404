import { useState } from "react";
import { FiEdit } from "react-icons/fi";
import { TbSourceCode } from "react-icons/tb";
import formatMoney from "../../../../utils/formatMoney";
import { capitalize } from "lodash";
import UpdateStatus from "./UpdateStatus";
import UpdateTitle from "./UpdateTitle";
import UpdatePrice from "./UpdatePrice";

export default function LeadBasics({ lead, lead_id, access }: any) {
  const [statusModal, setStatusModal] = useState<boolean>(false);
  const [priceModal, setPriceModal] = useState<boolean>(false);
  const [titleModal, setTitleModal] = useState<boolean>(false);
  return (
    <div className="">
      <div className="flex justify-start items-center bg-white p-2 border-b-4 border-gray-300 mb-1 h-10">
        <div className="font-bold text-sm">
          {lead?.contact?.name?.toUpperCase()}
        </div>
      </div>
      <div className="">
        <div className="bg-white border p-4 mb-1 flex flex-col justify-start">
          <div className="flex flex-col justify-center">
            <div className="flex justify-between">
              <div className="font-bold text-xl">
                {lead?.title || "-"}
                {access && (
                  <button onClick={() => setTitleModal(true)} className="px-1">
                    <FiEdit size={15} />
                  </button>
                )}
              </div>
            </div>
            <div className="flex justify-between">
              <div className="flex justify-start items-center">
                <div className="text-gray-600">
                   {formatMoney(lead?.value || 0)} <span className="text-xs">KES</span>
                </div>
                {access && (
                  <button onClick={() => setPriceModal(true)} className="px-1">
                    <FiEdit />
                  </button>
                )}
              </div>
              <div className="flex justify-center  items-center">
                <div className="">{capitalize(lead?.status?.state)}</div>
                {access && (
                  <button onClick={() => setStatusModal(true)} className="px-1">
                    <FiEdit />
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center mt-4">
            <div className="flex items-center">
              <TbSourceCode />
              <span className="text-gray-600 font-bold ml-1">
                Lead Source :{" "}
              </span>
            </div>
            <span className="ml-4 text-gray-500 text-xs">
              {lead?.source?.name || "-"}
            </span>
          </div>
        </div>
      </div>

      <UpdateStatus
        isVisible={statusModal}
        setVisible={setStatusModal}
        lead={lead}
        lead_id={lead_id}
      />
      <UpdateTitle
        isVisible={titleModal}
        setVisible={setTitleModal}
        lead={lead}
        title={lead?.title || ""}
        lead_id={lead_id}
      />
      <UpdatePrice
        isVisible={priceModal}
        setVisible={setPriceModal}
        lead={lead}
        value={lead?.value || ""}
        lead_id={lead_id}
      />
    </div>
  );
}
