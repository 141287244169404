import { AiOutlineCar, AiOutlineFileSearch } from "react-icons/ai";
import { BsLayoutTextWindowReverse } from "react-icons/bs";
import { FiBarChart2, FiUsers } from "react-icons/fi";
import { GoHome } from "react-icons/go";
import { IoSettingsOutline } from "react-icons/io5";
import { VscFiles } from "react-icons/vsc";
import logo from "../../assets/images/logo.png";
import SideMenu from "./SideMenu";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function SideBar() {
  const { auth } = useContext(AuthContext);

  const menus = [
    {
      id: 1,
      access: [0, 1, 2, 3, 4,9],
      name: "Dashboard",
      path: "/dashboard",
      icon: <GoHome size={26} />,
    },
    {
      id: 2,
      name: "Stock",
      access: [0, 2, 3, 4,9],
      path: "/stock",
      icon: <AiOutlineCar size={26} />,
    },
    {
      id: 3,
      name: "Inquiries",
      access: [0, 1, 2, 4,9],
      path: "/inquiries",
      icon: <AiOutlineFileSearch size={26} />,
    },
    {
      id: 4,
      name: "Pro-forma",
      access: [0, 2, 3, 4,9],
      path: "/proforma",
      icon: <VscFiles size={26} />,
    },
    {
      id: 5,
      name: "CRM",
      access: [0, 1, 2, 3, 4,9],
      path: "/crm",
      icon: <FiUsers size={26} />,
    },
    {
      id: 6,
      name: "Services",
      access: [0, 2, 3, 4,9],
      path: "/services",
      icon: <BsLayoutTextWindowReverse size={22} />,
    },
    {
      id: 7,
      name: "Reports",
      access: [0, 3, 4,9],
      path: "/reports",
      icon: <FiBarChart2 size={26} />,
    },
    {
      id: 8,
      access: [3, 4, 9],
      name: "Manage",
      path: "/manage",
      icon: <IoSettingsOutline size={26} />,
    },
  ];

  return (
    <div className="w-24 min-h-screen bg-white">
      <div className="">
        <div className="h-16 bg-automark-logo flex justify-center items-center border-r border-red-700">
          <img src={logo} alt="" height={20} width={50} />
        </div>

        <div className="border-r min-h-screen">
          <div className="">
            {menus?.map((menu: any, index: number) => {
              if (menu?.access?.includes(auth?.accessLevel)) {
                return <SideMenu menu={menu} key={index} />;
              }
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
