import Moment from "react-moment";
import formatMoney from "../../../../utils/formatMoney";
import { formatNumber } from "../../../../utils/formatNumber";
import { BiPlus } from "react-icons/bi";
import { useState } from "react";
import ConvertLead from "./ConvertLead";
import CreateProforma from "./CreateProforma";
import { Button } from "antd";
import UpdateVehicles from "./UpdateVehicles";
import { AiOutlineDelete } from "react-icons/ai";
import RemoveVehicle from "./RemoveVehicle";

export default function VehicleDetails({ lead, access }: any) {
  const [convertModal, setConvertModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [proformaModal, setProformaModal] = useState<boolean>(false);
  const [vehiclesModal, setVehiclesModal] = useState<boolean>(false);
  const [prepick, setPrePick] = useState<any>(null);

  return (
    <div className="text-xs border">
      <div className="flex justify-between items-center bg-white p-2  h-12">
        <div className="font-bold text-sm"> VEHICLE (S)</div>
        {access && (
          <Button className="" onClick={() => setVehiclesModal(true)}>
            +
          </Button>
        )}
      </div>
      <div className="">
        {lead?.vehicles?.map((vehicle: any, index: number) => (
          <div className="p-4 border-b" key={index}>
            <div className="w-full flex flex-col justify-between pb-2 mb-2 font-bold text-sm">
              <div className="text-lg">
                {index + 1} : {vehicle?.car?.year} {vehicle?.model?.name}
              </div>
              <div className="flex justify-between">
                <div className="flex">
                  <div className="flex">
                    {vehicle?.proforma?.isQuoted ? (
                      <button
                        className="border bg-gray-100 p-2 text-gray-300"
                        disabled
                      >
                        QUOTED
                      </button>
                    ) : (
                      <div className="">
                        {access && (
                          <button
                            onClick={() => {
                              setPrePick(vehicle);
                              setProformaModal(true);
                            }}
                            className="border border-blue-300 bg-blue-100 p-2 flex justify-center items-center"
                          >
                            <BiPlus />
                            <span className="ml-1">PROFORMA</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="ml-1">
                    {vehicle?.deal?.isConverted ? (
                      <button
                        className="border bg-gray-100 p-2 text-gray-300"
                        disabled
                      >
                        CONVERTED
                      </button>
                    ) : (
                      <div className="">
                        {access && (
                          <button
                            onClick={() => {
                              setPrePick(vehicle);
                              setConvertModal(true);
                            }}
                            className="border border-green-300 bg-green-100 p-2 flex justify-center items-center"
                          >
                            <BiPlus />
                            <span className="ml-1">CONVERT</span>
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                </div>
                {access && (
                  <button
                    onClick={() => {
                      setPrePick(vehicle);
                      setDeleteModal(true);
                    }}
                    className=" px-2 ml-2 flex justify-center items-center"
                  >
                    <AiOutlineDelete size={20} color="#E50000" />
                  </button>
                )}
              </div>
            </div>

            <div className="grid grid-cols-12 gap-x-2 bg-white mb-1">
              <div className="col-span-6">
                <div className="mb-2">
                  <span className="mr-1">Chassis: </span>
                  <span className="text-gray-600">
                    {vehicle?.chassis || "-"}
                  </span>
                </div>
                <div className="flex justify-center items-center">
                  <img
                    src={vehicle?.car?.thumbnail}
                    className="w-full aspect-[4/3] object-contain"
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <div className="mb-2">
                  <span className="mr-1">Seling Price: </span>
                  <span className="text-gray-600">
                    
                    {vehicle?.car?.prices?.inclusive
                      ? formatMoney(vehicle?.car?.prices?.inclusive)
                      : 0} <span className="text-xs">KES</span>
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Availability: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.availability?.title || "-"},{" "}
                    {vehicle?.car?.availability?.description || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Status: </span>
                  <span className="capitalize">{vehicle?.car?.status}</span>
                </div>
              </div>
              <div className="col-span-6">
                <div className="mb-2">
                  <span className="mr-1">Fuel: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.specifications?.fuel?.fuel_type || "-"}
                  </span>
                </div>

                <div className="mb-2">
                  <span className="mr-1">Year: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.year || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Registration: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.registration || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Shipment Date: </span>
                  <span className="text-gray-600">
                    <Moment format="Do MMM, YYYY">
                      {vehicle?.car?.shipment?.date || "-"}
                    </Moment>
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Bond Arrival Date: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.arrival ? (
                      <Moment format="Do MMM, YYYY">
                        {vehicle?.car?.arrival || "-"}
                      </Moment>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Transmission: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.specifications?.transmission || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Import Invoice: </span>
                  <span className="text-gray-600">
                    {vehicle?.import_invoice || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Mileage: </span>
                  <span className="text-gray-600">
                    {formatNumber(vehicle?.car?.mileage || "-")} Km
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">VSB: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.vsb || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Exterior Color: </span>
                  <span className="capitalize">
                    {vehicle?.car?.colors?.exterior || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Source: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.source?.name || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Location : </span>
                  <span className="text-gray-600">
                    {vehicle?.location?.name || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      <ConvertLead
        isVisible={convertModal}
        lead={lead}
        lead_id={lead?._id}
        setVisible={setConvertModal}
        prepick={prepick}
      />

      <CreateProforma
        isVisible={proformaModal}
        lead={lead}
        lead_id={lead?._id}
        setVisible={setProformaModal}
        prepick={prepick}
      />

      <UpdateVehicles
        isVisible={vehiclesModal}
        lead={lead}
        lead_id={lead?._id}
        setVisible={setVehiclesModal}
        prepick={lead?.vehicles || []}
      />
      <RemoveVehicle
        isVisible={deleteModal}
        lead={lead}
        lead_id={lead?._id}
        prepick={prepick}
        setVisible={setDeleteModal}
      />
    </div>
  );
}
