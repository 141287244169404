import { Button, Form, Input, message, Popover, Table, Tooltip } from "antd";
import { useContext, useEffect, useState } from "react";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
} from "react-icons/ri";
import { blobToDataUrl, getExportData } from "../../../utils/exportExcel";

import Manage from "..";
import CreateVehicle from "./components/CreateVehicle";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { BsCheck2Square } from "react-icons/bs";
import { useNavigate } from "react-router-dom";

import thumbnail from "../../../assets/images/photo.png";
import { handleFetchVehicles } from "../../../api/vehicles";
import { capitalize } from "lodash";
import formatMoney from "../../../utils/formatMoney";
import { IoWarningOutline } from "react-icons/io5";
import { GoDotFill } from "react-icons/go";
import { vehicleStatusColors } from "../../../constants/colors";
import { FiUpload } from "react-icons/fi";
import UploadStock from "./components/UploadStock";
import { BiLoader } from "react-icons/bi";
import { AuthContext } from "../../../context/AuthContext";
import Moment from "react-moment";
const REACT_APP_AUTO_BASE_URL = process.env.REACT_APP_AUTO_BASE_URL

const { Search } = Input;

export default function AllVehicles() {
  const { auth } = useContext(AuthContext);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [isDownloading, setDownloading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [uploadModal, setUploadModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [advSearch, setAdvSearch] = useState(false);
  const [form] = Form.useForm();

  const createAccess = [2, 4, 9];

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 0,
  });

  const initials = {
    chassis: "",
    vsb: "",
    model: "",
    source: "",
    status: "",
    transmission: "",
    exteriorColor: "",
    location: "",
    fuel: "",
    availability: "",
    minYear: "",
    maxYear: "",
    minPrice: "",
    maxPrice: "",
    minMileage: "",
    maxMileage: "",
  };
  const [filters, setFilters] = useState<any>({ ...initials });

  const params = `&chassis=${filters?.chassis}&vsb=${filters?.vsb}&model=${filters?.model}&source=${filters?.source}&status=${filters?.status}&transmission=${filters?.transmission}&exteriorColor=${filters?.exteriorColor}&location=${filters?.location}&fuel=${filters?.fuel}&availability=${filters?.availability}&minYear=${filters?.minYear}&maxYear=${filters?.maxYear}&minPrice=${filters?.minPrice}&maxPrice=${filters?.maxPrice}&minMileage=${filters?.minMileage}&maxMileage=${filters?.maxMileage}`;

  const {
    data: stock,
    refetch,
    isFetching,
    isFetched,
    isLoading,
    isError,
  } = useQuery(["vehicles", { pagination, params }], handleFetchVehicles);

  const [emailForm] = Form.useForm();
  const navigate = useNavigate();

  const handleRefetch = () => {
    refetch();
  };

  function bufferToExcel(buffer: any, fileName: any) {
    const arrayBuffer = new Uint8Array(buffer).buffer;
    const data = new Blob([arrayBuffer], { type: "application/octet-stream" });

    const blobUrl = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = blobUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(blobUrl);
  }

  const columns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Thumbnail",
      width: 50,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            <img
              src={record?.thumbnail || thumbnail}
              className="w-full aspect-[4/3] object-contain"
              style={{ width: 50 }}
              alt=""
            />
          </span>
        );
      },
    },
    {
      title: "Chassis",
      width: 180,
      render: (record: any) => {
        return (
          <div className="flex flex-col">
            <div className="text-gray-700">
              Chassis:{" "}
              <span className="text-black font-bold">
                {record?.chassis ? record?.chassis : "-"}
              </span>
            </div>
            <div className="text-gray-700">
              Model: {record?.model?.name ? record?.model?.name : "-"}
            </div>
          </div>
        );
      },
    },
    {
      title: "Customer",
      width: 140,
      render: (record: any) => {
        return (
          <div className="">
            <div className="text-gray-700">
              Customer:{" "}
              <span className="text-black font-bold">
                {record?.owner?.name || "-"}
              </span>
            </div>
            <div className="">Registration: {record?.registration || "-"}</div>
          </div>
        );
      },
    },

    {
      title: "Location",
      width: 100,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.location?.name ? record?.location?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Status",
      width: 110,
      render: (record: any) => {
        return (
          <div>
            <div className="text-gray-700 flex items-center">
              <GoDotFill color={vehicleStatusColors[record?.status]} />
              <span className="ml-1 uppercase ">
                {record?.status ? capitalize(record?.status) : "-"}
              </span>
            </div>
            {record?.status === "sold" && record?.sales?.sold_date && (
              <div className="">
                <Moment
                  className="ml-4"
                  style={{ fontSize: 12 }}
                  format="Do MMM, YYYY"
                >
                  {record?.sales?.sold_date}
                </Moment>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Source",
      width: 80,
      render: (record: any) => {
        return (
          <span className="text-gray-700">
            {record?.source?.name ? record?.source?.name : "-"}
          </span>
        );
      },
    },
    {
      title: "Published",
      width: 100,
      render: (record: any) => {
        return (
          <div className="">
            {record?.flags?.isPublished ? (
              <div className="flex items-center">
                <BsCheck2Square color="#16a34a" />
                <span className="ml-1 text-green-600">YES</span>
              </div>
            ) : (
              <div className="flex items-center">
                <IoWarningOutline color="#dc2626" />
                <span className="ml-1 text-red-600">NO</span>
              </div>
            )}
          </div>
        );
      },
    },
    {
      title: "Price (Excl.) KES",
      width: 100,
      
      render: (record: any) => {
        return (
          <div className="flex justify-end">
            <span className="text-gray-700">
              {record?.prices?.inclusive
                ? `${formatMoney(record?.prices?.exclusive)}`
                : "-"}
            </span>
          </div>
        );
      },
    },
  ];

  const handleExportData = async () => {
    setDownloading(true);
    const res = await fetch(`${REACT_APP_AUTO_BASE_URL}/api/vehicles/all/download`, {
      method: "GET",
    });

    const arrayBuffer = await res.arrayBuffer();
    const buffer = new Uint8Array(arrayBuffer);
    bufferToExcel(buffer, "Stock.xlsx");
    setDownloading(false);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const mapped = stock?.vehicles?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Chassis: item?.chassis === null ? "-" : item?.chassis,
        Registration: item?.registration === null ? "-" : item?.registration,
        Model: item?.model?.name === null ? "-" : item?.model?.name,
        Location: item?.location?.name === null ? "-" : item?.location?.name,
        Source: item?.source?.name === null ? "-" : item?.source?.name,
        Mileage: item?.mileage === null ? "-" : item?.mileage,
        VSB: item?.vsb === null ? "-" : item?.vsb,
        Year: item?.year === null ? "-" : item?.year,
        Availability: item?.availability === null ? "-" : item?.availability,
        Arrival: item?.arrival === null ? "-" : item?.arrival,
        Status: item?.status === null ? "-" : item?.status,
      };
    });

    const raw = getExportData(mapped);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const clearAllFilters = () => {
    form.resetFields();
    setAdvSearch(false);
    setFilters({ ...initials });
  };

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: stock?.total_count,
    });
  };

  useEffect(() => {
    if (stock?.total_count) {
      setPagination({
        ...pagination,
        total_count: stock?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <Manage
      title={"Vehicles"}
      subtitle={"All"}
      description={"List of all vehicles"}
      actions={
        <div className="flex">
          <Tooltip title="Upload Stock">
            <button
              className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={async () => {
                setUploadModal(true);
              }}
            >
              <FiUpload size={18} />
            </button>
          </Tooltip>
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button danger className="bg-automark" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>

          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() => handleExportData()}
            >
              {isDownloading ? (
                <BiLoader size={20} />
              ) : (
                <RiFileExcel2Line size={20} />
              )}
            </button>
          </Tooltip>
          {createAccess?.includes(auth?.accessLevel) && (
            <Button onClick={() => setCreateModal(true)}>+ New</Button>
          )}
        </div>
      }
    >
      <>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="h-12 border-b flex justify-between items-center px-2 bg-white">
              <Form layout="inline" form={form}>
                <Form.Item name="chassis">
                  <Search
                    placeholder="Search by chassis"
                    allowClear
                    className="rounded-none"
                    onSearch={(e: any) => {
                      setFilters({ ...filters, chassis: e });
                    }}
                  />
                </Form.Item>
                <Form.Item name="vsb">
                  <Search
                    placeholder="Search by VSB"
                    allowClear
                    className="rounded-none"
                    onSearch={(e: any) => {
                      setFilters({ ...filters, vsb: e });
                    }}
                  />
                </Form.Item>
                <Button
                  className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
                  onClick={() => {
                    clearAllFilters();
                  }}
                >
                  <RiFilterOffLine color="gray" />
                </Button>
              </Form>
            </div>

            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="small"
                  dataSource={stock === undefined ? [] : stock?.vehicles}
                  columns={columns}
                  rowKey="id"
                  loading={isLoading}
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        navigate(`/manage/stock/${record?.chassis}/details`);
                      },
                    };
                  }}
                  pagination={{
                    current: pagination?.page,
                    pageSize: pagination?.per_page,
                    total: stock?.total_count || pagination?.total_count,
                  }}
                  onChange={onChange}
                  rowClassName={"hover:cursor-pointer hover:bg-red-50"}
                />

                <CreateVehicle
                  isVisible={createModal}
                  setVisible={setCreateModal}
                  refetch={handleRefetch}
                />
              </div>
            </div>
          </div>
        )}
      </>

      <UploadStock isVisible={uploadModal} setVisible={setUploadModal} />
    </Manage>
  );
}
