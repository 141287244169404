import { Button, Col, Form, Input, Modal, Row, message } from "antd";
import { handleUpdateLead } from "../../../../api/leads";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
export default function UpdatePrice({
  value,
  lead,
  lead_id,
  isVisible,
  setVisible,
}: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateLead,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { value } = await form.getFieldsValue();
    const data = {
      value,
    };
    await mutate({ data, _id: lead_id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [lead]);

  return (
    <Modal
      open={isVisible}
      title="Update Price"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateLeadPrice"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateLeadPrice"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="value"
              label="Value ( KES )"
              initialValue={value}
              rules={[
                {
                  required: true,
                  message: "Please enter value ( KES )",
                },
              ]}
            >
              <Input placeholder="Value ( KES )" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
