import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Form, Row, Col, Input, message, Drawer } from "antd";
import { useEffect } from "react";
import { handleUpdateContact } from "../../../../api/contacts";
import { AiOutlineClose } from "react-icons/ai";

export default function UpdateProforma({
  isVisible,
  setVisible,
  selected,
}: any) {
  const { _id, name } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleUpdateContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["proformas"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name } = await form.getFieldsValue();
    const data = {
      name,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">PROFORMA : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">
            {selected?.contact?.name}
          </div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(false)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </>
      }
    >
      <Form
        id="updateProforma"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
}
