export default function DocumentFooter() {
  return (
    <div className="flex w-full justify-between border-t-2 border-black mt-1 pt-1">
      {/* DAR ES SALAAM */}
      <div className="w-1/4">
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
      </div>
      {/* ARUSHA */}
      <div className="w-1/4">
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
      </div>
      {/* TANGA */}
      <div className="w-1/4">
      <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100 w-40">-</div>
      </div>
      {/* EMAIL */}
      <div className="w-1/4">
      <div className="font-bold h-4 mb-1 bg-gray-100">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100">-</div>
        <div className="font-bold h-4 mb-1 bg-gray-100">-</div>
      </div>
    </div>
  );
}
