import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  message,
} from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleUpdateContactDate } from "../../../../api/contacts";
import { useEffect } from "react";

const { TextArea } = Input;

export default function UpdateDate({
  isVisible,
  setVisible,
  contact,
  selected,
}: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateContactDate,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      ...values,
    };
    await mutate({ data, _id: contact?._id, date_id: selected?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ date: selected?.date });
    form.setFieldsValue({ description: selected?.description });
    form.setFieldsValue({ note: selected?.note });
  }, [contact, selected]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">UPDATE : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Date</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateContactDate"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="date"
                label="Date"
                rules={[
                  {
                    required: true,
                    message: "Please select date",
                  },
                ]}
              >
                <DatePicker placeholder="Date" format={"DD/MM/YYYY"} style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="description"
                label="Description"
                initialValue={selected?.description}
                rules={[
                  {
                    required: true,
                    message: "Please enter description",
                  },
                ]}
              >
                <TextArea placeholder="Description" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              <Form.Item
                name="note"
                label="Other Notes ( Optional )"
                initialValue={selected?.note}
                rules={[
                  {
                    required: false,
                    message: "Please enter notes",
                  },
                ]}
              >
                <TextArea placeholder="Other Notes" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
