import { useContext, useRef, useState } from "react";

import CarModel from "../../assets/images/hilux.png";
import CompleteService from "./components/CompleteService";
import ReactToPrint from "react-to-print";
import ServiceRemarks from "./components/ServiceRemarks";
import { includes } from "lodash";

import ApproveService from "./components/ApproveService";
import { message } from "antd";
import { IoLocationOutline } from "react-icons/io5";
import ServiceWipno from "./components/ServiceWipno";
import { useParams } from "react-router-dom";
import JoborderWrapper from "./components/JoborderWrapper";
import BackNav from "../../components/toolbar/BackNav";
import Active from "../../components/icons/Active";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  handleCompleteService,
  handleContinueService,
  handleFetchService,
  handlePauseService,
  handleStartService,
} from "../../api/services";
import Something from "../../components/shared/Something";
import { serviceStatus } from "../../utils/contants";
import { AiOutlineCheckSquare, AiOutlinePause } from "react-icons/ai";
import { BsCheck2, BsPlay, BsPrinter } from "react-icons/bs";
import { AuthContext } from "../../context/AuthContext";
import VehicleDocuments from "./components/VehicleDocuments";

export default function DetailedService() {
  const { auth } = useContext(AuthContext);
  const { id } = useParams();
  const [completeService, setCompleteService] = useState(false);
  const [approveService, setApproveService] = useState(false);
  const [editMode, setEditMode] = useState<boolean>(false);

  const serviceAccess = [3, 4, 9];

  const [pr, setPr] = useState<number>(0);
  const [co, setCo] = useState<number>(0);

  const jobRef = useRef(null);

  const queryClient = useQueryClient();

  const {
    data: service,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["services", id],
    queryFn: () => handleFetchService({ service_id: id }),
  });




  const { mutate: progress } = useMutation({
    mutationFn: pr === 1 ? handleStartService : handlePauseService,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["services", id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: complete } = useMutation({
    mutationFn: co === 1 ? handleCompleteService : handleContinueService,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["services", id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  if (id === undefined) {
    return <></>;
  }

  const handleCompletion = async () => {
    const data = { remarks: co === 1 ? "Completed" : "Started" };
    await complete({ service_id: id, data });
  };
  const handleProgress = async () => {
    await progress({ service_id: id });
  };

  return (
    <>
      <BackNav />
      {isError ? (
        <div className="bg-white m-2 border pb-16">
          <Something refetch={refetch} isFetching={isFetching} />
        </div>
      ) : (
        <div className="grid gap-x-0 grid-cols-12">
          <div className="col-span-12 sm:col-span-12 md:col-span-12 border m-2">
            <div className="flex justify-between items-center border-b bg-white py-2 text-xs">
              <div className="flex flex-row">
                <div className="flex items-center mx-4">
                  <img src={CarModel} className="w-16 h-16" alt="" />
                </div>
                <div className="flex flex-col justify-center">
                  <div className="text-lg font-bold text-gray-700">
                    {service?.service_description || "Service Description"}
                  </div>
                  <div className="text-md">
                    <span className="font-bold ">Customer : </span>
                    Automark Tanzania
                  </div>
                  <div className="flex text-gray-600">
                    <div className="">
                      <span className="font-bold">Technician : </span>
                      {service?.technician?.name || "-"}
                    </div>
                  </div>

                  <div className="my-1 justify-center">
                    {service?.approval?.user !== null ? (
                      <>
                        {service?.approval?.isApproved ? (
                          <span className="text-green-700 border p-1 bg-green-50">
                            Approved by {service?.approval?.user?.name || "-"}
                          </span>
                        ) : (
                          <span className="text-red-700 border p-1 bg-red-50">
                            Rejected by {service?.approval?.user?.name || "-"}
                          </span>
                        )}
                      </>
                    ) : (
                      <span className="text-gray-500 border p-1 bg-red-50">
                        Awaiting Manager approval
                      </span>
                    )}
                  </div>
                </div>
                <div className="ml-4">
                  <div className="text-gray-600 my-1">
                    {service?.vehicle?.model?.name} -{" "}
                    {service?.vehicle?.chassis}
                  </div>
                  <div className="text-gray-600 italic">
                    Source: {service?.vehicle?.source?.name || "-"}
                  </div>
                  <div className="flex items-center">
                    <span>Service Center : </span>
                    <IoLocationOutline />
                    <span className="ml-1">{service?.location?.name}</span>
                  </div>
                </div>
              </div>

              <div className="flex items-center px-5">
                {service?.status === "completed" ? (
                  <div className="flex flex-col">
                    <ReactToPrint
                      trigger={() => {
                        return (
                          <button
                            className={`flex items-center h-8 mb-1  border px-2 py-1 hover:bg-green-50 rounded-md mr-2 w-16
                                `}
                          >
                            <BsPrinter />
                            <span className="ml-1">Print</span>
                          </button>
                        );
                      }}
                      content={() => jobRef.current}
                      documentTitle={`JobOrder`}
                    />
                  </div>
                ) : (
                  <div className="">
                    {serviceAccess?.includes(auth?.accessLevel) && (
                      <div className="flex flex-col">
                        {editMode ? (
                          <div className="flex flex-col">
                            <button
                              className={`flex items-center cursor-not-allowed h-8 mb-1 bg-gray-100 border px-2 py-1 rounded-md mr-2 w-16`}
                            >
                              <BsPrinter />
                              <span className="ml-1">Print</span>
                            </button>

                            <div
                              onClick={() => setEditMode(false)}
                              className="bg-blue-200 h-8 flex items-center rounded-lg cursor-pointer w-16"
                            >
                              <div className="flex items-center px-1">
                                <div className="rounded-lg mx-1">Edit</div>
                                <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                                  <BsCheck2 />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : (
                          <div className="flex flex-col">
                            <ReactToPrint
                              trigger={() => {
                                return (
                                  <button
                                    className={`flex items-center h-8 mb-1  border px-2 py-1 hover:bg-green-50 rounded-md mr-2 w-16
                                `}
                                  >
                                    <BsPrinter />
                                    <span className="ml-1">Print</span>
                                  </button>
                                );
                              }}
                              content={() => jobRef.current}
                              documentTitle={`JobOrder`}
                            />
                            <div
                              onClick={() => setEditMode(true)}
                              className="bg-gray-200 h-8 flex items-center rounded-lg cursor-pointer w-16"
                            >
                              <div className="flex items-center px-1">
                                <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                                  <BsCheck2 />
                                </div>
                                <div className="rounded-lg mx-1">Edit</div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                )}

                <div className="flex flex-col justify-center items-center  border-l ml-2 pl-4 w-20">
                  <div className="">
                    <Active
                      height={36}
                      width={36}
                      color={serviceStatus[service?.status]}
                    />
                  </div>

                  <div className="my-2 justify-center capitalize">
                    <div
                      style={{
                        color: serviceStatus[service?.status],
                        fontSize: 14,
                      }}
                    >
                      {service?.status}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="grid gap-x-0 grid-cols-12 bg-white">
              <div className="col-span-12 sm:col-span-9 md:col-span-9 bg-white">
                <div className="flex flex-row bg-whitish  justify-start items-start overflow-auto">
                  <div className="bg-white flex flex-col justify-between items-center">
                    <div className="bg-grayish px-10">
                      <JoborderWrapper
                        jobRef={jobRef}
                        service={service}
                        editable={editMode}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-span-12  sm:col-span-3 md:col-span-3 bg-whitish overflow-auto border-l">
              <VehicleDocuments chassis={service?.vehicle?.chassis}/>

                {serviceAccess?.includes(auth?.accessLevel) && (
                  <div className="flex flex-col justify-between  bg-white p-2 border-b text-xs">
                    {service?.status !== "completed" ? (
                      <div className="flex justify-between">
                        <div className="w-1/2 mr-1">
                          {includes([1, 2, 4, 5], 1) ? (
                            <button
                              onClick={() => {
                                setApproveService(true);
                                setEditMode(false);
                              }}
                              className="flex justify-start border focus:outline-none hover:text-red-500 p-2 w-full"
                            >
                              Approve Jobcard
                            </button>
                          ) : null}
                        </div>
                        <div className="flex w-1/2">
                          <div className="w-1/2 mr-1">
                            {service?.status === "paused" ? (
                              <button
                                onClick={() => {
                                  setPr(1);
                                  handleProgress();
                                  setEditMode(false);
                                }}
                                className="flex items-center justify-start border focus:outline-none hover:text-red-500 p-2 w-full"
                              >
                                <BsPlay />
                                <span>Start</span>
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setPr(0);
                                  handleProgress();
                                  setEditMode(false);
                                }}
                                className="flex items-center justify-start border focus:outline-none hover:text-red-500 p-2 w-full"
                              >
                                <AiOutlinePause />
                                <span>Pause</span>
                              </button>
                            )}
                          </div>

                          <div className="w-1/2">
                            {service?.status === "completed" ? (
                              <button
                                onClick={() => {
                                  setCo(0);
                                  handleCompletion();
                                }}
                                className="flex items-center justify-start border focus:outline-none hover:text-red-500 p-2 w-full"
                              >
                                <BsPlay />
                                <span className="ml-1">Continue</span>
                              </button>
                            ) : (
                              <button
                                onClick={() => {
                                  setCo(1);
                                  handleCompletion();
                                  setEditMode(false);
                                }}
                                className="flex items-center justify-start border focus:outline-none hover:text-red-500 p-2 w-full"
                              >
                                <AiOutlineCheckSquare />
                                <span className="ml-1">Complete</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <button
                        onClick={() => {
                          setPr(1);
                          handleProgress();
                        }}
                        className="flex items-center justify-start border focus:outline-none hover:text-red-500 p-2 w-full"
                      >
                        <BsPlay />
                        <span className="ml-1">Re-open the Job Card</span>
                      </button>
                    )}

                    <div className="flex justify-between items-center">
       
                      <CompleteService
                        service_id={service?._id}
                        isVisible={completeService}
                        isLoading={false}
                        setVisible={setCompleteService}
                      />
                      <ApproveService
                        service_id={service?._id}
                        isVisible={approveService}
                        isLoading={false}
                        setVisible={setApproveService}
                      />
                    </div>

              
                  </div>
                )}

                <ServiceWipno
                  service_id={service?._id}
                  status={service?.status}
                  remark={service?.remark}
                  wip={service?.wip}
                  auth={auth}
                  serviceAccess={serviceAccess}
                />
                <ServiceRemarks
                  service_id={service?._id}
                  remark={service?.remark}
                  status={service?.status}
                  service={service}
                  history={service?.history || []}
                  auth={auth}
                  serviceAccess={serviceAccess}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
