import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleUpdateContact } from "../../../../api/contacts";
import { useEffect } from "react";

const { Option } = Select;

export default function UpdateParticulars({
  isVisible,
  setVisible,
  contact,
}: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const data = {
      particular: {
        ...values,
      },
    };
    await mutate({ data, _id: contact?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ nationalID: contact?.particular?.nationalID });
    form.setFieldsValue({ passport: contact?.particular?.passport });
    form.setFieldsValue({ company: contact?.particular?.company });
    form.setFieldsValue({ designation: contact?.particular?.designation });
    form.setFieldsValue({ gender: contact?.particular?.gender });
    // form.setFieldsValue({ birth: moment(contact?.particular?.birth) });
  }, [contact]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">UPDATE : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Particulars</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateContactParticulars"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="nationalID"
                label="National ID"
                initialValue={contact?.particular?.nationalID}
                rules={[
                  {
                    required: true,
                    message: "Please enter national ID",
                  },
                ]}
              >
                <Input type="" placeholder="National ID" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="passport"
                label="Passport No"
                initialValue={contact?.particular?.passport}
                rules={[
                  {
                    required: true,
                    message: "Please enter postal",
                  },
                ]}
              >
                <Input type="" placeholder="Postal" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="company"
                label="Company ( Optional )"
                initialValue={contact?.particular?.company}
                rules={[
                  {
                    required: false,
                    message: "Please enter company name",
                  },
                ]}
              >
                <Input type="" placeholder="Company ( Optional )" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="designation"
                label="Designation ( Optional )"
                initialValue={contact?.particular?.designation}
                rules={[
                  {
                    required: false,
                    message: "Please enter designation",
                  },
                ]}
              >
                <Input type="" placeholder="Designation ( Optional )" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="gender"
                label="Gender"
                initialValue={contact?.particular?.gender}
                rules={[
                  {
                    required: true,
                    message: "Please select gender",
                  },
                ]}
              >
                <Select placeholder="Gender">
                  <Option value="Male">Male</Option>
                  <Option value="Female">Female</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="birth"
                label="Date of Birth"
                rules={[
                  {
                    required: false,
                    message: "Please enter D.O.B",
                  },
                ]}
              >
                <DatePicker
                  placeholder="Date of Birth"
                  format={"DD/MM/YYYY"}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
