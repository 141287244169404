import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import { useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import { FiEdit } from "react-icons/fi";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";

import Manage from "..";
import { postDataMail } from "../../../services/mail";
import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { AiOutlineDelete } from "react-icons/ai";
import CreateCheckpoint from "./components/CreateCheckpoint";
import { handleFetchCheckpoints } from "../../../api/checkpoints";
import CreateQuestion from "./components/CreateQuestion";
import DeleteQuestion from "./components/DeleteQuestion";

export default function AllQuestions() {
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);

  const {
    data: checkpoints,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["checkpoints"],
    queryFn: handleFetchCheckpoints,
  });

  const [emailForm] = Form.useForm();

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const mapped = checkpoints?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Mobile: item?.mobile === null ? "-" : item?.mobile,
        Email: item?.email === null ? "-" : item?.email,
        Address: item?.address === null ? "-" : item?.address,
        TIN: item?.tin === null ? "-" : item?.tin,
        Category: item?.isCompany ? "Organization" : "Individual",
      };
    });

    const raw = getExportData(mapped);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };


  return (
    <Manage
      title={"160 Questions"}
      subtitle={"All"}
      description={"List of all questions"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  checkpoints?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      "Group Name": item?.title === null ? "-" : item?.title,
                      Rank: item?.position === null ? "-" : item?.position,
                    };
                  }),
                  "Questions.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
        </div>
      }
    >
      <>
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12 p-4">
                {checkpoints?.map((item: any, index: number) => (
                  <div className="mb-4" key={index}>
                    <div className="flex justify-between pb-2">
                      <div className="">
                        {index + 1}. {item?.title}
                      </div>
                      <div className="flex items-center">
                        <div className="mr-2">
                          <FiEdit size={17} />
                        </div>
                        <div className="">
                          <AiOutlineDelete size={19} />
                        </div>
                      </div>
                    </div>

                    {item?.questions?.length === 0 ? (
                      <div className="text-gray-500 text-sm ml-4">
                        No Questions
                      </div>
                    ) : (
                      <div className="">
                        {item?.questions?.map(
                          (question: any, index: number) => (
                            <div className="flex justify-between ml-4">
                              <div className="">
                                {index + 1}. {question?.question}
                              </div>
                              <div className="">
                                <DeleteQuestion
                                  checkpoint_id={item?._id}
                                  question_id={question?._id}
                                />
                              </div>
                            </div>
                          )
                        )}
                      </div>
                    )}

                    <CreateQuestion checkpoint_id={item?._id} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
        <CreateCheckpoint isVisible={createModal} setVisible={setCreateModal} />
      </>
    </Manage>
  );
}
