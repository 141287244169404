import { AiOutlineUnorderedList } from "react-icons/ai";
import Moment from "react-moment";
import { Link } from "react-router-dom";

export default function Wrap({ children, active, dates }: any) {
  const menus = [
    { id: 1, name: "Deals Reports", link: "/reports/deals" },
    { id: 2, name: "Leads Reports", link: "/reports/leads" },
    { id: 3, name: "Stock Reports", link: "/reports/stock" },
    { id: 4, name: "Sales Reports", link: "/reports/sales" },
    { id: 5, name: "Aftersales Reports", link: "/reports/aftersales" },
  ];
  return (
    <div className="">
      <div className="border-b  bg-white flex justify-between px-2">
        <div className="flex items-center">
          {menus.map((menu: any, index: number) => (
            <Link
              to={menu?.link}
              key={index}
              className={`flex items-center mr-6 text-sm py-3 ${
                active === menu?.name
                  ? "border-b border-automark-dark text-black"
                  : "border-b border-white text-gray-600"
              }`}
            >
              <AiOutlineUnorderedList />
              <span className="ml-1">{menu?.name}</span>
            </Link>
          ))}
        </div>
        <div className="flex items-center text-green-600">
          {dates?.isRange ? (
            <div className="font-bold">
              <Moment format={"DD/MM/YYYY"}>{dates?.startDate}</Moment> -{" "}
              <Moment format={"DD/MM/YYYY"}>{dates?.endDate}</Moment>
            </div>
          ) : (
            <div className="font-bold">
              <Moment format={"DD/MM/YYYY"}>{dates?.startDate}</Moment>
            </div>
          )}
        </div>
      </div>
      <div className="">{children}</div>
    </div>
  );
}
