import { Link } from "react-router-dom";
import { RiLoginBoxLine } from "react-icons/ri";
import logo from "../../assets/images/logo_white.png";

import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";
import Profile from "./Profile";

export default function HomeHeader() {
  const { token } = useContext(AuthContext);


  return (
    <div className="h-16 bg-automark-dark flex flex-row justify-between items-center">
      <div className="flex flex-row items-center mx-4 text-white text-2xl font-bold">
        <div className="ml-4 text-sm hidden sm:block md:text-2xl">
          <img src={logo} width={190} alt="" />
        </div>
        <span className="ml-4 block sm:hidden text-sm font-normal">
          AUTOMARK
        </span>
      </div>
      <div className="flex flex-row items-center">
        <div className="text-white font-bold bg-green-100"></div>
        {token ? (
          <div className="ml-2 mr-4 z-50">
            <Profile />
          </div>
        ) : (
          <Link to={"/"} className="ml-2 mr-4">
            <RiLoginBoxLine color="#FFFFFF" size={22} />
          </Link>
        )}
      </div>
    </div>
  );
}
