import {
  Button,
  Modal,
  Form,
  DatePicker,
  Row,
  Col,
  Select,
  Input,
  message,
} from "antd";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleConvertLead } from "../../../../api/leads";
import { handleFetchDealStages } from "../../../../api/dealStages";
import { AuthContext } from "../../../../context/AuthContext";
const { Option } = Select;

export default function ConvertDeal({
  deal,
  deal_id,
  isVisible,
  setVisible,
}: any) {
  const { auth } = useContext(AuthContext);
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleConvertLead,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["deals", deal_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: stages } = useQuery({
    queryKey: ["dealStages"],
    queryFn: handleFetchDealStages,
  });

  const [categories] = useState<any>([]);

  const [form] = Form.useForm();

  const navigate = useNavigate();

  const onSubmit = async () => {
    const values = await form.validateFields();
    const data = {
      user: auth?._id,
      ...values,
    };
    await mutate({ deal_id, data });
    setVisible(false);
    form.resetFields();
    navigate("/crm/deals/new");
  };

  const stageOptions = stages?.map((stage: any, index: number) => {
    return (
      <Option value={stage._id} key={index}>
        {stage.name}
      </Option>
    );
  });

  const categoryOptions = categories?.map((item: any, index: number) => {
    return (
      <Option value={item?.cat_name} key={index}>
        {item?.cat_name}
      </Option>
    );
  });

  return (
    <Modal
      open={isVisible}
      title="Convert to Deal"
      onCancel={() => setVisible(false)}
      footer={[
        <Button key="back" danger onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="AddForm"
          danger
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Convert
        </Button>,
      ]}
    >
      <Form
        id="AddForm"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="title"
              label="Title"
              initialValue={deal?.title}
              rules={[
                {
                  required: true,
                  message: "Please enter title",
                },
              ]}
            >
              <Input placeholder="Title" style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="value"
              label="Value ( KES )"
              initialValue={deal?.value}
              rules={[
                {
                  required: true,
                  message: "Please enter deal value",
                },
              ]}
            >
              <Input placeholder="Value" style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="until"
              label="Expected close date"
              initialValue={deal?.dates?.expected_date}
              rules={[
                {
                  required: true,
                  message: "Please enter expected close date",
                },
              ]}
            >
              <DatePicker
                placeholder="Expected close date"
                format={"DD/MM/YYYY"}
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="stage"
              rules={[
                {
                  required: true,
                  message: "Please select stage",
                },
              ]}
            >
              <Select placeholder="Stage">{stageOptions}</Select>
            </Form.Item>
          </Col>
        </Row>
        {categories?.length === 0 ? null : (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="source_cat"
                label="Lead Source Category"
                rules={[
                  {
                    required: false,
                    message: "Please select source category",
                  },
                ]}
              >
                <Select placeholder="Source Category">{categoryOptions}</Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="source_subcat"
                label="Lead Source Details"
                rules={[
                  {
                    required: false,
                    message: "Please enter source detail",
                  },
                ]}
              >
                <Input placeholder="Source details" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>
        )}
      </Form>
    </Modal>
  );
}
