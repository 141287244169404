// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ant-spin-dot-item {
    background-color: red;
}


body {
    margin: 0;
    padding: 0;
    background-color: #FFFFFF;
    font: 12pt "Tahoma";
}

* {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
}

.page {
    width: 21cm;
    min-height: 29.7cm;
    padding: 2cm;
    margin: 1cm auto;
    background: #ffffff;
    box-shadow: inset 0 0 5px #CCC;
}

.subpage {
    position: relative;
        border: 2px black solid;
    height: 256mm;
}

@page {
    size: A4;
    margin: 0;
}

@media print {
    .page {
        margin: 0;
        border: initial;
        border-radius: initial;
        width: initial;
        min-height: initial;
        box-shadow: initial;
        background: initial;
        page-break-after: always;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/services/components/custom.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;;AAGA;IACI,SAAS;IACT,UAAU;IACV,yBAAyB;IACzB,mBAAmB;AACvB;;AAEA;IACI,sBAAsB;IACtB,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;IAChB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;QACd,uBAAuB;IAC3B,aAAa;AACjB;;AAEA;IACI,QAAQ;IACR,SAAS;AACb;;AAEA;IACI;QACI,SAAS;QACT,eAAe;QACf,sBAAsB;QACtB,cAAc;QACd,mBAAmB;QACnB,mBAAmB;QACnB,mBAAmB;QACnB,wBAAwB;IAC5B;AACJ","sourcesContent":[".ant-spin-dot-item {\n    background-color: red;\n}\n\n\nbody {\n    margin: 0;\n    padding: 0;\n    background-color: #FFFFFF;\n    font: 12pt \"Tahoma\";\n}\n\n* {\n    box-sizing: border-box;\n    -moz-box-sizing: border-box;\n}\n\n.page {\n    width: 21cm;\n    min-height: 29.7cm;\n    padding: 2cm;\n    margin: 1cm auto;\n    background: #ffffff;\n    box-shadow: inset 0 0 5px #CCC;\n}\n\n.subpage {\n    position: relative;\n        border: 2px black solid;\n    height: 256mm;\n}\n\n@page {\n    size: A4;\n    margin: 0;\n}\n\n@media print {\n    .page {\n        margin: 0;\n        border: initial;\n        border-radius: initial;\n        width: initial;\n        min-height: initial;\n        box-shadow: initial;\n        background: initial;\n        page-break-after: always;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
