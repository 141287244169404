import { useQuery } from "@tanstack/react-query";
import SourceWidget from "./SourceWidget";
import { handleFetchStockSourceReport } from "../../../api/reports";
import StockPanel from "./StockPanel";
import {  useState } from "react";

export default function SourceWrapper({ dates }: any) {
 
  const [selected, setSelected] = useState<any>({});
  const [isVisible, setVisible] = useState<boolean>(false);
  const { data: stock } = useQuery({
    queryKey: ["stockSourceReport"],
    queryFn: handleFetchStockSourceReport,
  });



  const handleSelect = ({ item }: any) => {
    setSelected(item);
    setVisible(true);
  };

  return (
    <div className="grid grid-cols-12 gap-4">
      <div className="col-span-12">
        <div className="">
          <div className="grid grid-cols-12 gap-2">
            {stock?.map((item: any, index: number) => (
              <SourceWidget
                item={item}
                key={index}
                handleSelect={handleSelect}
              />
            ))}
          </div>
        </div>
      </div>
      <StockPanel
        title="STOCK BY SOURCE"
        subtitle={selected?._id}
        vehicles={selected?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
