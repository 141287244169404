import {
  Button,
  Col,
  DatePicker,
  Drawer,
  Form,
  Input,
  Row,
  Space,
  message,
} from "antd";
import { useContext, useEffect, useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { AuthContext } from "../../../../context/AuthContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleRespondInquiry } from "../../../../api/inquiries";

const { TextArea } = Input;

export default function InquiryResponse({
  isVisible,
  setVisible,
  inquiry,
}: any) {
  const { auth } = useContext(AuthContext);
  const [isContacted, setContacted] = useState<number>(
    inquiry?.engagement?.isContacted ? 1 : 0
  );
  const [requireFollowUp, setFollowUp] = useState<number>(
    inquiry?.engagement?.requireFollowUp ? 1 : 0
  );

    const [isInterested, setInterested] = useState<number>(
      inquiry?.engagement?.isInterested ? 1 : 0
    );
    const [form] = Form.useForm();
    const queryClient = useQueryClient();

    const { mutate } = useMutation({
      mutationFn: handleRespondInquiry,
      onSuccess: () => {
        message.success("Updated Successfully");
        form.resetFields();
        clearForm();
        queryClient.invalidateQueries({ queryKey: ["newInquiries"] });
        queryClient.invalidateQueries({ queryKey: ["conInquiries"] });
        setVisible(false);
      },
      onError: () => {
        message.error("Something went wrong");
      },
    });

    const onSubmit = async () => {
      const { feedback, date } = await form.getFieldsValue();

      const data = {
        user: auth?._id,
        isContacted: isContacted === 1 ? true : false,
        requireFollowUp: requireFollowUp === 1 ? true : false,
        isInterested: isInterested === 1 ? true : false,
        feedback,
        date,
      };

      await mutate({ data, _id: inquiry?._id });
    };

    const options = [
      { id: 1, name: "YES" },
      { id: 0, name: "NO" },
    ];

    const clearForm = async () => {
      form.resetFields();
      setContacted(inquiry?.engagement?.isContacted ? 1 : 0);
      setFollowUp(inquiry?.engagement?.requireFollowUp ? 1 : 0);
      setInterested(inquiry?.engagement?.isInterested ? 1 : 0);
    };

    useEffect(() => {
      setContacted(inquiry?.engagement?.isContacted ? 1 : 0);
      setFollowUp(inquiry?.engagement?.requireFollowUp ? 1 : 0);
      setInterested(inquiry?.engagement?.isInterested ? 1 : 0);
    }, [inquiry]);

    if (!inquiry) {
      return <></>;
    }

    return (
      <Drawer
        title={
          <div className="flex items-center">
            <span className="mr-2 text-lg">
              <span className="font-bold">INQUIRY : </span>
            </span>
            <div className="flex text-gray-500 px-2 uppercase">Response</div>
          </div>
        }
        placement={"right"}
        width={800}
        onClose={() => setVisible(!isVisible)}
        open={isVisible}
        closable={false}
        extra={
          <Space>
            <Button
              type="default"
              color="#E50000"
              onClick={() => setVisible(!isVisible)}
              danger
            >
              <AiOutlineClose />
            </Button>
          </Space>
        }
      >
        <div className="">
          <div className="flex items-center justify-between mb-1 border-b pb-2">
            <div className="w-48">Intereted ?</div>
            <div className="flex ml-4">
              {options?.map((item: any, index: number) => (
                <button
                  onClick={() => setInterested(item?.id)}
                  key={index}
                  className={`${
                    isInterested === item?.id ? "bg-green-400" : ""
                  } w-32 text-center border border-gray-300 py-1`}
                >
                  {item?.name}
                </button>
              ))}
            </div>
          </div>
          <div className="flex items-center justify-between mb-1 border-b pb-2">
            <div className="w-48">Contacted ?</div>
            <div className="flex ml-4">
              {options?.map((item: any, index: number) => (
                <button
                  onClick={() => setContacted(item?.id)}
                  key={index}
                  className={`${
                    isContacted === item?.id ? "bg-green-400" : ""
                  } w-32 text-center border border-gray-300 py-1`}
                >
                  {item?.name}
                </button>
              ))}
            </div>
          </div>

          <div className="flex items-center justify-between mb-4 border-b pb-2">
            <div className="w-48">Require Follow Up ?</div>
            <div className="flex ml-4">
              {options?.map((item: any, index: number) => (
                <button
                  onClick={() => setFollowUp(item?.id)}
                  key={index}
                  className={`${
                    requireFollowUp === item?.id ? "bg-green-400" : ""
                  } w-32 text-center border border-gray-300 py-1`}
                >
                  {item?.name}
                </button>
              ))}
            </div>
          </div>

          <Form
            id="inquiryResponse"
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onSubmit}
            layout="vertical"
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                <Form.Item
                  name="feedback"
                  label="Feedback"
                  rules={[
                    {
                      required: true,
                      message: "Please enter feedback",
                    },
                  ]}
                >
                  <TextArea placeholder="Feedback"></TextArea>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="date"
                  label="Date"
                  rules={[
                    {
                      required: true,
                      message: "Please select date",
                    },
                  ]}
                >
                  <DatePicker placeholder="Date"  format={"DD/MM/YYYY"}/>
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button danger type="primary" htmlType="submit">
                Submit
              </Button>
              <Button
                danger
                htmlType="button"
                className="mx-2"
                onClick={clearForm}
              >
                Clear
              </Button>
            </Form.Item>
          </Form>
        </div>
      </Drawer>
    );
}
