import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  message,
  Select,
  DatePicker,
} from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleCreateContact } from "../../../../api/contacts";
import { useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { handleFetchIndustries } from "../../../../api/industries";

const { Option } = Select;

export default function CreateContact({
  isVisible,
  setVisible,
}: any) {
  const [selected, setSelected] = useState(1);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateContact,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: industries } = useQuery({
    queryKey: ["industries"],
    queryFn: handleFetchIndustries,
  });

  const types = [
    { id: 1, name: "Organization" },
    { id: 2, name: "Individual" },
  ];

  const industryOptions = industries?.map((industry: any, index: number) => (
    <Option value={industry?._id} key={index}>
      {industry?.name}
    </Option>
  ));

  const onSubmit = async () => {
    let business: any = null;
    let particular: any = null;
    let industry: any = null;
    let contact: any = null;
    const values = await form.getFieldsValue();

    if (selected === 1) {
      if (values.vrn) {
        business = {
          vrn: values.vrn,
        };
      }
      if (values.tin) {
        business = {
          ...business,
          tin: values.tin,
        };
      }

      contact = {
        name: values?.cName,
        mobile: values?.cMobile,
      };

      if (values.industryId) {
        const filters = industries.filter(
          (item: any) => item?._id === values.industryId
        );

        industry = {
          entity: filters[0]?._id,
          name: filters[0]?.name,
        };
        business = {
          ...business,
          industry,
        };
      }
    } else {
      particular = {
        gender: values.gender,
        birth: values.birth,
      };
    }

    const data = {
      isCompany: selected === 1 ? true : false,
      business,
      particular,
      contact,
      ...values,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Contact"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createContact"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createContact"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          {types?.map((type: any, index: number) => (
            <Col xs={{ span: 24 }} lg={{ span: 12 }} key={index}>
              <button
                type="button"
                className="flex flex-row  items-center mb-4 border w-full"
                onClick={() => setSelected(type.id)}
              >
                {selected === type?.id ? (
                  <div className="h-8 w-8 border-r flex justify-center items-center bg-green-100">
                    <AiOutlineCheck />
                  </div>
                ) : (
                  <div className="h-8 w-8 border-r flex justify-center items-center">
                    <AiOutlineCheck color="#FFFFFF" />
                  </div>
                )}
                <div className="ml-4"></div>
                {type?.name}
              </button>
            </Col>
          ))}
        </Row>

        {selected === 1 ? (
          <div className="">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="name"
                  label="Company Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input type="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="industryId"
                  label="Industry"
                  rules={[
                    {
                      required: false,
                      message: "Please enter industry",
                    },
                  ]}
                >
                  <Select placeholder="Industry">{industryOptions}</Select>
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input type="" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="mobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input type="" placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="tin"
                  label="TIN"
                  rules={[
                    {
                      required: false,
                      message: "Please enter tin",
                    },
                  ]}
                >
                  <Input type="" placeholder="TIN" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="vrn"
                  label="VRN"
                  rules={[
                    {
                      required: false,
                      message: "Please enter vrn",
                    },
                  ]}
                >
                  <Input type="" placeholder="VRN" />
                </Form.Item>
              </Col>
            </Row>

            <div className="bg-gray-100 py-2 font-bold">Contact Person</div>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cName"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input type="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="cMobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input type="" placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <div className="">
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="name"
                  label={selected === 1 ? "Company Name" : "Full Name"}
                  rules={[
                    {
                      required: true,
                      message: "Please enter name",
                    },
                  ]}
                >
                  <Input type="" placeholder="Name" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="tin"
                  label="TIN"
                  rules={[
                    {
                      required: false,
                      message: "Please enter tin",
                    },
                  ]}
                >
                  <Input type="" placeholder="TIN" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="email"
                  label="Email"
                  rules={[
                    {
                      required: true,
                      message: "Please enter email",
                    },
                  ]}
                >
                  <Input type="" placeholder="Email" />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="mobile"
                  label="Mobile"
                  rules={[
                    {
                      required: true,
                      message: "Please enter mobile",
                    },
                  ]}
                >
                  <Input type="" placeholder="Mobile" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="gender"
                  label="Gender"
                  rules={[
                    {
                      required: false,
                      message: "Please select gender",
                    },
                  ]}
                >
                  <Select placeholder="Gender">
                    <Option value="Male">Male</Option>
                    <Option value="Female">Female</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="birth"
                  label="Date of Birth"
                  rules={[
                    {
                      required: false,
                      message: "Please enter D.O.B",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Date of Birth"
                    format={"DD/MM/YYYY"}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>
            </Row>
          </div>
        )}
      </Form>
    </Modal>
  );
}
