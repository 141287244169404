import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  Row,
  Select,
  Space,
  message,
} from "antd";
import { AiOutlineClose } from "react-icons/ai";
import { handleUpdateContact } from "../../../../api/contacts";
import { useEffect } from "react";
import { handleFetchIndustries } from "../../../../api/industries";

const { Option } = Select;

export default function UpdateBusiness({
  isVisible,
  setVisible,
  contact,
}: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data: industries } = useQuery({
    queryKey: ["industries"],
    queryFn: handleFetchIndustries,
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateContact,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["contacts"] });
      setVisible(false);
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const industryOptions = industries?.map((industry: any, index: number) => (
    <Option value={industry?.name} key={index}>
      {industry?.name}
    </Option>
  ));

  const onSubmit = async () => {
    const {
      industry,
      fax,
      website,
      oName,
      oMobile,
      oEmail,
      oAddress,
      cName,
      cMobile,
    } = await form.getFieldsValue();
    const data = {
      business: {
        industry: {
          name: industry,
        },
        contact: {
          name: cName,
          mobile: cMobile,
        },
        owner: {
          name: oName,
          mobile: oMobile,
          email: oEmail,
          address: oAddress,
        },
        fax,
        website,
      },
    };
    await mutate({ data, _id: contact?._id });
  };

  const clearForm = () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ industry: contact?.business?.industry?.name });
    form.setFieldsValue({ cName: contact?.business?.contact?.name });
    form.setFieldsValue({ cMobile: contact?.business?.contact?.mobile });
    form.setFieldsValue({ oName: contact?.business?.owner?.name });
    form.setFieldsValue({ oMobile: contact?.business?.owner?.mobile });
    form.setFieldsValue({ oEmail: contact?.business?.owner?.email });
    form.setFieldsValue({ oAddress: contact?.business?.owner?.address });
    form.setFieldsValue({ fax: contact?.business?.fax });
    form.setFieldsValue({ website: contact?.business?.website });
  }, [contact, form]);

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">UPDATE : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Business</div>
        </div>
      }
      placement={"right"}
      width={900}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateContactBusiness"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="industry"
                label="Industry"
                initialValue={contact?.business?.industry?.name}
                rules={[
                  {
                    required: false,
                    message: "Please select industry",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Select industry"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {industryOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="fax"
                label="Fax"
                initialValue={contact?.business?.fax}
                rules={[
                  {
                    required: false,
                    message: "Please enter postal",
                  },
                ]}
              >
                <Input type="" placeholder="Postal" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="website"
                label="Website"
                initialValue={contact?.business?.website}
                rules={[
                  {
                    required: false,
                    message: "Please enter postal",
                  },
                ]}
              >
                <Input type="" placeholder="Postal" />
              </Form.Item>
            </Col>
          </Row>
          <div className="bg-gray-100 py-2 font-bold">Business Owner</div>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="oName"
                label="Name"
                rules={[
                  {
                    required: false,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input type="" placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="oMobile"
                label="Mobile"
                rules={[
                  {
                    required: false,
                    message: "Please enter mobile",
                  },
                ]}
              >
                <Input type="" placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="oEmail"
                label="Email"
                rules={[
                  {
                    required: false,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input type="" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="oAddress"
                label="Address"
                rules={[
                  {
                    required: false,
                    message: "Please enter address",
                  },
                ]}
              >
                <Input type="" placeholder="Address" />
              </Form.Item>
            </Col>
          </Row>
          <div className="bg-gray-100 py-2 font-bold">Contact Person</div>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="cName"
                label="Name"
                rules={[
                  {
                    required: false,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input type="" placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="cMobile"
                label="Mobile"
                rules={[
                  {
                    required: false,
                    message: "Please enter mobile",
                  },
                ]}
              >
                <Input type="" placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit" loading={isLoading}>
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
