import { useQuery } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import { useEffect, useState } from "react";
import AdvancedSearch from "./components/AdvancedSearch";
import { handleFetchInquiries } from "../../../api/inquiries";
import IMain from "./IMain";
import IHeader from "./components/IHeader";
import moment from "moment";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { BsCheck2Square } from "react-icons/bs";
import Moment from "react-moment";
import InquiryDetails from "./components/InquiryDetails";
import AssignModal from "./components/AssignModal";
import { Table } from "antd";

export default function INewInquiries({ inCRM }: any) {
  const [advSearch, setAdvSearch] = useState<boolean>(false);
  const [detailModal, setDetailModal] = useState<boolean>(false);
  const [assigned, setAssigned] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>(null);

  const initials = {
    name: "",
    consultant: "",
    source: "",
    status: "",
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
  };
  const [filters, setFilters] = useState<any>({ ...initials });

  const [pagination, setPagination] = useState<any>({
    page: 1,
    per_page: 10,
    total_pages: 1,
    total_count: 0,
  });

  const clearAllFilters = () => {
    setFilters({ ...initials });
  };

  const params = `&name=${filters?.name}&consultant=${
    filters?.consultant
  }&source=${filters?.source}&status=${filters?.status}&startDate=${moment(
    filters?.startDate
  ).format("YYYYMMDD")}&endDate=${moment(filters?.endDate).format("YYYYMMDD")}`;
  const {
    data: inquiries,
    refetch,
    isLoading,
    isFetched,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["newInquiries", { pagination, params }],
    queryFn: handleFetchInquiries,
  });

  const handleAdvancedSearch = (more: any) => {
    setFilters({ ...filters, ...more });
  };

  const onChange = (params: any) => {
    setPagination({
      ...pagination,
      page: params?.current,
      per_page: params?.pageSize,
      total_count: inquiries?.total_count,
    });
  };

  const InquiryColumns = [
    {
      title: "S/N",
      width: 40,
      render: (record: any, text: any, index: any) => (
        <span className="text-gray-700 ml-2">
          {pagination?.page * pagination?.per_page -
            pagination?.per_page +
            index +
            1}
        </span>
      ),
    },
    {
      title: "Name",
      width: 180,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.name || "-"}</span>;
      },
    },

    {
      title: "Mobile",
      width: 120,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.mobile || "-"}</span>;
      },
    },
    {
      title: "Email",
      width: 120,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.email || "-"}</span>;
      },
    },


    {
      title: "Created",
      width: 100,
      render: (record: any) => {
        return <Moment format={"Do MMM,YYYY"}>{record?.createdAt}</Moment>;
      },
    },
    {
      title: "Created By",
      width: 100,
      render: (record: any) => {
        return <span className="text-gray-700">{record?.registered?.name || "-"}</span>;
      },
    },
    {
      title: "Consultant",
      width: 120,
      render: (record: any) => {
        return (
          <span className="text-gray-700 capitalize">
            {record?.assigned?.to ? (
              record?.assigned?.to
            ) : (
              <div className="w-24  text-red-400">
                <span className="">*Not Assigned</span>
              </div>
            )}
          </span>
        );
      },
    },
    {
      title: "Contacted",
      width: 80,
      render: (record: any) => {
        return (
          <div className="">
            {record?.engagement?.isContacted ? (
              <div className="flex items-center">
                <BsCheck2Square color="#16a34a" />
                <span className="ml-1 text-green-600">YES</span>
              </div>
            ) : (
              <div className="flex items-center">
                <AiOutlineCloseSquare color="#dc2626" />
                <span className="ml-1 text-red-600">NO</span>
              </div>
            )}
          </div>
        );
      },
    },

    {
      title: "Details",
      width: 80,
      render: (record: any) => {
        return (
          <button
            onClick={() => {
              setSelected(record);
              setDetailModal(true);
            }}
            className="bg-green-50  w-24 rounded-none border border-green-600"
          >
            Details
          </button>
        );
      },
    },
  ];

  useEffect(() => {
    if (inquiries?.total_count) {
      setPagination({
        ...pagination,
        total_count: inquiries?.total_count,
      });
    }
  }, [isFetched]);

  return (
    <div className="">
      <IHeader
        filters={filters}
        setFilters={setFilters}
        setAdvSearch={setAdvSearch}
        advSearch={advSearch}
        inquiries={inquiries}
        handleAdvancedSearch={handleAdvancedSearch}
        clearAllFilters={clearAllFilters}
      />
      <IMain active="New Inquiries" actions={<></>} inCRM={inCRM}>
        {advSearch && (
          <AdvancedSearch
            setVisible={setAdvSearch}
            clearAllFilters={clearAllFilters}
            mainFilters={filters}
            handleAdvancedSearch={handleAdvancedSearch}
          />
        )}
        {isError ? (
          <Something refetch={refetch} isFetching={isFetching} />
        ) : (
          <div className="">
            <div className="grid gap-x-0 grid-cols-12 border bg-white">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                <Table
                  size="small"
                  dataSource={inquiries === undefined ? [] : inquiries?.data || []}
                  columns={InquiryColumns}
                  scroll={{ y: 860, x: 0 }}
                  rowKey="id"
                  onRow={(record) => {
                    return {
                      onClick: () => {
                        setSelected(record);
                        setDetailModal(true);
                      },
                    };
                  }}
                  pagination={{
                    current: pagination?.page,
                    pageSize: pagination?.per_page,
                    total: pagination?.total_count,
                  }}
                  onChange={onChange}
                  loading={isLoading}
                  rowClassName={"hover:cursor-pointer hover:bg-red-50"}
                />
              </div>
            </div>
            <InquiryDetails
              isVisible={detailModal}
              setVisible={setDetailModal}
              selected={selected || {}}
            />
            <AssignModal
              isVisible={assigned}
              setVisible={setAssigned}
              inquiry={selected || {}}
            />
          </div>
        )}
      </IMain>
    </div>
  );
}
