import { Button, Drawer, Form, Space, Input } from "antd";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { RiFileExcel2Line, RiFilterOffLine } from "react-icons/ri";
import Moment from "react-moment";
import formatMoney from "../../../../utils/formatMoney";
import { exportAllLeadsData } from "../../../../utils/leadDataExport";
import { upperCase } from "lodash";
const { Search } = Input;
export default function LeadPanel({
  setVisible,
  isVisible,
  title,
  subtitle,
  leads,
}: any) {
  const [search, setSearch] = useState("");

  const [form] = Form.useForm();

  const handleClearSearch = () => {
    setSearch("");
    form.resetFields();
  };

  const getTotal = () => {
    let total = 0;
    leads
      ?.filter((lead: any) => lead?.contact?.name?.includes(search))
      ?.forEach((item: any) => {
        total = total + Number(item?.value);
      });
    return total;
  };

  return (
    <Drawer
      title={`${title} : ${upperCase(subtitle)}`}
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="font-toyota">
        <div className="flex justify-between items-center border-b border-gray-300 mb-2 pb-2 -mt-4">
          <div className="flex">
            <Form layout="inline" form={form}>
              <Form.Item name="registration">
                <Search
                  placeholder="Search by customer"
                  allowClear
                  onChange={(val: any) => {
                    setSearch(val.target.value);
                  }}
                  onSearch={(value: any) => {
                    setSearch(value);
                  }}
                />
              </Form.Item>

              <Button className="" onClick={() => handleClearSearch()}>
                <RiFilterOffLine color="#6b7280" />
              </Button>
            </Form>
          </div>
          <div className="flex ">
            <button
              color="#E50000"
              className="flex justify-center items-center border p-1 hover:bg-green-100 focus:outline-none"
              onClick={() => exportAllLeadsData({ data: leads, search })}
            >
              <RiFileExcel2Line size={20} />
              <span className="ml-2">Export To Excel</span>
            </button>
          </div>
        </div>
        <div className="flex py-1 border-b border-gray-300">
          <div className="mr-2 w-10 text-center font-bold">S/N.</div>
          <div className="flex justify-between items-center flex-1">
            <div className="flex items-center">
              <div className="w-52 truncate font-bold">Deal Title</div>
              <div className="w-36 mr-2 truncate text-left font-bold">
                Customer
              </div>
              <div className="w-36 truncate font-bold">Deal Created</div>
              <div className="w-24 truncate font-bold">Status</div>
            </div>
            <div className="font-bold">
              <div className="mr-1 text-left flex-1">Amount (KES)</div>
            </div>
          </div>
        </div>
        {leads
          ?.filter((lead: any) => lead?.contact?.name?.includes(search))
          ?.map((item: any, index: number) => (
            <div className="flex py-1 border-b border-gray-300" key={index}>
              <div className="mr-2 w-10 text-center">{index + 1}.</div>
              <div className="flex justify-between items-center flex-1">
                <div className="flex items-center">
                  <div className="w-52">
                    {item?.title === null ? "-" : item?.title}
                  </div>
                  <div className="w-36 mr-2 truncate">
                    {item?.contact ? item?.contact?.name : "-"}
                  </div>
                  <div className="w-36 truncate flex">
                    <Moment format="Do MMM, YYYY">
                      {item?.dates?.created}
                    </Moment>
                  </div>
                  <div className="w-24 truncate flex capitalize text-center">
                    {item?.status?.state}
                  </div>
                </div>
                <div className="mr-1 text-right flex-1">
                  {formatMoney(item?.value)}
                </div>
              </div>
            </div>
          ))}
        <div className="flex justify-between items-start border-t-2 border-b-2 py-2">
          <div className="ml-2">Total</div>
          <div className="font-bold">{formatMoney(getTotal())}</div>
        </div>
      </div>
    </Drawer>
  );
}
