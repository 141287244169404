import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect } from "react";
import { handleUpdateAllowance } from "../../../../api/allowances";

export default function UpdateAllowance({
  isVisible,
  setVisible,
  selected,
}: any) {
  const { _id, location, distance, driver, fuel } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateAllowance,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["allowances"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { fuel_rate, fuel_amount, ...rest } = await form.getFieldsValue();
    const data = {
      fuel: {
        rate: fuel_rate,
        amount: fuel_amount,
        price: fuel_rate * fuel_amount,
      },
      ...rest,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Allowance"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateAllowance"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateAllowance"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="location"
              label="Location"
              initialValue={location}
              rules={[
                {
                  required: true,
                  message: "Please enter location",
                },
              ]}
            >
              <Input type="" placeholder="Location" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="distance"
              label="Distance  ( KM )"
              initialValue={distance}
              rules={[
                {
                  required: true,
                  message: "Please enter distance",
                },
              ]}
            >
              <Input type="" placeholder="Distance" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="driver"
              label="Driver's Amount (KES)"
              initialValue={driver}
              rules={[
                {
                  required: true,
                  message: "Please enter driver's amount",
                },
              ]}
            >
              <Input type="" placeholder="Driver Amount" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel_amount"
              label="Total Fuel Needed ( Litres )"
              initialValue={fuel?.amount}
              rules={[
                {
                  required: true,
                  message: "Please enter fuel amount",
                },
              ]}
            >
              <Input type="" placeholder="Total Fuel Amount" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel_rate"
              label="Fuel Rate ( Per Litre )"
              initialValue={fuel?.rate}
              rules={[
                {
                  required: true,
                  message: "Please enter distance",
                },
              ]}
            >
              <Input type="" placeholder="Distance" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
