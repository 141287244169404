import { Button, Form, Input, message, Popover, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { RiFileExcel2Line, RiMailSendLine } from "react-icons/ri";
import {
  blobToDataUrl,
  ExportToExcel,
  getExportData,
} from "../../../utils/exportExcel";
import { v4 as uuidv4 } from "uuid";

import Manage from "..";
import CreateDealStage from "./components/CreateDealStage";
import UpdateDealStage from "./components/UpdateDealStage";
import { postDataMail } from "../../../services/mail";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import Something from "../../../components/shared/Something";
import DeleteDealStage from "./components/DeleteDealStage";
import {
  handleFetchDealStages,
  handleReorderDealStage,
} from "../../../api/dealStages";
import formatMoney from "../../../utils/formatMoney";
import StageWrapper from "./components/StageWrapper";

export default function AllDealStages() {
  const [selected, setSelected] = useState<any>({});
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const {
    data: stages,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["dealStages"],
    queryFn: handleFetchDealStages,
  });

  const { mutate: reorderStage } = useMutation({
    mutationFn: handleReorderDealStage,
    onSuccess: () => {
      message.success("Updated Successfully");
      queryClient.invalidateQueries({ queryKey: ["dealStages"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const [emailForm] = Form.useForm();

  const handleRefetch = () => {
    refetch();
  };

  const [state, setState] = useState<any>({
    extems: [
      {
        sn: uuidv4(),
        name: "",
        description: "",
        purpose: "",
        estimatedPrice: 0,
        stockReference: null,
        quantity: 1,
      },
    ],
  });

  useEffect(() => {
    const items =
      stages?.length !== 0
        ? stages
        : [
            {
              sn: uuidv4(),
              name: "",
              purpose: "",
              description: "",
              estimatedPrice: 0,
              stockReference: null,
              quantity: 1,
              isGeneric: false,
            },
          ];

    setState({
      extems: items,
    });
  }, [stages]);

  const handleAddLineItem = (event: any) => {
    setState({
      extems: state.extems.concat([
        {
          sn: uuidv4(),
          name: "",
          purpose: "",
          description: "",
          estimatedPrice: 0,
          stockReference: null,
          quantity: 1,
          isGeneric: false,
        },
      ]),
    });
  };

  const handleSetUpdate = ({ item }: any) => {
    setSelected(item);
    setUpdateModal(true);
  };

  const handleSetDelete = ({ item }: any) => {
    setSelected(item);
    setDeleteModal(true);
  };

  const handleLineItemChange = (elementIndex: any) => (event: any) => {
    let extems = state.extems.map((item: any, i: any) => {
      if (elementIndex !== i) return item;
      if (event.target === undefined) {
        const el: any = []?.filter((prod: any) => prod?._id === event);
        return {
          ...item,
          stockReference: el[0]?._id,
          name: el[0]?.name,
          isGeneric: el[0]?.isGeneric,
        };
      }
      return { ...item, [event.target.name]: event.target.value };
    });
    setState({ extems });
  };

  const handleRemoveLineItem = (elementIndex: any) => (event: any) => {
    setState({
      extems: state.extems.filter((item: any, i: any) => {
        return elementIndex !== i;
      }),
    });
  };

  const handleReorderLineItems = async (newextems: any) => {
    setState({
      extems: newextems,
    });
    const extractedIds = newextems.map((item: any) => item._id);
    await reorderStage({ data: extractedIds });
  };

  const handleFocusSelect = (event: any) => {
    event.target.select();
  };
  const sendMail = async (values: any) => {
    setMailLoading(true);
    const dt = stages?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name === null ? "-" : item?.name,
        Description: item?.description === null ? "-" : item?.description,
        Status: item?.isActive ? "Active" : "In Active",
        Rank: item?.rank === null ? "-" : item?.rank,
      };
    });

    const raw = getExportData(dt);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  return (
    <Manage
      title={"Deal Stage"}
      subtitle={"All"}
      description={"List of all deal stages"}
      actions={
        <div className="flex">
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-between">
                    <Form.Item wrapperCol={{ offset: 12, span: 8 }}>
                      <Button type="primary" htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>

                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={21} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  stages?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name === null ? "-" : item?.name,
                      Description:
                        item?.description === null ? "-" : item?.description,
                      Status: item?.isActive ? "Active" : "In Active",
                      Rank: item?.rank === null ? "-" : item?.rank,
                    };
                  }),
                  "DealStages.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={20} />
            </button>
          </Tooltip>
          <Button onClick={() => setCreateModal(true)}>+ New</Button>
        </div>
      }
    >
      <>
        <div className="flex flex-col">
          <div className="flex flex-col w-full">
            <div className="grid gap-x-0 grid-cols-12">
              <div className="col-span-12 sm:col-span-12 md:col-span-12">
                {isError ? (
                  <Something refetch={refetch} isFetching={isFetching} />
                ) : (
                  <div className="">
                    <StageWrapper
                      items={state?.extems === undefined ? [] : state?.extems}
                      state={state}
                      requisition={stages}
                      currencyFormatter={formatMoney}
                      addHandler={handleAddLineItem}
                      changeHandler={handleLineItemChange}
                      focusHandler={handleFocusSelect}
                      handleSetUpdate={handleSetUpdate}
                      handleSetDelete={handleSetDelete}
                      deleteHandler={handleRemoveLineItem}
                      reorderHandler={handleReorderLineItems}
                    />
                    <div className="text-gray-600 text-xs p-4">
                      Note: Click, Drag and Release to Rearange a deal stage
                      position
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <CreateDealStage
          isVisible={createModal}
          setVisible={setCreateModal}
          refetch={handleRefetch}
        />

        <UpdateDealStage
          isVisible={updateModal}
          setVisible={setUpdateModal}
          selected={selected}
        />

        <DeleteDealStage
          isVisible={deleteModal}
          setVisible={setDeleteModal}
          selected={selected}
        />
      </>
    </Manage>
  );
}
