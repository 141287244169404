import Moment from "react-moment";
import {
  EditOutlined,
  DeleteOutlined,
  MailOutlined,
  PhoneOutlined,
  FileTextOutlined,
  FolderOutlined,
  UserSwitchOutlined,
  CheckSquareOutlined,
} from "@ant-design/icons";
import parse from "html-react-parser";
import docIcon from "../../../../assets/images/pdf.png";
import formatSize from "../../../../utils/formatSize";

export default function LeadActivity({
  setUpdateVisible,
  setDeleteVisible,
  setDoneVisible,
  activity,
  handleSelect,
}: any) {
  const {
    title,
    description,
    feedback,
    start,
    end,
    action,
    isScheduled,
    dates,
    done_date,
  } = activity;

  let icon = <CheckSquareOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
  let color = "#84aebc";
  let feed, hasTime: boolean, hasFeed: boolean, doc, maintitle;

  const handleMakeACall = async () => {
    // const data = {
    //   username: "automark",
    //   password: "62cbc23f2de93d0d53aed90774a879a7",
    //   port: "8080",
    //   version: "1.1.0",
    //   url: "110.22.2.3:8260/report",
    //   urltag: "1",
    // };
    // const response = await fetch(
    //   `${REACT_APP_PBX_BASE_URL}/extension/dial_outbound?token=644b37b83138138e783dd3951aa37c98`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify({
    //       extid: "131",
    //       outto: "255659860313",
    //       autoanswer: "yes",
    //     }),
    //   }
    // );
    // const result = await response.json();
    // if (result.status === "Failed") {
    //   message.error("Sorry, Call didn't go through, Try again");
    // }
  };

  // const handleCall = async () => {
  //   const result = await fetch(
  //     `${REACT_APP_PBX_BASE_URL}/dial?token=6a52ae6ff7ba0ad111affbd4aac10331`,
  //     {
  //       method: "POST",
  //       body: JSON.stringify({
  //         caller: "131",
  //         callee: "105",
  //         autoanswer: "no",
  //       }),
  //     }
  //   );
  //   const json = await result.json();
  // };

  switch (action) {
    case "meeting":
      maintitle = `${
        isScheduled ? "Meeting is scheduled" : "Meeting was conducted"
      }`;
      icon = <UserSwitchOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      color = "#5671c4";
      hasTime = true;
      hasFeed = true;
      feed = (
        <div className="">
          <div className="">{feedback}</div>
          <div className="">
            From <Moment format="hh:mm">{start}</Moment> To{" "}
            <Moment format="hh:mm">{end}</Moment>
          </div>
        </div>
      );

      break;
    case "email":
      maintitle = `${isScheduled ? "Email is scheduled" : "Email was done"}`;
      icon = <MailOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      color = "#FDEE23";
      hasTime = false;
      hasFeed = true;
      feed = <div className="">{parse(`${feedback}`)}</div>;
      break;
    case "task":
      maintitle = `${isScheduled ? "Task is scheduled" : "Task was done"}`;
      icon = <CheckSquareOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      color = "#84aebc";
      hasTime = false;
      hasFeed = false;
      break;
    case "call":
      maintitle = `${isScheduled ? "Call is scheduled" : "Call was made"}`;
      icon = <PhoneOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      color = "#2EBCB3";
      hasTime = true;
      hasFeed = true;
      feed = (
        <div className="">
          <div className="">{feedback}</div>
          <div className="">
            From <Moment format="hh:mm">{start}</Moment> To{" "}
            <Moment format="hh:mm">{end}</Moment>
          </div>
        </div>
      );
      break;
    case "notes":
      maintitle = "Note was added";
      icon = <FileTextOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      color = "#30c9c4";
      hasTime = false;
      hasFeed = false;
      break;
    case "document":
      maintitle = `${
        isScheduled ? "Meeting is scheduled" : "Meeting was done"
      }`;
      icon = <FolderOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      doc = (
        <div className="flex items-center">
          <a
            href={`${activity?.file?.url}`}
            target="_blank"
            download="Document.pdf"
          >
            <img src={docIcon} className="w-8 h-9" alt="" />
          </a>
          <div className="ml-1">
            <div className="capitalize">{activity?.action}</div>
            <div className="capitalize">{formatSize(activity?.file?.size)}</div>
          </div>
        </div>
      );
      color = "#FCB412";
      hasTime = false;
      hasFeed = false;
      break;
    default:
      icon = <CheckSquareOutlined style={{ fontSize: 14, color: "#FFFFFF" }} />;
      color = "#84aebc";
      hasTime = false;
      hasFeed = false;
  }

  return (
    <div className="flex justify-between items-start">
      <div className="w-12 flex flex-col justify-center items-center">
        <div
          className="flex justify-center items-center w-8 h-8 rounded-full border"
          style={{ backgroundColor: color }}
        >
          {icon}
        </div>
        <div className="">
          <div className="border-l h-12"></div>
          <div className=""></div>
        </div>
      </div>
      <div className="bg-white mb-2 flex flex-col w-full">
        <div className="border-b flex justify-between p-2">
          <div className=" font-bold">
            {title}
            {!dates?.fulfilled ? (
              <span className="mx-1">
                <Moment fromNow>{activity?.dates?.appointment}</Moment>
              </span>
            ) : null}
          </div>
          <div className="text-gray-400">
            <Moment fromNow>{activity?.dates?.created}</Moment>
          </div>
        </div>
        <div className="px-2 pb-2 pt-1 flex flex-col">
          <div className="">{description}</div>
          <div className="">{feed}</div>
          <div className="">{doc}</div>
          <div className="flex justify-between text-gray-600 mr-1">
            <div className="">
              {activity?.isScheduled ? "Appointment: " : "Created :"}
              <Moment format={"Do, MMM, YYYY"}>{dates?.appointment}</Moment>
            </div>
            <div className="">
              {activity?.action !== "document" && (
                <button
                  className="focus:outline-none px-2 hover:bg-yellow-100"
                  onClick={() => {
                    setUpdateVisible(true);
                    handleSelect({
                      hasFeed,
                      hasTime,
                      selected: { ...activity },
                    });
                  }}
                >
                  <EditOutlined />
                </button>
              )}
              <button
                className="focus:outline-none  pl-2 hover:bg-red-100"
                onClick={() => {
                  setDeleteVisible(true);
                  handleSelect({ hasFeed, hasTime, selected: { ...activity } });
                }}
              >
                <DeleteOutlined />
              </button>
            </div>
          </div>
          <div>
            {activity?.isScheduled && (
              <div className="text-gray-600">
                {!dates?.fulfilled ? (
                  <button
                    className="border p-1 mt-1 focus:outline-none hover:bg-green-100"
                    onClick={() => {
                      setDoneVisible(true);
                      handleSelect({
                        hasFeed,
                        hasTime,
                        selected: { ...activity },
                      });
                    }}
                  >
                    Done
                  </button>
                ) : (
                  <span className="">
                    Fullfilled :{" "}
                    <Moment format={"Do MMM, YYYY"}>
                      {activity?.dates?.fulfilled}
                    </Moment>
                  </span>
                )}
              </div>
            )}
            {action === "call" ? (
              <>
                {done_date === null ? (
                  <>
                    <button
                      className="border p-1 mt-1 focus:outline-none hover:bg-green-100 mx-1"
                      onClick={() => handleMakeACall()}
                    >
                      Make a Call
                    </button>
                  </>
                ) : null}
              </>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
