import {
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
} from "../services/auto";

const handleCreateBudget = async (data: any) => {
  const uri = `api/support/budget`;
  const response = postDataAuto({ data, uri });
  return response;
};
const handleFetchYearBudget = async ({year}: any) => {
  const uri = `api/support/budget/${year}`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleUpdateMonthBudget = async ({_id, data}: any) => {
  const uri = `api/support/budget/${_id}`;
  const response = patchDataAuto({data, uri });
  return response;
};



export {
  handleFetchYearBudget,
  handleCreateBudget,
  handleUpdateMonthBudget
};
