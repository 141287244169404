import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect } from "react";
import { handleUpdateServiceSite } from "../../../../api/serviceSites";

export default function UpdateServiceSite({
  isVisible,
  setVisible,
  selected,
}: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateServiceSite,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["serviceSites"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name } = await form.getFieldsValue();
    const data = {
      name,
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  useEffect(() => {
    form.resetFields();
  }, [selected, form]);

  return (
    <Modal
      open={isVisible}
      title="Update Service Site"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="updateServiceSite"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updateServiceSite"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="address"
              label="Address"
              initialValue={selected?.address}
              rules={[
                {
                  required: true,
                  message: "Please enter address",
                },
              ]}
            >
              <Input placeholder="Address" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={selected?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email ",
                },
              ]}
            >
              <Input placeholder="Email" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              initialValue={selected?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile ",
                },
              ]}
            >
              <Input placeholder="Mobile" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="contact"
              label="Contact Person Name"
              initialValue={selected?.contact}
              rules={[
                {
                  required: true,
                  message: "Please enter contact person name ",
                },
              ]}
            >
              <Input placeholder="Contact Person Name" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
