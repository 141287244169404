import { useState } from "react";
import { AiOutlinePlus } from "react-icons/ai";
import UpdateHobby from "./UpdateHobby";
import DeleteHobby from "./DeleteHobby";
import CreateFile from "./CreateFile";
import docIcon from "../../../../assets/images/pdf.png";

export default function FileDetails({ contact, editMode }: any) {
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selected, setSelected] = useState<any>({});

  return (
    <div className="">
      <div className="flex justify-between pb-2 border-b-2">
        <div className="font-bold">Customer Files</div>
        {editMode && (

          <button onClick={() => setCreateModal(true)} className="border p-2">
          <AiOutlinePlus />
        </button>
          )}
      </div>

      {contact?.files?.length === 0 ? (
        <div className="">
          <div className="text-gray-700 py-8">No Files Found</div>
        </div>
      ) : (
        <div className="">
          {contact?.files?.map((item: any, index: number) => (
            <div className="flex justify-between  my-4" key={index}>
              <div className="flex flex-col justify-center">
                {index + 1}. {item?.name || "-----"}
                <div className="flex items-center">
                  <a href={`${item?.url}`} target="_blank" download="File.pdf">
                    <img src={docIcon} className="w-8 h-9" alt="" />
                  </a>
                  <div className="ml-1">
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}

      <CreateFile
        isVisible={createModal}
        setVisible={setCreateModal}
        contact={contact}
      />

      <UpdateHobby
        isVisible={updateModal}
        setVisible={setUpdateModal}
        contact={contact}
        selected={selected}
      />

      <DeleteHobby
        isVisible={deleteModal}
        setVisible={setDeleteModal}
        contact={contact}
        selected={selected}
      />
    </div>
  );
}
