
export default function SoldValue({ dates }: any) {
  return (
    <div className="border border-gray-300 px-4 py-2 bg-red-100 h-20">
      <div className="flex justify-between items-center">
        <div className="flex-1">
          <div className="flex justify-between">
            <div className="text-lg">Sold Value [ KES ]</div>
          </div>
          <div className="text-xl font-bold">
          </div>
        </div>
      </div>
    </div>
  );
}
