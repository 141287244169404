import { useState } from "react";
import { FiChevronsRight } from "react-icons/fi";
import formatMoney from "../../../utils/formatMoney";
import DealPanel from "../../reports/deals/components/DealPanel";

export default function DealWidget({ item }: any) {
  const [isVisible, setVisible] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);

  const getValue = () => {
    let total = 0;
    item?.list.forEach((i: any) => {
      i.vehicles?.forEach((v: any) => {
        total = total + v?.prices?.inclusive;
      });
    });
    return total;
  };

  return (
    <div className="flex flex-col justify-between h-full bg-white border">
      <div className="flex flex-col flex-1 p-4">
        <div className="text-gray-500 text-xs uppercase">
          {item?._id} : <span>{item?.list?.length || "-"}</span>
        </div>
        <div className="font-bold text-toyota text-2xl">
          {isLoading ? (
            <div className="w-28 bg-gray-100 h-7 mt-2 rounded-md"></div>
          ) : (
            <div className="text-2xl flex justify-between">
              <div className="text-2xl">
                {formatMoney(getValue() ? getValue() : 0)} <span className="text-sm">KES</span>
              </div>
            </div>
          )}
        </div>
      </div>
      <button
        onClick={() => {
          setVisible(true);
        }}
        className="flex justify-between items-center border-t h-10 p-4 hover:bg-red-50 hover:cursor-pointer focus:outline-none"
      >
        <div className="mb-2">See Details</div>
        <div className="">
          <FiChevronsRight />
        </div>
      </button>

      <DealPanel
        title="DEALS BY STATUS"
        subtitle={item?._id}
        deals={item?.list || []}
        isVisible={isVisible}
        setVisible={setVisible}
      />
    </div>
  );
}
