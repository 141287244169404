import {
  deleteDataAuto,
  fetchDataAuto,
  patchDataAuto,
  postDataAuto,
  postFileAuto,
} from "../services/auto";

const handleFetchVehicles = async ({ queryKey }: any) => {
  const [
    ,
    {
      pagination: { page = 1, per_page = 12 },
      params,
    },
  ] = queryKey;
  const uri = `api/vehicles?page=${page}&perPage=${per_page}${params}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchVehiclesBySource = async ({ queryKey }: any) => {
  const [, { source_id }] = queryKey;
  const uri = `api/vehicles?perPage=0&source=${source_id}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleFetchAllVehicles = async () => {
  const uri = `api/vehicles`;
  const response = fetchDataAuto({ uri });
  return response;
};


const handleFetchUnfilteredVehicles = async ({source}: any) => {
  const s = source === undefined ? '': source
  const uri = `api/vehicles/unfiltered?source=${s}`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleDowloadVehicles = async () => {
  const uri = `api/vehicles/all/download`;
  const response = fetchDataAuto({ uri });
  return response;
};

const handleCreateVehicle = async (data: any) => {
  const uri = `api/vehicles`;
  const response = postDataAuto({ data, uri });
  return response;
};

const handleUploadVehicles = async (data: any) => {
  const uri = `api/vehicles/upload`;
  const response = postFileAuto({ data, uri });
  return response;
};

const handleFetchVehicle = async (chassis: any) => {
  const uri = `api/vehicles/${chassis}`;
  const response = fetchDataAuto({ uri });
  return response;
};
const handleUpdateVehicleDetails = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/details`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleDeleteVehicle = async ({ chassis }: any) => {
  const uri = `api/vehicles/${chassis}`;
  const response = deleteDataAuto({ uri });
  return response;
};

const handleUploadVehicleImage = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/upload/image`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUploadVehicleImages = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/upload/images`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUploadVehicleDocument = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/upload/document`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleLocation = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/location`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleSource = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/source`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehiclePrice = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/price`;
  const response = patchDataAuto({ data, uri });
  return response;
};


const handleUpdateVehicleOwner = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/owner`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleSpecs = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/specs`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleOtherSpecs = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/specs/others`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleFeatures = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/features`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleShipment = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/shipment`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehiclePublishment = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/publish`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleAvailability = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/availability`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleMakeVehicleAvailable = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/available`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleStatus = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/status`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleMakeVehicleSold = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/sale`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleMakeVehicleReturned = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/return`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleMakeVehicleReserved = async ({ data, chassis }: any) => {
  const uri = `api/vehicles/${chassis}/reserve`;
  const response = patchDataAuto({ data, uri });
  return response;
};

const handleUpdateVehicleCheck = async ({
  data,
  chassis,
  check_id,
  question_id,
}: any) => {
  const uri = `api/vehicles/${chassis}/check/${check_id}/question/${question_id}`;
  const response = patchDataAuto({ data, uri });
  return response;
};

export {
  handleFetchVehicles,
  handleFetchVehicle,
  handleCreateVehicle,
  handleDeleteVehicle,
  handleUploadVehicles,
  handleFetchAllVehicles,
  handleDowloadVehicles,
  handleUpdateVehicleDetails,
  handleUploadVehicleImage,
  handleUploadVehicleImages,
  handleUpdateVehicleLocation,
  handleUpdateVehicleSource,
  handleUpdateVehiclePrice,
  handleUpdateVehicleOwner,
  handleUpdateVehicleFeatures,
  handleUploadVehicleDocument,
  handleUpdateVehicleShipment,
  handleUpdateVehicleSpecs,
  handleMakeVehicleAvailable,
  handleMakeVehicleReturned,
  handleUpdateVehicleStatus,
  handleMakeVehicleSold,
  handleMakeVehicleReserved,
  handleUpdateVehicleOtherSpecs,
  handleFetchUnfilteredVehicles,
  handleUpdateVehiclePublishment,
  handleUpdateVehicleAvailability,
  handleUpdateVehicleCheck,
  handleFetchVehiclesBySource,
};
