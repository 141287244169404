import Something from "../../../components/shared/Something";
import Wrap from "../Wrap";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router";
import { handleFetchLead } from "../../../api/leads";
import BackTool from "../../../components/toolbar/BackTool";
import VehicleDetails from "./components/VehicleDetails";
import ActivityHeader from "./components/ActivityHeader";
import LeadActivities from "./components/LeadActivities";
import LeadRemarks from "./components/LeadRemarks";
import LeadParticipants from "./components/LeadParticipants";
import LeadTasks from "./components/LeadTasks";
import LeadContacts from "./components/LeadContacts";
import LeadBasics from "./components/LeadBasics";
import { useContext } from "react";
import { AuthContext } from "../../../context/AuthContext";

export default function DetailedLead() {
  const { auth } = useContext(AuthContext);
  const updateAccess = [2, 3, 4, 9];

  const access = updateAccess.includes(auth?.accessLevel);
  const { id } = useParams();

  const {
    data: lead,
    refetch,
    isFetching,
    isError,
  } = useQuery({
    queryKey: ["leads", id],
    queryFn: () => handleFetchLead({ _id: id }),
  });

  return (
    <Wrap active="Leads" actions={<></>}>
      {isError ? (
        <Something refetch={refetch} isFetching={isFetching} />
      ) : (
        <>
          <BackTool />
          <div className="m-2 p-2 border bg-white">
            <div className="grid gap-x-4 grid-cols-12 bg-white">
              <div className="col-span-12 sm:col-span-3 md:col-span-3">
                <LeadBasics lead={lead || {}} lead_id={id} access={access} />
                <LeadContacts lead={lead || {}} />

                <LeadTasks lead={lead || {}} lead_id={id} access={access} />

                <LeadParticipants
                  lead={lead || {}}
                  lead_id={id}
                  access={access}
                />

                <LeadRemarks lead={lead || {}} lead_id={id} access={access} />

                <VehicleDetails
                  lead={lead || {}}
                  lead_id={id}
                  access={access}
                />
              </div>
              <div className="col-span-12 sm:col-span-9 md:col-span-9">
                <ActivityHeader
                  lead={lead || {}}
                  lead_id={id}
                  access={access}
                />
                <LeadActivities
                  lead={lead || {}}
                  lead_id={id}
                  access={access}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </Wrap>
  );
}
