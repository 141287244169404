import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  Modal,
  Form,
  message,
  Row,
  Col,
  Input,
  Button,
  DatePicker,
  Select,
} from "antd";
import {
  handleMakeVehicleSold,
  handleUpdateVehicleStatus,
} from "../../../../api/vehicles";
import { AiOutlineCheck } from "react-icons/ai";
import { BsChevronLeft } from "react-icons/bs";
import { handleFetchContacts } from "../../../../api/contacts";
import { FiUserPlus } from "react-icons/fi";
import CreateContact from "../../contacts/components/CreateContact";

const { Option } = Select;

export default function UpdateStatus({
  isVisible,
  setVisible,
  vehicle,
  chassis,
}: any) {
  const [owner, setOwner] = useState<any>({
    entity: "",
    name: "",
    mobile: "",
    email: "",
  });
  const [selected, setSelected] = useState<any>(vehicle?.status);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const year = new Date().getFullYear();

  const [sell, setSell] = useState<boolean>(false);
  const [form] = Form.useForm();

  const { data: contacts } = useQuery({
    queryKey: ["contacts"],
    queryFn: handleFetchContacts,
  });

  const handleContactSelected = (e: any) => {
    const selected = contacts?.filter((item: any) => item?._id === e);
    setOwner({
      entity: selected[0]?._id,
      name: selected[0]?.name,
      mobile: selected[0]?.mobile || null,
      email: selected[0]?.email || null,
    });
  };

  const { mutate: updteStatus } = useMutation({
    mutationFn: handleUpdateVehicleStatus,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { mutate: makeSold } = useMutation({
    mutationFn: handleMakeVehicleSold,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["vehicles", chassis] });
      queryClient.invalidateQueries({ queryKey: ["actuals", year] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async (status: any) => {
    const data = {
      status,
    };
    await updteStatus({ data, chassis: vehicle?.chassis });
    setVisible(false);
  };

  const onSubmitSale = async () => {
    const values = await form.validateFields();
    await makeSold({ chassis, data: { ...values, owner } });
    setVisible(false);
  };

  const status = [
    { id: 1, name: "Available", value: "available", isSupported: true },
    { id: 2, name: "Reserved", value: "reserved", isSupported: true },
    { id: 3, name: "Returned", value: "returned", isSupported: true },
  ];

  const clearForm = async () => {
    form.resetFields();
  };

  const contactOptions = contacts?.map((contact: any, index: number) => (
    <Option value={contact?._id} key={index}>
      {contact?.name}
    </Option>
  ));

  useEffect(() => {
    setSelected(vehicle?.status);
  }, [vehicle]);

  return (
    <Modal
      open={isVisible}
      title="Vehicle Status"
      onCancel={() => setVisible(false)}
      footer={null}
    >
      {!sell ? (
        <div className="">
          {status?.map((item: any, index: number) => (
            <button
              onClick={() => {
                onSubmit(item?.value);
                setSelected(item?.value);
              }}
              className={`flex items-center mb-2 bg-gray-100 w-full p-2  ${
                item?.isSupported ? "" : "text-gray-200"
              }`}
              key={index}
            >
              <div className="w-8 h-8 border flex justify-center items-center">
                {selected === item?.value ? <AiOutlineCheck /> : ""}
              </div>
              <div className="ml-2">{item?.name}</div>
            </button>
          ))}

          <button
            onClick={() => setSell(true)}
            type="button"
            className={`flex items-center mb-2 bg-gray-100 w-full p-2`}
          >
            <div className="w-8 h-8 border flex justify-center items-center"></div>
            <div className="ml-2">Sold</div>
          </button>
        </div>
      ) : (
        <div className="">
          <button
            type="button"
            onClick={() => setSell(false)}
            className="flex items-center pt-4 pb-2"
          >
            <BsChevronLeft />
            <div className="ml-1">Back</div>
          </button>

          <div className="text-green-600">Status: Sold</div>
          <Form
            id="saleVehicle"
            form={form}
            name="normal_login"
            className="login-form"
            onFinish={onSubmit}
            layout="vertical"
          >
            <Row gutter={[16, 0]}>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="invoice"
                  label="Invoice Number"
                  rules={[
                    {
                      required: true,
                      message: "Please enter invoice number",
                    },
                  ]}
                >
                  <Input
                    type="number"
                    placeholder="Invoice Number"
                    className="w-full"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="sold_date"
                  label="Invoice Date"
                  rules={[
                    {
                      required: true,
                      message: "Please enter invoice date",
                    },
                  ]}
                >
                  <DatePicker
                    placeholder="Invoice Date"
                    format={"DD/MM/YYYY"}
                    className="w-full"
                  />
                </Form.Item>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <div className="flex items-center">
                  <Form.Item
                    name="contact"
                    label="Customer"
                    rules={[
                      {
                        required: true,
                        message: "Please select customer",
                      },
                    ]}
                    style={{ width: "80%" }}
                  >
                    <Select
                      showSearch
                      
                      placeholder="Search and Select Customer"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(e) => handleContactSelected(e)}
                    >
                      {contactOptions}
                    </Select>
                  </Form.Item>
                  <Button className="mt-1" onClick={() => setContactModal(true)}>
                    <FiUserPlus />
                  </Button>
                </div>
              </Col>
              <Col xs={{ span: 24 }} lg={{ span: 12 }}>
                <Form.Item
                  name="vsb"
                  label="VSB"
                  initialValue={vehicle?.vsb}
                  rules={[
                    {
                      required: true,
                      message: "Please enter VSB",
                    },
                  ]}
                >
                  <Input type="" placeholder="VSB" className="w-full" />
                </Form.Item>
              </Col>
            </Row>

            <Form.Item>
              <Button
                danger
                type="primary"
                htmlType="button"
                onClick={() => onSubmitSale()}
              >
                Sold
              </Button>
              <Button
                danger
                htmlType="button"
                className="mx-2"
                onClick={clearForm}
              >
                Clear
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}

      <CreateContact isVisible={contactModal} setVisible={setContactModal} />
    </Modal>
  );
}
