import Cookies from "js-cookie";
const REACT_APP_AUTO_BASE_URL = process.env.REACT_APP_AUTO_BASE_URL
const fetchDataAuto = async ({ uri }: any) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${REACT_APP_AUTO_BASE_URL}/${uri}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (response.status === 401) {
      return Promise.reject("Unauthorized");
    }
    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }

    return await response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};

const postDataAuto = async ({ data, uri }: any) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${REACT_APP_AUTO_BASE_URL}/${uri}`, {
      method: "POST",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};

const postFileAuto = async ({ data, uri }: any) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${REACT_APP_AUTO_BASE_URL}/${uri}`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: data,
    });
    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};
const patchDataAuto = async ({ data, uri }: any) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${REACT_APP_AUTO_BASE_URL}/${uri}`, {
      method: "PATCH",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }

    return await response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};

const putDataAuto = async ({ data, uri }: any) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${REACT_APP_AUTO_BASE_URL}/${uri}`, {
      method: "PUT",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};

const deleteDataAuto = async ({ uri }: any) => {
  try {
    const token = Cookies.get("token");
    const response = await fetch(`${REACT_APP_AUTO_BASE_URL}/${uri}`, {
      method: "DELETE",
      headers: {
        Accept: "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      const errorData = await response.json();
      return Promise.reject(errorData.message || "Something went wrong");
    }
    return response.json();
  } catch (error) {
    return Promise.reject("Network error");
  }
};

export {
  fetchDataAuto,
  postDataAuto,
  patchDataAuto,
  putDataAuto,
  deleteDataAuto,
  postFileAuto,
};
