import { AiOutlineRight } from "react-icons/ai";

export default function StatusWidget({ item }: any) {
  return (
    <div className="col-span-12 sm:col-span-2 border bg-white">
      <div className="flex flex-col">
        <div className="p-2">
          <div className="flex justify-between">
            <div className="font-bold">{item?.label || "Approved"}</div>
            <div className="">{item?.count || "-"}</div>
          </div>
          <div className="text-xl">
          </div>
        </div>
        <div className="border-t items-center p-1 mt-4 hover:bg-gray-50 cursor-pointer">
          <div className="flex justify-between items-center">
            <div className="p-1">See Details</div>
            <div className="">
              <AiOutlineRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
