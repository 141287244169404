import Moment from "react-moment";
import formatMoney from "../../../../utils/formatMoney";
import { formatNumber } from "../../../../utils/formatNumber";

export default function VehicleDetails({ deal }: any) {
  return (
    <div className="text-xs border">
      <div className="flex justify-start items-center bg-white p-2  h-12">
        <div className="font-bold text-sm"> VEHICLE (S)</div>
      </div>
      <div className="">
        {deal?.vehicles?.map((vehicle: any, index: number) => (
          <div className=" p-4 mb-2" key={index}>
            <div className="border-b w-full pb-2 mb-2 font-bold text-sm">
              Vehicle {index + 1} : {vehicle?.year} {vehicle?.model?.name}{" "}
              {vehicle?.chassis}
            </div>

            <div className="grid grid-cols-12 gap-x-2 bg-white mb-1">
              <div className="col-span-6">
                <div className="flex justify-center items-center">
                  <img
                    src={vehicle?.car?.thumbnail}
                    className="w-full aspect-[4/3] object-contain"
                    style={{ width: "100%" }}
                    alt=""
                  />
                </div>
                <div className="mb-2">
                  <span className="mr-1">Seling Price: </span>
                  <span className="text-gray-600">
                    
                    {vehicle?.car?.prices?.inclusive
                      ? formatMoney(vehicle?.car?.prices?.inclusive)
                      : 0} <span className="text-xs">KES</span>
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Availability: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.availability?.title || "-"},{" "}
                    {vehicle?.car?.availability?.description || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Status: </span>
                  <span className="capitalize">{vehicle?.car?.status}</span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Exterior Color: </span>
                  <span className="capitalize">
                    {vehicle?.car?.colors?.exterior || "-"}
                  </span>
                </div>
              </div>
              <div className="col-span-6">
                <div className="mb-2">
                  <span className="mr-1">Fuel: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.specifications?.fuel?.fuel_type || "-"}
                  </span>
                </div>

                <div className="mb-2">
                  <span className="mr-1">Chassis: </span>
                  <span className="text-gray-600">
                    {vehicle?.chassis || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Year: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.year || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Registration: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.registration || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Shipment Date: </span>
                  <span className="text-gray-600">
                    <Moment format="Do MMM, YYYY">
                      {vehicle?.car?.shipment?.date || "-"}
                    </Moment>
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Bond Arrival Date: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.arrival ? (
                      <Moment format="Do MMM, YYYY">
                        {vehicle?.car?.arrival || "-"}
                      </Moment>
                    ) : (
                      "-"
                    )}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Transmission: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.specifications?.transmission || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Import Invoice: </span>
                  <span className="text-gray-600">
                    {vehicle?.import_invoice || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Mileage: </span>
                  <span className="text-gray-600">
                    {formatNumber(vehicle?.car?.mileage || "-")} Km
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">VSB: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.vsb || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Source: </span>
                  <span className="text-gray-600">
                    {vehicle?.car?.source?.name || "-"}
                  </span>
                </div>
                <div className="mb-2">
                  <span className="mr-1">Location : </span>
                  <span className="text-gray-600">
                    {vehicle?.location?.name || "-"}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
