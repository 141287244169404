export default function DocumentBanks() {
  return (
    <div className="flex mt-4">
      <div className="w-28 font-bold">
        <div className="">Bank Details:</div>
        <div className="">A/c Name:</div>
        <div className="">A/c Number:</div>
        <div className="">Bank:</div>
        <div className="">Swift Code:</div>
      </div>
      <div className="flex-1">
        <div className="flex justify-between w-full">
          <div className="border border-black w-2/5">
            <div className="border-b border-black font-bold text-center">
              KES ACCOUNT
            </div>
            <div className="border-b border-black font-bold px-2">
              -
            </div>
            <div className="border-b border-black font-bold px-2">
              -
            </div>
            <div className="border-b border-black px-2">-</div>
            <div className="px-2">-</div>
          </div>
          <div className="w-1/5"></div>
          <div className="border border-black w-2/5">
            <div className="border-b border-black font-bold text-center">
              KES ACCOUNT
            </div>
            <div className="border-b border-black font-bold px-2">
              -
            </div>
            <div className="border-b border-black font-bold px-2">
            -
            </div>
            <div className="border-b border-black px-2">-</div>
            <div className="px-2">-</div>
          </div>
        </div>
        <div className="mt-2 font-bold">
          ( Kindly refer the proforma number in all correspondence and when
          remitting payments )
        </div>
      </div>
    </div>
  );
}
