export default function ConvWidget({ dates }: any) {

  return (
    <div className="col-span-3 border border-gray-300 p-2 bg-green-100 h-20">
      <div className="">Convertion Rate - [ Won Deals ] </div>
      <div className="text-lg">
        <span className="text-3xl">-</span> / -{" "}
        <span className="font-bold">
         - %
        </span>
      </div>
    </div>
  );
}
