import {
  Button,
  DatePicker,
  Form,
  Input,
  Popover,
  Tooltip,
  message,
} from "antd";
import {
  RiFileExcel2Line,
  RiFilterOffLine,
  RiMailSendLine,
  RiSearch2Line,
} from "react-icons/ri";
import {
  ExportToExcel,
  blobToDataUrl,
  getExportData,
} from "../../../../utils/exportExcel";
import { useContext, useState } from "react";
import { postDataMail } from "../../../../services/mail";
import { BsCaretDown } from "react-icons/bs";
import { AiOutlineCheck, AiOutlinePlus } from "react-icons/ai";
import CreateInquiryPanel from "./CreateInquiryPanel";
import moment from "moment";
import dayjs from "dayjs";
import { AuthContext } from "../../../../context/AuthContext";
const { Search } = Input;
const { RangePicker } = DatePicker;

export default function IHeader({
  inquiries,
  setFilters,
  filters,
  setAdvSearch,
  advSearch,
  handleAdvancedSearch,
  clearAllFilters,
}: any) {
  const { auth } = useContext(AuthContext);
  const [createModal, setCreateModal] = useState<boolean>(false);
  const [isMailLoading, setMailLoading] = useState<boolean>(false);
  const [messageModal, toggleMessageModal] = useState(false);
  const [showRange, setShowRange] = useState(true);
  const [isFiltered, setFiltered] = useState<boolean>(false);
  const [form] = Form.useForm();

  const createAccess = [1, 2, 3, 4, 9];

  const initial = {
    dates: {
      startDate: moment().startOf("month"),
      endDate: moment().endOf("month"),
      isRange: true,
    },
    option: {
      id: 3,
      name: "This Month",
      start: moment().startOf("month"),
      end: moment().endOf("month"),
      isRange: true,
    },
  };

  const [dates, setDates] = useState<any>({
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month"),
    isRange: true,
  });

  const [option, setOption] = useState<any>({ ...initial?.option });

  const [emailForm] = Form.useForm();

  const clearFilters = () => {
    form.resetFields();
    clearAllFilters();
    setDates({ ...initial?.dates });
    setOption({ ...initial?.option });
    setShowRange(true);
  };

  const handleFetchData = ({ dates }: any) => {
    const data: any = { startDate: dates?.startDate, endDate: dates?.endDate };
    handleAdvancedSearch(data);
  };

  const sendMail = async (values: any) => {
    setMailLoading(true);
    const data = inquiries?.data?.map((item: any, index: number) => {
      return {
        "S/N": index + 1,
        Name: item?.name || "-",
        Mobile: item?.mobile || "-",
        Email: item?.email || "-",
        Source: item?.source || "-",
        Medium: item?.medium || "-",
        Created:
          item?.createdAt === null
            ? "-"
            : moment(item?.createdAt).format("DD/MM/YYYY"),
        Consultant: item?.assigned?.to,
        Requirements: item?.requirements,
        Interested: item?.engagement?.isInterested ? "Yes" : "No",
        Contacted: item?.engagement?.isContacted ? "Yes" : "No",
        "Require Follow Up": item?.engagement?.requireFollowUp ? "Yes" : "No",
        "Contacted At":
          item?.engagement?.contactedAt === null
            ? "-"
            : moment(item?.engagement?.contactedAt).format("DD/MM/YYYY"),
        "Contacted By": item?.engagement?.consultant?.name || "-",
        Feedback: item?.feedback,
      };
    });

    const raw = getExportData(data);
    const uri = await blobToDataUrl(raw);

    const maildata = {
      email: values.email,
      subject: values.subject,
      data: uri,
    };

    const mailuri = "mail";

    await postDataMail({ data: maildata, uri: mailuri });
    setMailLoading(false);

    message.success("Email Sent Successfully");
    emailForm.resetFields();
    toggleMessageModal(false);
  };

  const onDateChange = (date: any) => {
    if (date === null) return;
    setFiltered(true);
    setDates({
      startDate: moment(new Date(date)),
      endDate: moment(new Date(date)),
      isRange: false,
    });

    handleFetchData({
      dates: {
        startDate: moment(new Date(date)),
        endDate: moment(new Date(date)),
        isRange: false,
      },
    });
  };

  const onRangeChange = (date: any) => {
    if(date === null) return
    setFiltered(true);
    setFilters({
      ...filters,
      startDate: moment(new Date(date[0])),
      endDate: moment(new Date(date[1])),
    });

    handleFetchData({
      dates: {
        startDate: moment(new Date(date[0])),
        endDate: moment(new Date(date[1])),
        isRange: true,
      },
    });
  };

  const options = [
    {
      id: 1,
      name: "Today",
      start: moment(new Date()),
      end: moment(new Date()),
    },
    {
      id: 2,
      name: "This Week",
      start: moment().startOf("week"),
      end: moment().endOf("week"),
    },
    {
      id: 3,
      name: "This Month",
      start: moment().startOf("month"),
      end: moment().endOf("month"),
    },
    {
      id: 4,
      name: "Year to Date",
      start: moment(new Date()).startOf("year"),
      end: moment(new Date()),
    },
  ];

  const content = (
    <div>
      {options?.map((item: any, index: number) => (
        <div
          className="flex flex-col justify-start items-start w-full"
          key={index}
        >
          <button
            onClick={() => {
              setOption(item);

              if (item.id !== 1) {
                setFiltered(true);
                setDates({
                  startDate: moment(new Date(item.start)),
                  endDate: moment(new Date(item.end)),
                  isRange: true,
                });

                handleFetchData({
                  dates: {
                    startDate: moment(new Date(item.start)),
                    endDate: moment(new Date(item.end)),
                  },
                });
                setShowRange(true);
              } else {
                setFiltered(false);
                setDates({
                  startDate: moment(new Date(item.start)),
                  endDate: moment(new Date(item.end)),
                  isRange: false,
                });
                setShowRange(false);
                handleFetchData({
                  dates: {
                    startDate: moment(new Date(item.start)),
                    endDate: moment(new Date(item.end)),
                  },
                });
              }
            }}
            className="py-2 border-t focus:outline-none w-full flex items-start"
          >
            <div
              className={`${
                option?.id === item?.id ? "bg-green-600" : ""
              } w-6 h-6 border mr-2 flex items-center justify-center`}
            >
              <AiOutlineCheck color="#FFFFFF" />
            </div>
            {item?.name}
          </button>
        </div>
      ))}

      <div className="flex flex-col justify-start items-start w-full border-t-2">
        <button
          onClick={() => {
            setShowRange(true);
            setOption({
              id: 5,
              name: "Date Range",
              start: moment(new Date()),
              end: moment(new Date()),
            });

            setDates({
              startDate: moment(new Date()),
              endDate: moment(new Date()),
              isRange: true,
            });
          }}
          className="py-2 border-t focus:outline-none w-full flex items-start"
        >
          Date Range
        </button>
      </div>
    </div>
  );
  return (
    <div className="h-12 border-b flex justify-between items-center px-2 bg-white">
      <Form layout="inline" form={form}>
        <Form.Item name="chassis">
          <Search
            placeholder="Search by name"
            allowClear
            className="rounded-none"
            onSearch={(e: any) => {
              setFilters({ ...filters, name: e });
            }}
          />
        </Form.Item>

        {showRange ? (
          <div className="flex justify-center items-center">
            <Form.Item name="ranger">
              <RangePicker
                allowClear={true}
                onChange={onRangeChange}
                format={"DD/MM/YYYY"}
                defaultValue={[dayjs(dates.startDate), dayjs(dates.endDate)]}
              />
            </Form.Item>
          </div>
        ) : (
          <div className="flex justify-center items-center">
            <Form.Item name="picker">
              <DatePicker
                onChange={onDateChange}
                defaultValue={dayjs(dates.startDate)}
                format={"DD/MM/YYYY"}
                placeholder="Specific date"
                className=" border-gray-300 rounded-none"
              />
            </Form.Item>
          </div>
        )}
        <Popover content={content} title="Options" placement="bottom">
          <button className="flex items-center cursor-pointer focus:outline-none">
            <div className="">{option?.name}</div>
            <div className="ml-1">
              <BsCaretDown />
            </div>
          </button>
        </Popover>
        <Button
          className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
          onClick={() => {
            setAdvSearch(!advSearch);
          }}
        >
          <div className="flex justify-center items-center">
            <div className="mr-2">Advanced Search</div>
            <div className="border-l px-2">
              <RiSearch2Line color="gray" size={14} />
            </div>
          </div>
        </Button>
        <Button
          className="ml-2 focus:outline-none hover:bg-red-100 rounded-none"
          onClick={() => {
            clearFilters();
          }}
        >
          <RiFilterOffLine color="gray" />
        </Button>
      </Form>
      <div className="">
        <div className="flex">
          {createAccess?.includes(auth?.accessLevel) && (
            <Button
              className="bg-white rounded-none ml-2 flex justify-center items-center"
              style={{ fontSize: 12 }}
              onClick={() => setCreateModal(true)}
            >
              <div className="">
                <AiOutlinePlus size={12} />
              </div>
              <div className="ml-1 pt-1">New</div>
            </Button>
          )}
          <Popover
            content={
              <>
                <Form
                  name="basic"
                  labelCol={{ span: 8 }}
                  wrapperCol={{ span: 16 }}
                  initialValues={{ remember: true }}
                  form={emailForm}
                  onFinish={sendMail}
                  autoComplete="off"
                >
                  <Form.Item
                    label="Email"
                    name="email"
                    rules={[{ required: true, message: "Email is Required" }]}
                  >
                    <Input placeholder="Email" />
                  </Form.Item>
                  <Form.Item
                    label="Subject"
                    name="subject"
                    rules={[{ required: true, message: "Subject is Required" }]}
                  >
                    <Input placeholder="Email Subject" />
                  </Form.Item>
                  <div className="flex flex-row justify-end border-t pt-2">
                    <Form.Item wrapperCol={{ offset: 0, span: 8 }}>
                      <Button
                        onClick={() => {
                          toggleMessageModal(false);
                        }}
                        danger
                      >
                        Cancel
                      </Button>
                    </Form.Item>
                    <Form.Item wrapperCol={{ offset: 2, span: 8 }}>
                      <Button type="primary" danger htmlType="submit">
                        {isMailLoading ? "Sending ..." : "Submit"}
                      </Button>
                    </Form.Item>
                  </div>
                </Form>
              </>
            }
            title="Message Content"
            trigger="focus"
            placement="left"
            open={messageModal}
          >
            <Tooltip title="Send Email">
              <button
                className="flex ml-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
                onClick={async () => {
                  toggleMessageModal(true);
                }}
              >
                <RiMailSendLine size={18} />
              </button>
            </Tooltip>
          </Popover>
          <Tooltip title="Export To Excel">
            <button
              color="#E50000"
              className="flex mx-2 justify-center items-center border px-4 py-1 hover:bg-gray-100"
              onClick={() =>
                ExportToExcel(
                  inquiries?.data?.map((item: any, index: number) => {
                    return {
                      "S/N": index + 1,
                      Name: item?.name || "-",
                      Mobile: item?.mobile || "-",
                      Email: item?.email || "-",
                      Source: item?.source || "-",
                      Medium: item?.medium || "-",
                      Created:
                        item?.createdAt === null
                          ? "-"
                          : moment(item?.createdAt).format("DD/MM/YYYY"),
                      Consultant: item?.assigned?.to,
                      Requirements: item?.requirements,
                      Interested: item?.engagement?.isInterested ? "Yes" : "No",
                      Contacted: item?.engagement?.isContacted ? "Yes" : "No",
                      "Require Follow Up": item?.engagement?.requireFollowUp
                        ? "Yes"
                        : "No",
                      "Contacted At":
                        item?.engagement?.contactedAt === null
                          ? "-"
                          : moment(item?.engagement?.contactedAt).format(
                              "DD/MM/YYYY"
                            ),
                      "Contacted By": item?.engagement?.consultant?.name || "-",
                      Feedback: item?.feedback,
                    };
                  }),
                  "Inquiries.xlsx"
                )
              }
            >
              <RiFileExcel2Line size={18} />
            </button>
          </Tooltip>
        </div>
      </div>

      <CreateInquiryPanel isVisible={createModal} setVisible={setCreateModal} />
    </div>
  );
}
