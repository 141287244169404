import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateAllowance } from "../../../../api/allowances";

export default function CreateAllowance({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateAllowance,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["allowances"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { fuel_rate, fuel_amount, ...rest } = await form.getFieldsValue();
    const data = {
      fuel: {
        rate: fuel_rate,
        amount: fuel_amount,
        price: fuel_rate * fuel_amount,
      },
      ...rest,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Allowance"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createAllowance"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createAllowance"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="flex flex-row, justify-between"></div>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="location"
              label="Location"
              rules={[
                {
                  required: true,
                  message: "Please enter location",
                },
              ]}
            >
              <Input type="" placeholder="Location" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="distance"
              label="Distance  ( KM )"
              rules={[
                {
                  required: true,
                  message: "Please enter distance",
                },
              ]}
            >
              <Input type="" placeholder="Distance" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="driver"
              label="Driver's Amount (KES)"
              rules={[
                {
                  required: true,
                  message: "Please enter driver's amount",
                },
              ]}
            >
              <Input type="" placeholder="Driver Amount" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel_amount"
              label="Total Fuel Amount ( Litres )"
              rules={[
                {
                  required: true,
                  message: "Please enter fuel amount",
                },
              ]}
            >
              <Input type="" placeholder="Total Fuel Amount" />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="fuel_rate"
              label="Fuel Rate ( Per Litre )"
              rules={[
                {
                  required: true,
                  message: "Please enter distance",
                },
              ]}
            >
              <Input type="" placeholder="Distance" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
