import { Button, Modal, Form, Row, Col, Input, message, Select } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateContact } from "../../../../api/contacts";

const { Option } = Select;

export default function CreateSupplier({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateContact,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["suppliers"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const origins = [
    { id: 1, name: "Japan" },
    { id: 2, name: "Singapore" },
    { id: 3, name: "South Africa" },
  ];

  const countryOptions = origins?.map((industry: any, index: number) => (
    <Option value={industry?.name} key={index}>
      {industry?.name}
    </Option>
  ));

  const onSubmit = async () => {
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="Create Supplier"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createSupplier"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createSupplier"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="">
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="name"
                label="Company Name"
                rules={[
                  {
                    required: true,
                    message: "Please enter name",
                  },
                ]}
              >
                <Input type="" placeholder="Name" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="industryId"
                label="Country"
                rules={[
                  {
                    required: false,
                    message: "Please enter country",
                  },
                ]}
              >
                <Select placeholder="Country">{countryOptions}</Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please enter email",
                  },
                ]}
              >
                <Input type="" placeholder="Email" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="mobile"
                label="Mobile"
                rules={[
                  {
                    required: true,
                    message: "Please enter mobile",
                  },
                ]}
              >
                <Input type="" placeholder="Mobile" />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="address"
                label="Address"
                rules={[
                  {
                    required: false,
                    message: "Please enter address",
                  },
                ]}
              >
                <Input type="" placeholder="Address" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="tin"
                label="TIN"
                rules={[
                  {
                    required: false,
                    message: "Please enter tin",
                  },
                ]}
              >
                <Input type="" placeholder="TIN" />
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="vrn"
                label="VRN"
                rules={[
                  {
                    required: false,
                    message: "Please enter vrn",
                  },
                ]}
              >
                <Input type="" placeholder="VRN" />
              </Form.Item>
            </Col>
          </Row>
        </div>
      </Form>
    </Modal>
  );
}
