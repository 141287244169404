import { useContext, useState } from "react";
import { FiEdit } from "react-icons/fi";
import UpdatePublish from "./UpdatePublish";
import { BsCheck2Square } from "react-icons/bs";
import { IoWarningOutline } from "react-icons/io5";
import { AuthContext } from "../../context/AuthContext";

export default function Publishment({
  vehicle,
  flags,
  chassis,
  editable,
}: any) {
  const { auth } = useContext(AuthContext);
  const [updateModal, setUpdateModal] = useState<boolean>(false);
  const editAccess = [4,9];

  return (
    <div>
      <div className="border-b border-t px-4 py-2 flex justify-between bg-gray-100">
        <div className=""> Automark Website</div>
        {editAccess?.includes(auth?.accessLevel) && (
          <div className="">
            {editable && (
              <button
                onClick={() => setUpdateModal(true)}
                className="border p-1 rounded-md"
              >
                <FiEdit />
              </button>
            )}
          </div>
        )}
      </div>
      <div className="border-t-4"></div>
      <div className="p-4" style={{ fontSize: 14 }}>
        <div className="flex justify-between">
          Published to the website :-
          <div className="text-gray-600">
            {flags?.isPublished ? (
              <div className="flex">
                <BsCheck2Square color="#16a34a" />
                <span className="ml-1 text-green-600">YES</span>
              </div>
            ) : (
              <div className="flex">
                <IoWarningOutline color="#dc2626" />
                <span className="ml-1 text-red-600">NO</span>
              </div>
            )}
          </div>
        </div>
      </div>
      <UpdatePublish
        isVisible={updateModal}
        setVisible={setUpdateModal}
        flags={flags}
        vehicle={vehicle}
        chassis={chassis}
      />
    </div>
  );
}
