import { Button, Drawer, Space } from "antd";
import { useContext, useState } from "react";
import { AiOutlineClose, AiOutlineCloseSquare } from "react-icons/ai";
import AssignConsultant from "./AssignConsultant";
import { MdOutlineAssignmentInd } from "react-icons/md";
import Moment from "react-moment";
import InquiryResponse from "./InquiryResponse";
import { useQuery } from "@tanstack/react-query";
import { handleFetchInquiry } from "../../../../api/inquiries";
import { BsCheck2Square } from "react-icons/bs";
import CreateContact from "./CreateContact";
import { FiUserPlus } from "react-icons/fi";
import { AuthContext } from "../../../../context/AuthContext";

export default function InquiryDetails({
  isVisible,
  setVisible,
  selected,
}: any) {
  const { auth } = useContext(AuthContext);
  const [responseModal, setResponseModal] = useState<boolean>(false);
  const [assignModal, setAssignModal] = useState<boolean>(false);
  const [contactModal, setContactModal] = useState<boolean>(false);
  const assignRights: any = [1, 4];
  const contactRights: any = [2, 4];
  const updateRights: any = [1, 2, 4];

  const { data: inquiry }: any = useQuery({
    queryKey: ["newInquiries", selected?._id],
    queryFn: () => handleFetchInquiry({ _id: selected?._id }),
  });

  return (
    <Drawer
      title={
        <div className="flex items-center">
          <span className="mr-2 text-lg">
            <span className="font-bold">INQUIRY : </span>
          </span>
          <div className="flex text-gray-500 px-2 uppercase">Details</div>
        </div>
      }
      placement={"right"}
      width={800}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <div className="">
        <div className="flex justify-between items-center mb-4 border-b pb-2 -mt-4">
          <div className="text-3xl">{inquiry?.name}</div>
          {!inquiry?.isExisting && (
            <div className="">
              {contactRights?.includes(auth?.accessLevel) && (
                <Button
                  className="flex items-center"
                  onClick={() => setContactModal(true)}
                >
                  <FiUserPlus />
                  <div className="ml-1">Add as a Contact / Customer</div>
                </Button>
              )}
            </div>
          )}
        </div>
        <div className="grid grid-cols-12 gap-x-4">
          <div className="col-span-6">
            <div className="text-lg border-b-2 border-red-400 p-2 bg-gray-100">
              Customer Details
            </div>
            <div className="">
              <div className="flex py-2 justify-between border-b">
                <div className="w-28 font-bold">Mobile Number </div>
                <div className="">{inquiry?.mobile || "-"}</div>
              </div>
              <div className="flex py-2 justify-between border-b">
                <div className="w-28 font-bold">Email Address </div>
                <div className="">{inquiry?.email || "-"}</div>
              </div>
              <div className="flex py-2 justify-between border-b">
                <div className="w-28 font-bold">Source</div>
                <div className="">{inquiry?.source || "-"}</div>
              </div>
              <div className="flex py-2 justify-between">
                <div className="w-28 font-bold">Medium</div>
                <div className="">{inquiry?.medium || "-"}</div>
              </div>
            </div>
          </div>
          <div className="col-span-6">
            <div className="">
              <div className="flex justify-between border-b-2 border-red-400 p-2 bg-gray-100">
                <div className="text-lg ">Consultant Assigned</div>
                {inquiry?.assigned?.to && (
                  <div className="">
                    <button
                      onClick={() => {
                        setAssignModal(true);
                      }}
                      className="border border-red-300 w-24 text-center flex items-center justify-center bg-red-100"
                    >
                      <MdOutlineAssignmentInd />
                      <span className="ml-1">Re-Assign</span>
                    </button>
                  </div>
                )}
              </div>
              {inquiry?.assigned?.to ? (
                <div className="">
                  <div className="py-2 border-b">
                    Name: {inquiry?.assigned?.to || "-"}
                  </div>
                  <div className="py-2">
                    Assigned: <Moment fromNow>{inquiry?.assigned?.date}</Moment>
                  </div>
                </div>
              ) : (
                <div className="py-2">
                  {assignRights?.includes(auth?.accessLevel) && (
                    <button
                      onClick={() => {
                        setAssignModal(true);
                      }}
                      className="border border-red-300 w-24 text-center flex items-center justify-center bg-red-100"
                    >
                      <MdOutlineAssignmentInd />
                      <span className="ml-1">Assign</span>
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="">Requirements:</div>
          <div className="flex flex-col py-1">
            <div className="border bg-gray-50 p-2 min-h-32">
              {inquiry?.requirements || "-"}
            </div>
          </div>
        </div>

        <div className="mt-4">
          <div className="flex justify-between w-full border-b-2 py-2">
            <div className="text-lg ">Engagement</div>
            {updateRights?.includes(auth?.accessLevel) && (
              <button
                onClick={() => setResponseModal(true)}
                className="border p-1"
              >
                Update
              </button>
            )}
          </div>

          <div className="flex border-b py-1">
            <div className="w-40">Interested :-</div>
            <div className="ml-2">
              {inquiry?.engagement?.isInterested ? (
                <div className="flex items-center">
                  <BsCheck2Square color="#16a34a" />
                  <span className="ml-1 text-green-600">YES</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <AiOutlineCloseSquare color="#dc2626" />
                  <span className="ml-1 text-red-600">NO</span>
                </div>
              )}
            </div>
          </div>

          <div className="flex border-b py-1">
            <div className="w-40">Contacted :-</div>
            <div className="ml-2">
              {inquiry?.engagement?.isContacted ? (
                <div className="flex items-center">
                  <BsCheck2Square color="#16a34a" />
                  <span className="ml-1 text-green-600">YES</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <AiOutlineCloseSquare color="#dc2626" />
                  <span className="ml-1 text-red-600">NO</span>
                </div>
              )}
            </div>
          </div>

          <div className="flex border-b py-1">
            <div className="w-40">Require Follow Up :-</div>
            <div className="ml-2">
              {inquiry?.engagement?.requireFollowUp ? (
                <div className="flex items-center">
                  <BsCheck2Square color="#16a34a" />
                  <span className="ml-1 text-green-600">YES</span>
                </div>
              ) : (
                <div className="flex items-center">
                  <AiOutlineCloseSquare color="#dc2626" />
                  <span className="ml-1 text-red-600">NO</span>
                </div>
              )}
            </div>
          </div>

          <div className="flex border-b py-1">
            <div className="w-40">Contacted At:-</div>
            <div className="ml-2">
              {inquiry?.engagement?.contactedAt ? (
                <Moment format="Do MMM, YYYY">
                  {inquiry?.engagement?.contactedAt}
                </Moment>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="flex border-b py-1">
            <div className="w-40">Contacted By:-</div>
            <div className="ml-2">
            {inquiry?.engagement?.contactedBy || "-"}
            </div>
          </div>

          <div className="flex items-center">
            <div className="w-40">Date :-</div>
            <div className=" p-2 min-h-24">
              <Moment format="Do MMM, YYYY">{inquiry?.engagement?.date}</Moment>
            </div>
          </div>

          <div className="flex flex-col py-1">
            <div className="">Feedback :-</div>
            <div className="border bg-gray-50 p-2 min-h-24">
              {inquiry?.engagement?.feedback || "-"}
            </div>
          </div>
        </div>
      </div>

      <CreateContact
        isVisible={contactModal}
        setVisible={setContactModal}
        inquiry={inquiry}
      />
      <AssignConsultant
        isVisible={assignModal}
        setVisible={setAssignModal}
        inquiry={inquiry}
      />

      <InquiryResponse
        isVisible={responseModal}
        setVisible={setResponseModal}
        inquiry={inquiry}
      />
    </Drawer>
  );
}
