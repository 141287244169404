import sjosh from "../../assets/images/sign.png";
import mrashid from "../../assets/images/mrashid.png";

export default function DocumentSignature({ proforma }: any) {
  const signatures: any = {
    "mrashid@automark.co.tz": mrashid,
    "sjoshi@automark.co.tz": sjosh,
  };

  const sign = signatures[proforma?.approver?.email];
  return (
    <div className="mt-4">
      <div className="">For Automark</div>
      <div className="">
        {proforma?.flags?.isApproved ? (
          <div className="h-10">
            <img src={sign} width={80} alt="" />
          </div>
        ) : (
          <div className="h-10"></div>
        )}
        <div className="">Sales Manager</div>
        <div className="" style={{ fontSize: 12 }}>
          {proforma?.flags?.isApproved ? (
            <div className="h-6">{proforma?.approver?.name}</div>
          ) : (
            <div className="h-6"></div>
          )}
        </div>
      </div>
      <div className="mt-2">
        <div className="">
          For any correspondence on this proforma invoice, please contact
          <span className="px-1">{proforma?.creator?.name}</span>
        </div>
        <div className="flex">
          <div className="">
            Mobile: <span className="px-2">{proforma?.creator?.mobile}</span>
          </div>
          <div className="ml-8">
            Email: <span className="px-2">{proforma?.creator?.email}</span>
          </div>
        </div>
      </div>
    </div>
  );
}
