import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  TimePicker,
  Checkbox,
  message,
} from "antd";
import { useState } from "react";
import { handleCreateDealActivity } from "../../../../api/deals";

const { TextArea } = Input;

const format = "HH:mm";

export default function CreateActivity({
  category,
  action,
  hasTime,
  hasFeed,
  deal_id,
  isVisible,
  hideModal,
}: any) {
  const [is_scheduled, setScheduled] = useState<boolean>(true);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateDealActivity,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["deals", deal_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const scheduled = is_scheduled ? "yes" : "no";
    const data = {
      ...values,
      title: values?.description,
      isScheduled: scheduled,
    };
    await mutate({ data, deal_id, action });
    hideModal();
    form.resetFields();
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title={`${
        is_scheduled ? `Schedule a ${category}` : `Report a ${category}`
      }`}
      onCancel={hideModal}
      footer={[
        <Button key="back" danger onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="AddDealActivity"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="AddDealActivity"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
      >
        <div className="mb-4">
          {category !== "Notes" ? (
            <Checkbox
              checked={is_scheduled}
              onChange={() => setScheduled(!is_scheduled)}
            >
              Schedule
            </Checkbox>
          ) : null}
        </div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          {hasFeed ? (
            <>
              {!is_scheduled && (
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="feedback"
                    rules={[
                      {
                        required: true,
                        message: "Please enter feedback",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Feedback"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          ) : null}

          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <DatePicker placeholder="Date"   format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        {hasTime ? (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="start"
                rules={[
                  {
                    required: true,
                    message: "Please enter start time",
                  },
                ]}
              >
                <TimePicker
                  placeholder="Start Time"
                  format={format}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="end"
                rules={[
                  {
                    required: true,
                    message: "Please enter finish time",
                  },
                ]}
              >
                <TimePicker
                  placeholder="Finish Time"
                  format={format}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </Form>
    </Modal>
  );
}
