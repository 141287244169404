import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useEffect } from "react";
import { handleUpdateSource } from "../../../../api/sources";

export default function UpdateSource({ isVisible, setVisible, selected }: any) {
  const { _id } = selected;
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateSource,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["sources"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name, budget } = await form.getFieldsValue();
    const data = {
      name,
      budget: Number(budget),
    };
    await mutate({ data, _id });
    setVisible(false);
  };

  useEffect(() => {
    form.setFieldsValue({ ...selected });
  }, [selected]);

  return (
    <Modal
      open={isVisible}
      title="Update Source"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updateSource"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Update
        </Button>,
      ]}
    >
      <Form
        id="updateSource"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={selected?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter source name",
                },
              ]}
            >
              <Input type="" placeholder="Model Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="budget"
              label="Service Budget (KES)"
              rules={[
                {
                  required: true,
                  message: "Please enter budget",
                },
              ]}
            >
              <Input type="" placeholder="Budget" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
