import { useQuery } from "@tanstack/react-query";
import { Button, Checkbox, Col, Form, Row, Select } from "antd";
import { GrClose } from "react-icons/gr";
import { handleFetchModels } from "../../../api/models";
import { handleFetchSources } from "../../../api/sources";
import { handleFetchLocations } from "../../../api/locations";
import { useState } from "react";
import formatMoney from "../../../utils/formatMoney";
import { formatNumber } from "../../../utils/formatNumber";
import { handleFetchAvailabilities } from "../../../api/availability";

const { Option } = Select;

export default function AdvancedSearch({
  setVisible,
  isLoading,
  clearAllFilters,
  handleAdvancedSearch,
}: any) {
  const [filters, setFilters] = useState<any>({});
  const [form] = Form.useForm();

  const { data: models } = useQuery({
    queryKey: ["models"],
    queryFn: handleFetchModels,
  });

  const { data: sources } = useQuery({
    queryKey: ["sources"],
    queryFn: handleFetchSources,
  });

  const { data: locations } = useQuery({
    queryKey: ["locations"],
    queryFn: handleFetchLocations,
  });

  const { data: availabilities } = useQuery({
    queryKey: ["availabilities"],
    queryFn: handleFetchAvailabilities,
  });

  const clearFilters = () => {
    setFilters({});
    clearAllFilters();
  };

  const handleFetchNumbers = () => {
    const year = new Date().getFullYear() + 1;
    const years: any = [];

    for (let i = 2000; i < year; i++) {
      years.unshift({ id: i, name: i });
    }
    return { years };
  };

  const { years } = handleFetchNumbers();

  const modelOptions = models?.map((item: any, index: number) => {
    return (
      <Option value={item?._id} key={index}>
        {item.name}
      </Option>
    );
  });

  const sourceOptions = sources?.map((item: any, index: number) => {
    return (
      <Option value={item?._id} key={index}>
        {item.name}
      </Option>
    );
  });

  const locationOptions = locations?.map((item: any, index: number) => {
    return (
      <Option value={item?._id} key={index}>
        {item.name}
      </Option>
    );
  });

  const availabilityOptions = availabilities?.map(
    (item: any, index: number) => {
      return (
        <Option value={item?._id} key={index}>
          {item.title}
        </Option>
      );
    }
  );

  const yearOptions = years?.map((item: any, index: number) => {
    return (
      <Option value={item.name} key={index}>
        {`${item.name}`}
      </Option>
    );
  });
  return (
    <div className="h-auto  px-4 border-b bg-white">
      <div className="flex justify-between items-center py-4 border-b">
        <div className="text-lg">Advanced Search</div>
        <div className="">
          <button
            onClick={() => setVisible(false)}
            className="focus:outline-none hover:bg-red-100  p-2"
          >
            <GrClose />
          </button>
        </div>
      </div>
      <div className="h-auto mt-4 flex-1">
        <Form
          id="stockAdvSearch"
          name="stockAdvSearch"
          form={form}
          onFinish={() => handleAdvancedSearch(filters)}
          layout="vertical"
        >
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 6 }}>
              <Form.Item
                name="model"
                label="Model"
                rules={[
                  {
                    required: false,
                    message: "Please select model",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Model"
                  onChange={(e: any) => {
                    setFilters({ ...filters, model: e });
                  }}
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option selected value="">
                    All
                  </Option>
                  {modelOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="source"
                label="Source"
                rules={[
                  {
                    required: false,
                    message: "Please select source",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Source"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, source: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {sourceOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="status"
                label="Status"
                rules={[
                  {
                    required: false,
                    message: "Please select status",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Status"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, status: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="available">Available</Option>
                  <Option value="reserved">Reserved</Option>
                  <Option value="returned">Returned</Option>
                  <Option value="inbound">Inbound</Option>
                  <Option value="sold">Sold</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="transmission"
                label="Transmission"
                rules={[
                  {
                    required: false,
                    message: "Please select transmission",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Transmission"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, transmission: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="Automatic">Automatic</Option>
                  <Option value="Manual">Manual</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="color"
                label="Exterior Color"
                rules={[
                  {
                    required: false,
                    message: "Please select exterior color",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Exterior Color"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, exteriorColor: e });
                  }}
                >
                  <Option value="">All</Option>
                  <Option value="Black">Black</Option>
                  <Option value="White">White</Option>
                  <Option value="Gray">Gray</Option>
                  <Option value="Peal White">Peal White</Option>
                  <Option value="Red">Red</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="location"
                label="Location"
                rules={[
                  {
                    required: false,
                    message: "Please select location",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Location"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, location: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {locationOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="availability"
                label="Availability"
                rules={[
                  {
                    required: false,
                    message: "Please select availability",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Availability"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, availability: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {availabilityOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="fuel"
                label="Fuel"
                rules={[
                  {
                    required: false,
                    message: "Please select fuel",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Fuel"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, fuel: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="Petrol">Petrol</Option>
                  <Option value="Diesel">Diesel</Option>
                  <Option value="Electric">Electric</Option>
                  <Option value="Hybrid">Hybrid</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="minYear"
                label="Min Year"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter minimum year",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Min Year"
                  optionFilterProp="children"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, minYear: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {yearOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="maxYear"
                label="Max Year"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter maximum year",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Max Year"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, maxYear: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  {yearOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="minPrice"
                label="Min Price"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter minimum price",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Price Min"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, minPrice: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="5000">{`${formatMoney(5000)} KES`}</Option>
                  <Option value="10000">{`${formatMoney(10000)} KES`}</Option>
                  <Option value="15000">{`${formatMoney(15000)} KES`}</Option>
                  <Option value="20000">{`${formatMoney(20000)} KES`}</Option>
                  <Option value="25000">{`${formatMoney(25000)} KES`}</Option>
                  <Option value="30000">{`${formatMoney(30000)} KES`}</Option>
                  <Option value="35000">{`${formatMoney(35000)} KES`}</Option>
                  <Option value="40000">{`${formatMoney(40000)} KES`}</Option>
                  <Option value="45000">{`${formatMoney(45000)} KES`}</Option>
                  <Option value="50000">{`${formatMoney(50000)} KES`}</Option>
                  <Option value="55000">{`${formatMoney(55000)} KES`}</Option>
                  <Option value="60000">{`${formatMoney(60000)} KES`}</Option>
                  <Option value="65000">{`${formatMoney(65000)} KES`}</Option>
                  <Option value="70000">{`${formatMoney(70000)} KES`}</Option>
                  <Option value="75000">{`${formatMoney(75000)} KES`}</Option>
                  <Option value="80000">{`${formatMoney(80000)} KES`}</Option>
                  <Option value="85000">{`${formatMoney(85000)} KES`}</Option>
                  <Option value="90000">{`${formatMoney(90000)} KES`}</Option>
                  <Option value="95000">{`${formatMoney(95000)} KES`}</Option>
                  <Option value="100000">{`${formatMoney(100000)} KES`}</Option>
                  <Option value="105000">{`${formatMoney(105000)} KES`}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="maxPrice"
                label="Max Price"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter maximum price",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Max Price"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, maxPrice: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="5000">{`${formatMoney(5000)} KES`}</Option>
                  <Option value="10000">{`${formatMoney(10000)} KES`}</Option>
                  <Option value="15000">{`${formatMoney(15000)} KES`}</Option>
                  <Option value="20000">{`${formatMoney(20000)} KES`}</Option>
                  <Option value="25000">{`${formatMoney(25000)} KES`}</Option>
                  <Option value="30000">{`${formatMoney(30000)} KES`}</Option>
                  <Option value="35000">{`${formatMoney(35000)} KES`}</Option>
                  <Option value="40000">{`${formatMoney(40000)} KES`}</Option>
                  <Option value="45000">{`${formatMoney(45000)} KES`}</Option>
                  <Option value="50000">{`${formatMoney(50000)} KES`}</Option>
                  <Option value="55000">{`${formatMoney(55000)} KES`}</Option>
                  <Option value="60000">{`${formatMoney(60000)} KES`}</Option>
                  <Option value="65000">{`${formatMoney(65000)} KES`}</Option>
                  <Option value="70000">{`${formatMoney(70000)} KES`}</Option>
                  <Option value="75000">{`${formatMoney(75000)} KES`}</Option>
                  <Option value="80000">{`${formatMoney(80000)} KES`}</Option>
                  <Option value="85000">{`${formatMoney(85000)} KES`}</Option>
                  <Option value="90000">{`${formatMoney(90000)} KES`}</Option>
                  <Option value="95000">{`${formatMoney(95000)} KES`}</Option>
                  <Option value="100000">{`${formatMoney(100000)} KES`}</Option>
                  <Option value="105000">{`${formatMoney(105000)} KES`}</Option>
                </Select>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="minMileage"
                label="Min Mileage"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter minimum mileage",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Min Mileage"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, minMileage: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="10000">{`${formatNumber(10000)} Km`}</Option>
                  <Option value="20000">{`${formatNumber(20000)} Km`}</Option>
                  <Option value="30000">{`${formatNumber(30000)} Km`}</Option>
                  <Option value="40000">{`${formatNumber(40000)} Km`}</Option>
                  <Option value="50000">{`${formatNumber(50000)} Km`}</Option>
                  <Option value="60000">{`${formatNumber(60000)} Km`}</Option>
                  <Option value="70000">{`${formatNumber(70000)} Km`}</Option>
                  <Option value="80000">{`${formatNumber(80000)} Km`}</Option>
                  <Option value="90000">{`${formatNumber(90000)} Km`}</Option>
                  <Option value="100000">{`${formatNumber(100000)} Km`}</Option>
                  <Option value="150000">{`${formatNumber(150000)} Km`}</Option>
                  <Option value="200000">{`${formatNumber(200000)} Km`}</Option>
                  <Option value="300000">{`${formatNumber(300000)} Km`}</Option>
                </Select>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item
                name="maxMileage"
                label="Max Mileage"
                initialValue=""
                rules={[
                  {
                    required: false,
                    message: "Please enter maximum mileage",
                  },
                ]}
              >
                <Select
                  showSearch
                  placeholder="Max Mileage"
                  filterOption={(input: any, option: any) =>
                    option?.children
                      ?.toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  onChange={(e: any) => {
                    setFilters({ ...filters, maxMileage: e });
                  }}
                >
                  <Option selected value="">
                    All
                  </Option>
                  <Option value="10000">{`${formatNumber(10000)} Km`}</Option>
                  <Option value="20000">{`${formatNumber(20000)} Km`}</Option>
                  <Option value="30000">{`${formatNumber(30000)} Km`}</Option>
                  <Option value="40000">{`${formatNumber(40000)} Km`}</Option>
                  <Option value="50000">{`${formatNumber(50000)} Km`}</Option>
                  <Option value="60000">{`${formatNumber(60000)} Km`}</Option>
                  <Option value="70000">{`${formatNumber(70000)} Km`}</Option>
                  <Option value="80000">{`${formatNumber(80000)} Km`}</Option>
                  <Option value="90000">{`${formatNumber(90000)} Km`}</Option>
                  <Option value="100000">{`${formatNumber(100000)} Km`}</Option>
                  <Option value="150000">{`${formatNumber(150000)} Km`}</Option>
                  <Option value="200000">{`${formatNumber(200000)} Km`}</Option>
                  <Option value="300000">{`${formatNumber(300000)} Km`}</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="roofrail">
                <Checkbox>Roof Rail</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="sunroof">
                <Checkbox>Sunroof</Checkbox>
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="leather">
                <Checkbox>Leather Interior</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="fabric">
                <Checkbox>Fabric Interior</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="petrol">
                <Checkbox>Petrol</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="diesel">
                <Checkbox>Diesel</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="fourwd">
                <Checkbox>4WD</Checkbox>
              </Form.Item>
            </Col>
            <Col xs={{ span: 24 }} lg={{ span: 3 }}>
              <Form.Item name="twowd">
                <Checkbox>2WD</Checkbox>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" danger htmlType="submit" loading={isLoading}>
              Search
            </Button>

            <Button
              type="default"
              danger
              className="ml-4"
              onClick={() => {
                form.resetFields();
                clearFilters();
              }}
            >
              Clear
            </Button>

            <Button
              type="default"
              danger
              className="ml-4"
              onClick={() => setVisible(false)}
            >
              Close
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
}
