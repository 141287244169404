import Cookies from "js-cookie";

const REACT_APP_EMAIL_BASE_URL = process.env.REACT_APP_AUTO_BASE_URL
const postDataMail = async ({ data, uri }: any) => {
  const token = Cookies.get("token");
  const response = await fetch(`${REACT_APP_EMAIL_BASE_URL}/${uri}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });
  if (response.status !== 201) {
    return Promise.reject("Something Went Wrong");
  }
  return response.json();
};

export { postDataMail };
