import logo from "../../assets/images/logo-red.png";
export default function DocumentHeader() {
  return (
    <div className="flex justify-between">
      <div className="-mt-2">
        <div className="text-lg h-7 bg-gray-100 mb-1 px-2">-</div>
        <div className="text-md mb-1 h-5 bg-gray-100 w-48 px-2">-</div>
        <div className="text-md mb-1 h-5 bg-gray-100 w-44 px-2">-</div>
        <div className="text-md mb-1 h-5 bg-gray-100 w-44 px-2">-</div>
        <div className="text-md mb-1 h-5 bg-gray-100 w-44 px-2">-</div>
        <div className="text-md mb-1 h-5 bg-gray-100 w-44 px-2">-</div>
        <div className="text-md h-5 bg-gray-100 w-44 px-2">-</div>
      </div>
      <div className="">
        <img src={logo} width={250} alt="" />
      </div>
    </div>
  );
}
