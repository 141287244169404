import { Button, DatePicker, Form, Input, message } from "antd";
import { handleCreateLeadTask } from "../../../../api/leads";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function CreateTask({ setVisible, lead_id }: any) {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateLeadTask,
    onSuccess: () => {
      message.success("Created Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { description, date } = await form.getFieldsValue();
    const data = {
      title: description,
      description,
      date,
    };
    await mutate({ data, _id: lead_id });
    setVisible(false);
  };

  return (
    <div className="w-full">
      <Form
        name="create_task"
        className="login-form"
        form={form}
        onFinish={onSubmit}
      >
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: "Please enter task description.",
            },
          ]}
        >
          <Input placeholder="Description" style={{ width: "100%" }} />
        </Form.Item>
        <Form.Item
          name="date"
          rules={[
            {
              required: true,
              message: "Please enter task date.",
            },
          ]}
        >
          <DatePicker placeholder="Date"   format={"DD/MM/YYYY"} style={{ width: "100%" }} />
        </Form.Item>

        <Form.Item>
          <Button
            onClick={() => setVisible(false)}
            type="default"
            danger
            className="login-form-button mr-2"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            danger
            htmlType="submit"
            className="login-form-button"
            loading={isLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
