import { useContext, useEffect } from "react";

import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { AuthContext } from "../../../context/AuthContext";
import { handleUpdateDetails } from "../../../api/users";
import { useMutation, useQueryClient } from "@tanstack/react-query";
export default function UpdateDetails({ isVisible, setVisible }: any) {
  const { updateUser, auth, updateAuth } = useContext(AuthContext);

  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateDetails,
    onSuccess: (data) => {
      message.success("Updated Successfully");
      form.resetFields();
      setVisible(false);
      queryClient.invalidateQueries({ queryKey: ["users"] });

      updateUser(data);
      updateAuth(data);
    },
    onError: (error) => {
      message.error(`${error}`);
    },
  });

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    await mutate({ _id: auth?._id, data: { ...values } });
  };

  useEffect(() => {
    form.resetFields()
    form.setFieldsValue({
      name: auth?.name,
    });
    form.setFieldsValue({
      email: auth?.email,
    });
    form.setFieldsValue({
      mobile: auth?.mobile,
    });
    form.setFieldsValue({
      mobile2: auth?.mobile2,
    });
  }, [form, auth]);

  return (
    <Modal
      open={isVisible}
      title={<div className="flex">Update Details</div>}
      onCancel={() => setVisible(!isVisible)}
      width={680}
      footer={[
        <Button key="back" danger onClick={() => setVisible(!isVisible)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="updDetails"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="updDetails"
        form={form}
        name="normal_login"
        className="login-form"
        layout="vertical"
        onFinish={onSubmit}
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Name"
              initialValue={auth?.name}
              rules={[
                {
                  required: true,
                  message: "Please enter name",
                },
              ]}
            >
              <Input placeholder="Name" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="email"
              label="Email"
              initialValue={auth?.email}
              rules={[
                {
                  required: true,
                  message: "Please enter email",
                },
              ]}
            >
              <Input placeholder="Email" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile"
              label="Mobile"
              initialValue={auth?.mobile}
              rules={[
                {
                  required: true,
                  message: "Please enter mobile",
                },
              ]}
            >
              <Input
                type={"text"}
                placeholder="Mobile"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="mobile2"
              label="Mobile 2 (Optional)"
              initialValue={auth?.mobile}
              rules={[
                {
                  required: false,
                  message: "Please enter mobile 2",
                },
              ]}
            >
              <Input placeholder="Mobile 2" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
