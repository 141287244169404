import { FiUsers } from "react-icons/fi";
import { HiOutlineClipboardList, HiOutlineCreditCard } from "react-icons/hi";
import { TfiControlEject } from "react-icons/tfi";
import AdmMenu from "../../components/sidebar/AdmMenu";
import { RiMoneyDollarCircleLine } from "react-icons/ri";
import { useContext } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function Manage({
  children,
  title,
  subtitle,
  description,
  actions,
}: any) {
  const { auth } = useContext(AuthContext);
  const menus = [
    {
      id: 1,
      to: "/manage/stock",
      name: "Vehicles",
      access: [3, 4,9],
      icon: <TfiControlEject />,
    },

    {
      id: 2,
      to: "/manage/proforma",
      name: "Proforma",
      access: [4,9],
      icon: <TfiControlEject />,
    },
    {
      id: 3,
      to: "/manage/users",
      name: "Users",
      access: [4,9],
      icon: <FiUsers />,
    },
    {
      id: 4,
      to: "/manage/roles",
      name: "Roles",
      access: [4,9],
      icon: <TfiControlEject />,
    },
    {
      id: 5,
      to: "/manage/crm/contacts",
      name: "Contacts",
      access: [3, 4,9],
      icon: <FiUsers />,
    },

    // {
    //   id: 6,
    //   to: "/manage/makes",
    //   name: "Makes",
    //   access: [4,9],
    //   icon: <HiOutlineClipboardList />,
    // },

    {
      id: 7,
      to: "/manage/models",
      name: "Models",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },

    {
      id: 8,
      to: "/manage/budget",
      name: "Budget",
      access: [4,9],
      icon: <HiOutlineCreditCard />,
    },

    {
      id: 9,
      to: "/manage/sources",
      name: "Sources",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 10,
      to: "/manage/locations",
      name: "Locations",
      access: [3, 4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 11,
      to: "/manage/availabilities",
      name: "Availabilities",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 12,
      to: "/manage/features",
      name: "Features",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 13,
      to: "/manage/service/types",
      name: "Service Types",
      access: [3, 4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 14,
      to: "/manage/service/sites",
      name: "Service Sites",
      access: [3, 4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 15,
      to: "/manage/deal/titles",
      name: "Deals Titles",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 16,
      to: "/manage/technicians",
      name: "Technicians",
      access: [3, 4,9],
      icon: <FiUsers />,
    },
    {
      id: 17,
      to: "/manage/deal/stages",
      name: "Deal Stages",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 18,
      to: "/manage/crm/sources",
      name: "CRM Sources",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 19,
      to: "/manage/questions",
      name: "160 Questions",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 20,
      to: "/manage/remarks",
      name: "Remarks",
      access: [2, 3, 4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 21,
      to: "/manage/reasons",
      name: "Reasons",
      access: [2, 3, 4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 22,
      to: "/manage/industries",
      name: "Industries",
      access: [4,9],
      icon: <HiOutlineClipboardList />,
    },
    {
      id: 23,
      to: "/manage/allowance",
      name: "Allowance",
      access: [4,9],
      icon: <RiMoneyDollarCircleLine />,
    },
  ];

  return (
    <div className="grid grid-cols-12 gap-0 m-2 border rounded-lg">
      <div className="bg-white col-span-2 rounded-l-lg border-r">
        {menus?.map((menu: any, index: number) => {
          if (menu?.access?.includes(auth?.accessLevel)) {
            return (
              <AdmMenu to={`${menu?.to}`} icon={menu?.icon} key={index}>
                {menu?.name}
              </AdmMenu>
            );
          }
        })}
      </div>
      <div className="bg-white col-span-10 h-full rounded-r-lg">
        <div className="border-b p-4 flex justify-between items-center h-18">
          <div className="flex justify-center items-center">
            <div className="">
              <span className="text-lg font-bold">{title} : </span>
              <span>{subtitle}</span>
              <div className="">{description}</div>
            </div>
          </div>
          {actions}
        </div>
        <div className="">{children}</div>
      </div>
    </div>
  );
}
