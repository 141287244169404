import { Button, Modal, Form, Row, Col, Input, message } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { handleCreateMake } from "../../../../api/makes";


export default function CreateMake({ isVisible, setVisible }: any) {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleCreateMake,
    onSuccess: () => {
      message.success("Created Successfully");
      queryClient.invalidateQueries({ queryKey: ["makes"] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const onSubmit = async () => {
    const { name, description } = await form.getFieldsValue();
    const data = {
      name,
      description,
    };
    await mutate(data);
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title="New Make"
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={clearForm}>
          Clear
        </Button>,
        <Button
          key="submit"
          form="createMake"
          htmlType="submit"
          type="primary"
          onSubmit={onSubmit}
          loading={isLoading}
          danger
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="createMake"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="name"
              label="Make Name"
              rules={[
                {
                  required: true,
                  message: "Please enter make name",
                },
              ]}
            >
              <Input type="" placeholder="Make Name" />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} lg={{ span: 12 }}>
            <Form.Item
              name="description"
              label="Make Description"
              rules={[
                {
                  required: false,
                  message: "Please enter make description",
                },
              ]}
            >
              <Input type="" placeholder="Make description" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
}
