import { Button, Form, Row, Col, message, Select, Drawer, Space } from "antd";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { handleUpdateLeadVehicles } from "../../../../api/leads";
import {
  handleFetchUnfilteredVehicles,
} from "../../../../api/vehicles";
import { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";
import formatMoney from "../../../../utils/formatMoney";
import { RiDeleteBin4Line } from "react-icons/ri";
import { BsCheck2 } from "react-icons/bs";

const { Option } = Select;

export default function UpdateVehicles({
  isVisible,
  setVisible,
  lead_id,
  prepick,
}: any) {

  

  const [editMode, setEditMode] = useState<boolean>(false);
  const [vehicles, setVehicles] = useState<any>([]);
  const [picked, setPicked] = useState<any>(null);
  const [source, setSource] = useState<any>(prepick[0]?.car?.source?.entity || '');
  const [selectedVat] = useState<number>(16);

  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { mutate } = useMutation({
    mutationFn: handleUpdateLeadVehicles,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      clearForm();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const { data: stock, refetch } = useQuery({
    queryKey: ["sourceVehicles", {source }],
    queryFn: () => handleFetchUnfilteredVehicles({source})
  });

  const vehicleOptions = stock?.vehicles?.map((vehicle: any, index: number) => (
    <Option value={vehicle?.chassis} key={index}>
      {`${index + 1}. ${vehicle?.year} ${vehicle.model?.name} - Chassis No:  ${
        vehicle.chassis
      } ( ${vehicle?.source?.name} )`}
    </Option>
  ));

  const onSubmit = async () => {
    const extracted = vehicles.map((item: any) => item.chassis);
    const data = {
      vehicles: extracted,
    };
    await mutate({ data, lead_id });
    setVisible(false);
  };

  const clearForm = async () => {
    form.resetFields();
    setVehicles([]);
    setSource("");
  };

  const handleAddVehicle = async () => {
    if (picked === null) {
      return;
    }
    const v = stock?.vehicles?.filter((item: any) => item?.chassis === picked);
    addVehicle(v[0]);
    // if (!source) {
    //   setSource(v[0]?.source?.entity?._id);
    //   await refetch();
    // }
  };

  const handleRemVehicle = async (item: any) => {
    remVehicle(item);
  };

  const addVehicle = async (item: any) => {
    try {
      let a = vehicles;
      if (a.some((e: any) => e.chassis === item?.chassis)) {
        message.error("Vehicle already Added");
        return;
      } else {
        a.push(item);

        setVehicles(a);
      }
    } catch (e) {
      console.error(e);
    }
  };

  const remVehicle = async (item: any) => {
    try {
      const filtered = vehicles.filter((i: any) => i.chassis !== item?.chassis);
      setVehicles(filtered);
    } catch (e) {
      console.error(e);
    }
  };

  const getTotal = () => {
    let total = 0;
    if (vehicles) {
      vehicles.forEach((vehicle: any) => {
        total = total + vehicle?.prices?.exclusive;
      });
    }
    return total;
  };

  const getCalculations: any = () => {
    let discount = 0;
    let vat = 0;
    let unit = 0;
    let net = 0;
    let exchange = 0;

    const total = getTotal();
    if (vehicles) {
      vat = ((total - discount) * selectedVat) / 100;

      exchange =
        vehicles[0]?.source?.entity?.exchange || vehicles[0]?.source?.exchange;

      unit = total - discount;

      net = unit + vat;
    }

    return { total, discount, vat, unit, net, exchange };
  };

  const { vat, unit, net } = getCalculations();

  const addPrePicked = () => {
    let vs: any = [];
    prepick?.forEach((v: any) => {
      vs.push(v?.car);
    });

    setVehicles(vs || []);
  };

  useEffect(() => {
    addPrePicked();
    setSource(prepick?.car ? prepick[0]?.car?.source?.entity : '')
  }, []);

  return (
    <Drawer
      title={`ADD VEHICLES`}
      placement={"right"}
      width={window.innerWidth > 1200 ? "48%" : "auto"}
      onClose={() => setVisible(!isVisible)}
      open={isVisible}
      closable={false}
      extra={
        <Space>
          <Button
            type="default"
            color="#E50000"
            onClick={() => setVisible(!isVisible)}
            danger
          >
            <AiOutlineClose />
          </Button>
        </Space>
      }
    >
      <Form
        id="updateLeadVehicles"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        layout="vertical"
      >
        <div className="mb-8 border-b">
          <div className="flex justify-between w-full p-2 bg-gray-100 items-center border-b">
            <div className="">Vehicles</div>
            <div className="">
              {editMode ? (
                <div
                  onClick={() => {
                    setEditMode(false);
                    addPrePicked();
                  }}
                  className="bg-blue-200 h-8 flex items-center rounded-lg cursor-pointer"
                >
                  <div className="flex px-1">
                    <div className="rounded-lg h-6 mx-1">Edit</div>
                    <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                      <BsCheck2 />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  onClick={() => {
                    setEditMode(true);
                    addPrePicked();
                  }}
                  className="bg-gray-200 h-8 flex items-center rounded-lg cursor-pointer"
                >
                  <div className="flex px-1">
                    <div className="rounded-lg bg-white h-6 w-6 flex justify-center items-center">
                      <BsCheck2 />
                    </div>
                    <div className="rounded-lg h-6 mx-1">Edit</div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {editMode && (
            <div className="mt-4">
              <Row gutter={[16, 0]}>
                <Col xs={{ span: 20 }} lg={{ span: 22 }}>
                  <Form.Item
                    name="chassis"
                    rules={[
                      {
                        required: true,
                        message: "Please select vehicle",
                      },
                    ]}
                  >
                    <Select
                      showSearch
                      placeholder="Search and select vehicle"
                      optionFilterProp="children"
                      filterOption={(input: any, option: any) =>
                        option?.children
                          ?.toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      onSelect={(e: any) => {
                        setPicked(e);
                      }}
                    >
                      {vehicleOptions}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 2 }} lg={{ span: 2 }}>
                  <Button
                    onClick={() => {
                      handleAddVehicle();
                      setPicked(null);
                    }}
                    type="primary"
                    htmlType="button"
                    className="bg-gray-400"
                  >
                    <AiOutlinePlus />
                  </Button>
                </Col>
              </Row>
            </div>
          )}

          <div className="">
            <div className="border-b flex justify-between bg-gray-50 p-2">
              <div className="flex">
                <div className="">S/n. </div>
                <div className="ml-2">Vehicle</div>
              </div>

              <div className="flex">
                <div className="w-40 text-right"></div>
                <div className="w-32 text-right">Amount ( KES )</div>
                {editMode && <div className="w-12 ml-2">Action</div>}
              </div>
            </div>
            {vehicles?.map((item: any, index: number) => (
              <div className="border-b flex justify-between p-2" key={index}>
                <div className="flex">
                  <div className="">{index + 1}. </div>
                  <div className="ml-2">
                    {item?.model?.name} - {item?.chassis}
                  </div>
                </div>
                <div className="flex">
                  <div className="w-40 text-left"></div>
                  <div className="w-32 text-right">
                    {formatMoney(item?.prices?.exclusive)}
                  </div>
  

                  {editMode && (
                    <div className="w-12 ml-2">
                      <div className="flex items-center justify-center">
                        <button
                          onClick={() => handleRemVehicle(item)}
                          className="border p-1 rounded-md ml-2 bg-red-500"
                          type="button"
                        >
                          <RiDeleteBin4Line color={"#FFF"} />
                        </button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="border-t  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Sub Total</div>
              <div className="w-32 text-right">{formatMoney(unit)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t border-gray-300 flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Vat ( {selectedVat} %)</div>
              <div className="w-32 text-right">{formatMoney(vat)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>

            <div className="border-t-2 border-b border-gray-600  flex justify-between bg-gray-50 px-2">
              <div className="flex flex-1">
                <div className=""> </div>
                <div className="ml-2"></div>
              </div>
              <div className="w-40 text-left">Grand Total</div>
              <div className="w-32 text-right">{formatMoney(net)}</div>
              {editMode && <div className="w-12 ml-2"></div>}
            </div>
          </div>
        </div>

        <Form.Item>
          <Button danger type="primary" htmlType="submit">
            Update
          </Button>
          <Button danger htmlType="button" className="mx-2" onClick={clearForm}>
            Reset
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
