import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  TimePicker,
  message,
  Checkbox,
} from "antd";
import moment from "moment";
import { useEffect, useState } from "react";
import { handleUpdateLeadActivity } from "../../../../api/leads";

const { TextArea } = Input;

const format = "HH:mm";

export default function UpdateModal({
  lead_id,
  isVisible,
  setVisible,
  activity,
}: any) {
  const { _id, description, feedback, start, end, action } = activity;

  const [is_scheduled, setScheduled] = useState<boolean>(activity?.isScheduled);

  let hasFeed = true;
  let hasTime = true;

  switch (action) {
    case "notes":
      hasFeed = false;
      hasTime = false;
      break;
    default:
  }

  const [form] = Form.useForm();

  const queryClient = useQueryClient();

  const { mutate, isLoading } = useMutation({
    mutationFn: handleUpdateLeadActivity,
    onSuccess: () => {
      message.success("Updated Successfully");
      form.resetFields();
      queryClient.invalidateQueries({ queryKey: ["leads", lead_id] });
    },
    onError: () => {
      message.error("Something went wrong");
    },
  });

  const dates = {
    date: moment(activity?.dates?.appointment),
    start: moment(start),
    finish: moment(end),
  };

  const defaultValues = {
    description,
    feedback,
    start: dates.start,
    end: dates.finish,
    date: dates.date,
  };

  useEffect(() => {
    form.setFieldsValue(defaultValues);
  }, [activity, dates]);

  const onSubmit = async () => {
    const values = await form.getFieldsValue();
    const scheduled = is_scheduled ? "yes" : "no";
    const data = {
      ...values,
      title: values?.description,
      isScheduled: scheduled,
    };
    await mutate({ data, lead_id, activity_id: _id });
    setVisible(false);
    form.resetFields();
  };

  return (
    <Modal
      open={isVisible}
      title={"Update Activity"}
      onCancel={() => setVisible(false)}
      footer={[
        <Button danger key="back" onClick={() => setVisible(false)}>
          Cancel
        </Button>,
        <Button
          key="submit"
          form="UpdateActivity"
          htmlType="submit"
          type="primary"
          danger
          onSubmit={onSubmit}
          loading={isLoading}
        >
          Submit
        </Button>,
      ]}
    >
      <Form
        id="UpdateActivity"
        form={form}
        name="normal_login"
        className="login-form"
        onFinish={onSubmit}
        initialValues={defaultValues}
      >
        <div className="mb-4">
          {action !== "Notes" ? (
            <Checkbox
              checked={is_scheduled}
              onChange={() => setScheduled(!is_scheduled)}
            >
              Schedule
            </Checkbox>
          ) : null}
        </div>
        <Row gutter={[16, 0]}>
          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="description"
              rules={[
                {
                  required: true,
                  message: "Please enter description",
                },
              ]}
            >
              <TextArea placeholder="Description" style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          {hasFeed ? (
            <>
              {!is_scheduled && (
                <Col xs={{ span: 24 }} lg={{ span: 24 }}>
                  <Form.Item
                    name="feedback"
                    rules={[
                      {
                        required: true,
                        message: "Please enter feedback",
                      },
                    ]}
                  >
                    <TextArea
                      placeholder="Feedback"
                      style={{ width: "100%" }}
                    />
                  </Form.Item>
                </Col>
              )}
            </>
          ) : null}

          <Col xs={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              name="scheduled"
              initialValue={dates.date}
              rules={[
                {
                  required: true,
                  message: "Please enter date",
                },
              ]}
            >
              <DatePicker placeholder="Date" format={"DD/MM/YYYY"} style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        {hasTime ? (
          <Row gutter={[16, 0]}>
            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="start"
                rules={[
                  {
                    required: true,
                    message: "Please enter start time",
                  },
                ]}
              >
                <TimePicker
                  placeholder="Start Time"
                  format={format}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col xs={{ span: 24 }} lg={{ span: 12 }}>
              <Form.Item
                name="end"
                rules={[
                  {
                    required: true,
                    message: "Please enter finish time",
                  },
                ]}
              >
                <TimePicker
                  placeholder="Finish Time"
                  format={format}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>
        ) : null}
      </Form>
    </Modal>
  );
}
